import { Tire, TireFormData } from '../types/tire';
import api from './api';

interface IProps {
  page?: number;
  size?: number;
  model?: string;
  modification?: string;
  project?: string;
}

export async function getTireListService({
  model,
  modification,
  project,
}: IProps): Promise<Tire[]> {
  const response = await api.get(`/tires/`, {
    params: {
      model,
      modification,
      project,
      sort: 'brand,asc',
    },
  });

  return response.data;
}

export async function createTireService(data: Tire): Promise<TireFormData> {
  const response = await api.post('/tires/', data);

  return response.data;
}

export async function updateTireService(
  data: TireFormData,
): Promise<TireFormData> {
  const response = await api.put(`/tires/`, data);

  console.log('updateTireService', data);

  return response.data;
}

export async function deleteTireService(id: number) {
  await api.delete(`/tires/${id}`);
}
