import { useMemo } from 'react';

import { usePermission } from '../../../hooks/usePermission';

interface IProps {
  selectedTaskId?: number;
  isFormModalOpen: boolean;
  isTaskStatusNotCompleted: boolean;
  isTaskOwner: boolean;
}

export default function useScheduleTaskPermission({
  selectedTaskId,
  isFormModalOpen,
  isTaskStatusNotCompleted,
  isTaskOwner,
}: IProps) {
  const { isAdmin, isEditor } = usePermission();

  return useMemo(() => {
    if (!isFormModalOpen) {
      return false;
    }

    if (isAdmin) {
      return true;
    }

    if (isEditor && !isTaskStatusNotCompleted) {
      if (isTaskOwner) {
        return true;
      }

      if (!selectedTaskId) {
        return true;
      }

      return false;
    }

    return false;
  }, [
    isAdmin,
    isEditor,
    isFormModalOpen,
    isTaskStatusNotCompleted,
    isTaskOwner,
    selectedTaskId,
  ]);
}
