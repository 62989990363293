import {
  ProjectFormData,
  Project,
  ProjectsResponseDataProps,
  LinkReportStatus,
} from '../types/project';
import { getDateFormatView } from '../utils/getDateFormat';
import api from './api';

function handleProjectFormData(data: ProjectFormData) {
  return {
    ...data,
    services: (data.services?.map(id => ({ id })) as any) ?? [],
    linkReports: data.linkReports?.map(linkReport => ({
      ...linkReport,
      estimatedDate:
        linkReport.estimatedDate && new Date(linkReport.estimatedDate),
    })),
    focusRequest: data.focusRequest ?? undefined,
    started: data.started && new Date(data.started),
    finished: data.finished && new Date(data.finished),
  };
}

export async function getProjectListService({
  page = 0,
  size = 10,
  folder,
  responsible,
}: {
  page?: number;
  size?: number;
  folder?: string;
  responsible?: number;
}): Promise<ProjectsResponseDataProps> {
  const response = await api.get(`/projects/`, {
    params: {
      folder,
      responsible,
      page,
      size,
    },
  });

  return {
    ...response.data,
    _embedded: {
      ...response.data._embedded,
      projects: response.data._embedded.projects?.map((project: Project) => ({
        ...project,
        linkReports: project.linkReports?.map(linkReport => ({
          ...linkReport,
          estimatedDate: getDateFormatView(String(linkReport.estimatedDate)),
        })),
      })),
    },
  };
}

export async function getProjectByIdService(id: number): Promise<Project> {
  const response = await api.get(`/projects/${id}`);

  return response.data;
}

export async function createProjectService(
  data: ProjectFormData,
): Promise<ProjectFormData> {
  data = {
    ...data,
    services: [data.services as unknown as number],
  };
  const response = await api.post('/projects/', handleProjectFormData(data));

  return response.data;
}

export async function updateProjectService(
  data: ProjectFormData,
): Promise<ProjectFormData> {
  data = {
    ...data,
    services: [data.services as unknown as number],
  };

  const response = await api.put(`/projects/`, handleProjectFormData(data));

  return response.data;
}

export async function deleteProjectService(id: number): Promise<void> {
  await api.delete(`/projects/${id}`);
}

export async function getProjectStatusService() {
  const response = await api.get(`/projects/status/`);

  return response.data;
}

export async function getProjectLinkReportStatusService(): Promise<LinkReportStatus> {
  const response = await api.get(`/projects/link-report-status/`);

  return response.data;
}
