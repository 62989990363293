import { useQuery } from 'react-query';

import { IsOpenOverviewProps } from '..';

import {
  getProjectsPerTestTypeService,
  getTiresPerTestTypeService,
  getProjectsPerTestTypeJoinnedService,
  getTiresPerTestTypeJoinnedService,
} from '../../../services/dashboard';
import { DepartamentOverviewProps } from '../../../types/dashboard';

export default function useDepartamentChartData(
  isOpenOverview: IsOpenOverviewProps,
): DepartamentOverviewProps {
  const { data: projectsPerTestType, isLoading: isLoadingProjectsPerTestType } =
    useQuery('chart_projects_per_test_type', getProjectsPerTestTypeService, {
      enabled: isOpenOverview.departament,
    });

  const { data: tiresPerTestType, isLoading: isLoadingTiresPerTestType } =
    useQuery('chart_tires_per_test_type', getTiresPerTestTypeService, {
      enabled: isOpenOverview.departament,
    });

  const {
    data: projectsPerTestTypeJoinned,
    isLoading: isLoadingProjectsPerTestTypeJoinned,
  } = useQuery(
    'chart_projects_test_type_joinned',
    getProjectsPerTestTypeJoinnedService,
    {
      enabled: isOpenOverview.departament,
    },
  );

  const {
    data: tiresPerTestTypeJoinned,
    isLoading: isLoadingTiresPerTestTypeJoinned,
  } = useQuery(
    'chart_tires_test_type_joinned',
    getTiresPerTestTypeJoinnedService,
    {
      enabled: isOpenOverview.departament,
    },
  );

  return {
    projectsPerTestType,
    isLoadingProjectsPerTestType,

    tiresPerTestType,
    isLoadingTiresPerTestType,

    projectsPerTestTypeJoinned,
    isLoadingProjectsPerTestTypeJoinned,

    tiresPerTestTypeJoinned,
    isLoadingTiresPerTestTypeJoinned,
  };
}
