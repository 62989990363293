import { useQuery } from 'react-query';

import { IsOpenOverviewProps } from '..';

import {
  getProjectsPerTestTypeAndEngineerService,
  getProjectsPerEngineerService,
  getProjectsPerSegmentAndEngineerService,
  getProjectsPerStatusAndEngineerService,
  getTiresPerEngineerService,
  getTiresPerStatusAndEngineerService,
  getTiresPerTestTypeAndEngineerService,
  getScheduleTasksPerStatusAndEngineerService,
} from '../../../services/dashboard';
import { EngineersOverviewDataProps } from '../../../types/dashboard';

export default function useEngineersChartData(
  isOpenOverview: IsOpenOverviewProps,
): EngineersOverviewDataProps {
  const {
    data: projectsPerTestTypeAndEngineer,
    isLoading: isLoadingProjectsPerTestTypeAndEngineer,
  } = useQuery(
    'chart_project_per_testType_and_engineer',
    getProjectsPerTestTypeAndEngineerService,
    {
      enabled: isOpenOverview.engineers,
    },
  );

  const { data: projectsPerEngineer, isLoading: isLoadingProjectsPerEngineer } =
    useQuery('chart_projects_per_engineer', getProjectsPerEngineerService, {
      enabled: isOpenOverview.engineers,
    });

  const {
    data: projectsPerSegmentAndEngineers,
    isLoading: isLoadingProjectsPerSegmentAndEngineers,
  } = useQuery(
    'chart_projects_per_segment_and_engineer',
    getProjectsPerSegmentAndEngineerService,
    {
      enabled: isOpenOverview.engineers,
    },
  );

  const {
    data: projectsPerStatusAndEngineer,
    isLoading: isLoadingProjectsPerStatusAndEngineer,
  } = useQuery(
    'chart_projects_per_status_and_engineer',
    getProjectsPerStatusAndEngineerService,
    {
      enabled: isOpenOverview.engineers,
    },
  );

  const {
    data: tiresPerTestTypeAndEngineer,
    isLoading: isLoadingTiresPerTestTypeAndEngineer,
  } = useQuery(
    'chart_tires_per_testType_and_engineer',
    getTiresPerTestTypeAndEngineerService,
    {
      enabled: isOpenOverview.engineers,
    },
  );

  const { data: tiresPerEngineer, isLoading: isLoadingTiresPerEngineer } =
    useQuery('chart_tires_per_engineer', getTiresPerEngineerService, {
      enabled: isOpenOverview.engineers,
    });

  const {
    data: tiresPerStatusAndEngineer,
    isLoading: isLoadingTiresPerStatusAndEngineer,
  } = useQuery(
    'chart_tires_per_status_and_engineer',
    getTiresPerStatusAndEngineerService,
    {
      enabled: isOpenOverview.engineers,
    },
  );

  const {
    data: schedulePerStatusAndEngineer,
    isLoading: isLoadingSchedulePerStatusAndEngineer,
  } = useQuery(
    'chart_schedule_per_status_and_engineer',
    getScheduleTasksPerStatusAndEngineerService,
    {
      enabled: isOpenOverview.schedule,
    },
  );

  return {
    projectsPerEngineer,
    isLoadingProjectsPerEngineer,

    tiresPerEngineer,
    isLoadingTiresPerEngineer,

    projectsPerStatusAndEngineer,
    isLoadingProjectsPerStatusAndEngineer,

    tiresPerStatusAndEngineer,
    isLoadingTiresPerStatusAndEngineer,

    projectsPerTestTypeAndEngineer,
    isLoadingProjectsPerTestTypeAndEngineer,

    projectsPerSegmentAndEngineers,
    isLoadingProjectsPerSegmentAndEngineers,

    tiresPerTestTypeAndEngineer,
    isLoadingTiresPerTestTypeAndEngineer,

    schedulePerStatusAndEngineer,
    isLoadingSchedulePerStatusAndEngineer,
  };
}
