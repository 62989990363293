import I18n from 'i18n-js';

import { DashboardOverview } from '../..';
import { EngineersOverviewDataProps } from '../../../../types/dashboard';
import { BasicHorBarChart } from '../Charts/BasicHorBarChart';
import { StackedBarChart } from '../Charts/StackedBarChart';
import { ChartsBox } from '../ChartsBox';

interface IProps {
  toggleOpen(value: DashboardOverview): void;
  dataChart: EngineersOverviewDataProps;
}

export function EngineersOverview({ toggleOpen, dataChart }: IProps) {
  return (
    <ChartsBox
      overviewName="engineers"
      toggleOpen={toggleOpen}
      title={I18n.t('page.dashboard.engineers.title')}
    >
      <BasicHorBarChart
        title={I18n.t('page.dashboard.engineers.chart.projectsPerEngineer')}
        data={dataChart.projectsPerEngineer}
        isLoading={dataChart.isLoadingProjectsPerEngineer}
      />

      <BasicHorBarChart
        title={I18n.t('page.dashboard.engineers.chart.tiresPerEngineer')}
        data={dataChart.tiresPerEngineer}
        isLoading={dataChart.isLoadingTiresPerEngineer}
        color="#e68deb"
      />

      <StackedBarChart
        title={I18n.t(
          'page.dashboard.engineers.chart.projectsPerStatusAndEngineer',
        )}
        data={dataChart.projectsPerStatusAndEngineer}
        isLoading={dataChart.isLoadingProjectsPerStatusAndEngineer}
      />

      <StackedBarChart
        title={I18n.t(
          'page.dashboard.engineers.chart.tiresPerStatusAndEngineer',
        )}
        data={dataChart.tiresPerStatusAndEngineer}
        isLoading={dataChart.isLoadingTiresPerStatusAndEngineer}
      />

      <StackedBarChart
        title={I18n.t(
          'page.dashboard.engineers.chart.projectsPerTestTypeAndEngineer',
        )}
        data={dataChart.projectsPerTestTypeAndEngineer}
        isLoading={dataChart.isLoadingProjectsPerTestTypeAndEngineer}
      />

      <StackedBarChart
        title={I18n.t(
          'page.dashboard.engineers.chart.projectsPerSegmentAndEngineer',
        )}
        data={dataChart.projectsPerSegmentAndEngineers}
        isLoading={dataChart.isLoadingProjectsPerSegmentAndEngineers}
      />

      <StackedBarChart
        title={I18n.t(
          'page.dashboard.engineers.chart.tiresPerTestTypeAndEngineer',
        )}
        data={dataChart.tiresPerTestTypeAndEngineer}
        isLoading={dataChart.isLoadingTiresPerTestTypeAndEngineer}
      />

      <StackedBarChart
        title={I18n.t(
          'page.dashboard.scheduleTasks.chart.tasksPerStatusAndEngineer',
        )}
        data={dataChart.schedulePerStatusAndEngineer}
        isLoading={dataChart.isLoadingSchedulePerStatusAndEngineer}
      />
    </ChartsBox>
  );
}
