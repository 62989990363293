import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { updateFleetService } from '../../../services/fleets';
import { queryClient } from '../../../services/queryClient';
import { FleetDataCategorized } from '../../../types/fleet';

export function useEditFleet(onRequestClose: () => void) {
  return useMutation(
    async (data: FleetDataCategorized) => {
      return updateFleetService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fleets');
        onRequestClose();
        toast.success('Fleet updated successfully');
      },
      onError: () => {
        toast.error('Error updating fleet');
      },
    },
  );
}
