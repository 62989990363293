import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';

import {
  convertCityToDropdownOption,
  convertStateToDropdownOption,
} from '../helpers/convertLocaleToDropdownOption';
import {
  getStateService,
  getCitiesByStateIdService,
} from '../services/localities';

export default function useLocaleQueries(stateQueryKey: string) {
  const { control } = useFormContext();
  const { data: states, isLoading: isLoadingStates } = useQuery(
    'states',
    async () => {
      return convertStateToDropdownOption(await getStateService());
    },
  );

  const stateValue = useWatch({
    control,
    name: stateQueryKey,
  });

  const stateInfo = states?.find(state => state.value === stateValue);

  const { data: citiesByState, isLoading: isLoadingCitiesByState } = useQuery(
    ['citiesByState', stateInfo?.key],
    async () => {
      return convertCityToDropdownOption(
        await getCitiesByStateIdService(Number(stateInfo?.key)),
      );
    },
    {
      enabled: !!stateInfo?.key,
    },
  );

  return {
    states,
    citiesByState,
    isLoadingStates,
    isLoadingCitiesByState,
  };
}
