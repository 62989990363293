import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { uploadFileService } from '../../../../../../../services/fileService';
import { queryClient } from '../../../../../../../services/queryClient';

interface IProps {
  index: number;

  currentRouteFiles: File[];
}

export function useUploadFile({
  index,

  currentRouteFiles,
}: IProps) {
  return useMutation(
    async () => {
      return uploadFileService(currentRouteFiles[index], 'FLEET');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ROUTE_FILE');
        toast.success('File uploaded successfuly');
      },
      onError: (error: any) => {
        if (error?.response?.status === 409) {
          toast.warn('This file has already been uploaded to the server');
        }

        if (error?.response?.status === 413) {
          toast.warn(
            'Could not upload this file to the server because it is too large',
          );
        } else {
          toast.error('Error uploading file');
        }
      },
    },
  );
}
