import { GenericObject } from '../types/fleet';
import api from './api';

const TRUCK = '/truck';

export async function getTruckService(): Promise<GenericObject[]> {
  const response = await api.get(`${TRUCK}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createTruckService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${TRUCK}/`, item);

  return response.data;
}

export async function updateTruckService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${TRUCK}/`, item);

  return response.data;
}

export async function deleteTruckService(id: number) {
  await api.delete(`${TRUCK}/${id}`);
}
