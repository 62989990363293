import api from './api';

export interface GenericObject {
  id?: number;
  label: string;
}

const REPORT_PERCENTAGE = '/percentageReport';

export async function getReportPercentageType(): Promise<GenericObject[]> {
  const response = await api.get(`${REPORT_PERCENTAGE}/`);
  return response.data;
}

export async function createReportPercentageType(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${REPORT_PERCENTAGE}/`, item);
  return response.data;
}

export async function updateReportPercentageType(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${REPORT_PERCENTAGE}/`, item);
  return response.data;
}

export async function deleteReportPercentageType(id: number) {
  await api.delete(`${REPORT_PERCENTAGE}/${id}`);
}
