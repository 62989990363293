import { Loader } from 'semantic-ui-react';

import { Container } from './styles';

interface IChartboxProps {
  title: string;
  value?: number;
  isLoading?: boolean;
  color?: string;
}

export function BasicChart({ title, value, isLoading, color }: IChartboxProps) {
  return (
    <Container color={color}>
      <div className="minichartbox__title">{title}</div>
      <div className="minichartbox__content">
        {isLoading ? <Loader active inline /> : value ?? 0}
      </div>
    </Container>
  );
}
