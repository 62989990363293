import { GenericObject } from '../types/fleet';
import api from './api';

const USED_BAND = '/usedBand';

export async function getUsedBandService(): Promise<GenericObject[]> {
  const response = await api.get(`${USED_BAND}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createUsedBandService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${USED_BAND}/`, item);

  return response.data;
}

export async function updateUsedBandService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${USED_BAND}/`, item);

  return response.data;
}

export async function deleteUsedBandService(id: number) {
  await api.delete(`${USED_BAND}/${id}`);
}
