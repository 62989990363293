import { useMemo } from 'react';

export function useFleetFormDataCachedExists() {
  return useMemo(() => {
    const newArray: any[] = [];

    const fleetFormDataOnLocalStorage = JSON.parse(
      localStorage.getItem('@FleetFormData') as any,
    );

    Object.values(fleetFormDataOnLocalStorage ?? {}).forEach(item => {
      if (item && typeof item === 'object') {
        newArray.push(...Object.values(item));
      }
      return undefined;
    });

    return newArray.some(
      value => value?.length > 0 || typeof value === 'number',
    );
  }, []);
}
