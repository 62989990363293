import { Controller, useFormContext } from 'react-hook-form';
import {
  Dropdown,
  DropdownItemProps,
  StrictDropdownProps,
} from 'semantic-ui-react';

type Props = Omit<StrictDropdownProps, 'control' | 'error'> & {
  controllerName: string;
  options: DropdownItemProps[];
};

export default function ControllerDropdown({
  controllerName,
  options,
  ...rest
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={controllerName}
      render={({ field: { value, onChange } }) => (
        <Dropdown
          search
          selection
          value={value}
          options={options ?? []}
          onChange={(_, data) => onChange(data.value)}
          {...rest}
        />
      )}
    />
  );
}
