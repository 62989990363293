import styled from 'styled-components';

export const JustifyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 992px) {
    margin-right: 20px;
  }
`;
