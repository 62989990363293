/* eslint-disable @typescript-eslint/ban-ts-comment */
import InputMask from 'react-input-mask';
import { UseMutationResult } from 'react-query';
import { Header, Table as SUITable, Icon, Input } from 'semantic-ui-react';

import { ItemsProps } from '../..';
import { User } from '../../../../../../../types/user';
import { handleChangeLabelItem } from '../../../../utils/handleChangeLabelItem';
import { handleClickCheckItem } from '../../utils/handleClickCheckItem';
import { handleClickEditItem } from '../../utils/handleClickEditItem';

interface Props {
  items?: ItemsProps[];
  setItems: React.Dispatch<React.SetStateAction<ItemsProps[] | undefined>>;
  handleUpdateItem: UseMutationResult<User, unknown, any, unknown>;
  handleDeleteItem: UseMutationResult<void, unknown, number, unknown>;
}

export function Table({
  items,
  setItems,
  handleUpdateItem,
  handleDeleteItem,
}: Props) {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>, id?: number) {
    const { value } = e.currentTarget;

    handleChangeLabelItem({
      items,
      setItems,
      id,
      e,
      value,
    });
  }
  return (
    <SUITable compact striped unstackable>
      <SUITable.Header>
        <SUITable.Row>
          <SUITable.HeaderCell>Name</SUITable.HeaderCell>
          <SUITable.HeaderCell colSpan="4">Contact</SUITable.HeaderCell>
        </SUITable.Row>
      </SUITable.Header>
      <SUITable.Body>
        {items?.map(({ id, name, phone, email, isEdit }) => (
          <SUITable.Row key={`${id}`}>
            <SUITable.Cell>
              {isEdit ? (
                <Input
                  name="name"
                  value={name}
                  onChange={(e, { value }) =>
                    handleChangeLabelItem({
                      items,
                      setItems,
                      id,
                      e,
                      value,
                    })
                  }
                  fluid
                />
              ) : (
                name
              )}
            </SUITable.Cell>
            <SUITable.Cell>
              <Header as="h5">
                <Header.Content style={{ width: '100%' }}>
                  {isEdit ? (
                    <InputMask
                      mask="(99) 99999-9999"
                      maskChar={null}
                      value={phone}
                      onChange={e => handleChange(e, id)}
                    >
                      {/* @ts-ignore */}
                      {inputMask => (
                        <Input {...inputMask} name="phone" width="8" fluid />
                      )}
                    </InputMask>
                  ) : (
                    phone
                  )}
                  <Header.Subheader>
                    {isEdit ? (
                      <Input
                        name="email"
                        value={email}
                        onChange={(e, { value }) =>
                          handleChangeLabelItem({
                            items,
                            setItems,
                            id,
                            e,
                            value,
                            isLowerCase: true,
                          })
                        }
                        fluid
                      />
                    ) : (
                      email
                    )}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </SUITable.Cell>
            {isEdit ? (
              <SUITable.Cell width="1">
                <Icon
                  link
                  name="check"
                  color="green"
                  onClick={() =>
                    handleClickCheckItem({
                      items,
                      setItems,
                      id,
                      handleUpdateItem,
                    })
                  }
                />
              </SUITable.Cell>
            ) : (
              <SUITable.Cell width="1" />
            )}
            <SUITable.Cell width="1">
              <Icon
                link
                name="pencil"
                color={isEdit ? undefined : 'yellow'}
                onClick={() =>
                  handleClickEditItem({
                    items,
                    setItems,
                    id,
                  })
                }
                disabled={isEdit}
              />
            </SUITable.Cell>
            <SUITable.Cell width="1">
              <Icon
                link
                name="trash"
                color={isEdit ? undefined : 'red'}
                onClick={() => handleDeleteItem.mutateAsync(id as number)}
                disabled={isEdit}
              />
            </SUITable.Cell>
          </SUITable.Row>
        ))}
      </SUITable.Body>
    </SUITable>
  );
}
