import I18n from 'i18n-js';

import GoPassImg from '../../../assets/img/gopass_logo.png';
import { Button } from '../../../components/Form/Button';
import { useAuth } from '../../../contexts/auth';

export function SignInButton() {
  const { signIn } = useAuth();

  return (
    <Button
      title={I18n.t('page.login.button.loginGoodyear')}
      image={GoPassImg}
      onClick={signIn}
    />
  );
}
