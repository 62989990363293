import I18n from 'i18n-js';
import {
  Button,
  Dropdown,
  Header,
  Icon,
  PaginationProps,
  Popup,
  Table as TableSemantic,
} from 'semantic-ui-react';

import { Pagination } from '../../../../components/Pagination';
import useIsMobileScreen from '../../../../hooks/useIsMobileScreen';
import { Report, ReportsResponseDataProps } from '../../../../types/report';
import { getDateFormat } from '../../../../utils/getDateFormat';
import { HeaderTable } from '../Header';

interface TableProps {
  reports?: ReportsResponseDataProps;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  handleReportFormModalOpen(report: Report): void;
  handleReportDeleteModalOpen(report: Report): void;
}

export function Table({
  reports,
  page,
  setPage,
  handleReportFormModalOpen,
  handleReportDeleteModalOpen,
}: TableProps) {
  const { isMobileScreen } = useIsMobileScreen(460);

  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps,
  ) {
    setPage(activePage as number);
  }

  return (
    <span>
      <div className="table__scrollable">
        <TableSemantic className="table" unstackable striped>
          <HeaderTable />
          <TableSemantic.Body className="tbody">
            {reports ? (
              reports?._embedded.reports?.map((report, index) => {
                return (
                  <TableSemantic.Row className="tr noRowspan" key={report.id}>
                    <TableSemantic.Cell className="td id">
                      {index + 1}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {report.percentage?.label}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {report.avgNskMm}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {report.wornOut}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {report.kmRun}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {report.kmMm}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {report.kmMmRate}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {report.kmProject}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {report.kmProjectRate}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {getDateFormat(report?.reportDate)}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {report.cycle}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {report.retreadIndex}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td">
                      {getDateFormat(report?.dateIssued)}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell className="td action">
                      <Dropdown
                        pointing="right"
                        trigger={<Icon name="ellipsis vertical" size="large" />}
                        icon={null}
                      >
                        <Dropdown.Menu>
                          <span className="action-buttons">
                            <Popup
                              disabled={isMobileScreen}
                              content={I18n.t(
                                'page.reports.tooltip.action.edit',
                              )}
                              trigger={
                                <Button
                                  icon="pencil"
                                  size="mini"
                                  color="yellow"
                                  onClick={() =>
                                    handleReportFormModalOpen(report)
                                  }
                                />
                              }
                            />
                            <Popup
                              disabled={isMobileScreen}
                              content={I18n.t(
                                'page.reports.tooltip.action.delete',
                              )}
                              trigger={
                                <Button
                                  icon="trash"
                                  size="mini"
                                  color="red"
                                  onClick={() =>
                                    handleReportDeleteModalOpen(report)
                                  }
                                />
                              }
                            />
                          </span>
                        </Dropdown.Menu>
                      </Dropdown>
                    </TableSemantic.Cell>
                  </TableSemantic.Row>
                );
              })
            ) : (
              <TableSemantic.Row>
                <TableSemantic.Cell colSpan="20">
                  <Header>{I18n.t('error.noDataFound')}</Header>
                </TableSemantic.Cell>
              </TableSemantic.Row>
            )}
          </TableSemantic.Body>
        </TableSemantic>
      </div>

      <div className="table__pagination">
        <Pagination
          page={page}
          totalPages={reports?.page.totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </span>
  );
}
