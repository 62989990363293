import {
  Header as HeaderSemantic,
  Icon,
  SemanticICONS,
} from 'semantic-ui-react';

import { Container } from './styles';

interface IHeaderProps {
  icon?: SemanticICONS;
  content: string;
}

export function Header({ icon, content }: IHeaderProps) {
  return (
    <Container>
      <HeaderSemantic className="category">
        <Icon size="small" name={icon} />
        {content}
        <Icon disabled />
      </HeaderSemantic>
    </Container>
  );
}
