import I18n from 'i18n-js';
import { useEffect, useState } from 'react';
import { DropdownProps, Form } from 'semantic-ui-react';

import { FilterParamsNamesProps, FilterParamsProps } from '../..';
import { FilterGroup } from '../../../../components/FilterGroup';
import { useAuth } from '../../../../contexts/auth';
import { useScheduleFilterFormResquests } from '../../hooks/useScheduleFilterFormResquests';

interface IProps {
  filterParams: FilterParamsProps;
  setFilterParams: React.Dispatch<React.SetStateAction<FilterParamsProps>>;
}

export default function FilterContent({
  filterParams,
  setFilterParams,
}: IProps) {
  const { user } = useAuth();
  const [isSelectAllResponsibles, setIsSelectAllResponsibles] = useState(false);

  const {
    businessOptions,
    isLoadingBusinessOptions,

    taskTypeOptions,
    taskNameOptions,
    isLoadingScheduleData,

    priorityOptions,
    isLoadingPriorityOptions,

    statusOptions,
    isLoadingStatusOptions,

    projectOptions,
    isLoadingProjectOptions,

    fleetOptions,
    isLoadingFleetOptions,

    usersOptions,
    isUsersLoading,
  } = useScheduleFilterFormResquests(filterParams);

  useEffect(() => {
    const currentUserEmail = usersOptions?.find(
      ({ value }) => value === user?.mail.toLowerCase(),
    )?.value as string;

    if (currentUserEmail) {
      setFilterParams(prev => ({
        ...prev,
        selectedUser: [currentUserEmail],
      }));
    }

    if (isSelectAllResponsibles && usersOptions) {
      setFilterParams(prev => ({
        ...prev,
        selectedUser: usersOptions.map(({ value }) => value) as string[],
      }));
    } else {
      setFilterParams(prev => ({
        ...prev,
        selectedUser: [currentUserEmail],
      }));
    }
  }, [isSelectAllResponsibles, setFilterParams, user?.mail, usersOptions]);

  function handleFilterParams(
    param: FilterParamsNamesProps,
    data: DropdownProps,
  ) {
    setFilterParams(prev => ({
      ...prev,
      [param]: data.value,
    }));
  }

  return (
    <FilterGroup>
      <Form.Dropdown
        label={I18n.t('page.schedule.label.business')}
        search
        clearable
        selection
        options={businessOptions ?? []}
        value={filterParams?.selectedBusinessId}
        onChange={(_, data) => handleFilterParams('selectedBusinessId', data)}
        loading={isLoadingBusinessOptions}
      />

      <Form.Dropdown
        label={I18n.t('page.schedule.label.taskType')}
        search
        clearable
        selection
        options={taskTypeOptions ?? []}
        value={filterParams?.selectedTaskTypeId}
        onChange={(_, data) => handleFilterParams('selectedTaskTypeId', data)}
        loading={isLoadingScheduleData}
      />

      <Form.Dropdown
        label={I18n.t('page.schedule.label.taskName')}
        search
        clearable
        selection
        options={taskNameOptions ?? []}
        value={filterParams?.selectedTaskNameId}
        onChange={(_, data) => handleFilterParams('selectedTaskNameId', data)}
        loading={isLoadingBusinessOptions}
      />

      <Form.Dropdown
        label={I18n.t('page.schedule.label.priority')}
        search
        clearable
        selection
        options={priorityOptions ?? []}
        value={filterParams?.selectedPriorityId}
        onChange={(_, data) => handleFilterParams('selectedPriorityId', data)}
        loading={isLoadingPriorityOptions}
      />

      <Form.Dropdown
        label={I18n.t('page.schedule.label.status')}
        search
        clearable
        selection
        options={statusOptions ?? []}
        value={filterParams?.selectedStatusId}
        onChange={(_, data) => handleFilterParams('selectedStatusId', data)}
        loading={isLoadingStatusOptions}
      />

      <Form.Dropdown
        label={I18n.t('page.schedule.label.project')}
        search
        clearable
        selection
        options={projectOptions ?? []}
        value={filterParams?.selectedProjectId}
        onChange={(_, data) => handleFilterParams('selectedProjectId', data)}
        loading={isLoadingProjectOptions}
      />

      <Form.Dropdown
        label={I18n.t('page.schedule.label.fleet')}
        search
        clearable
        selection
        options={fleetOptions ?? []}
        value={filterParams?.selectedFleetId}
        onChange={(_, data) => handleFilterParams('selectedFleetId', data)}
        loading={isLoadingFleetOptions}
      />

      <Form.Dropdown
        label={I18n.t('page.schedule.label.responsible')}
        search
        clearable
        selection
        multiple
        options={usersOptions ?? []}
        value={filterParams?.selectedUser}
        onChange={(_, data) => handleFilterParams('selectedUser', data)}
        loading={isUsersLoading}
      />

      <Form.Checkbox
        label={I18n.t('page.schedule.label.selectAllResponsibles')}
        checked={isSelectAllResponsibles}
        onChange={() => setIsSelectAllResponsibles(prev => !prev)}
      />
    </FilterGroup>
  );
}
