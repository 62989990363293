import { ButtonProps, Icon, SemanticICONS } from 'semantic-ui-react';

import { Container, Image } from './styles';

interface Props extends ButtonProps {
  title: string;
  iconName?: SemanticICONS;
  outline?: boolean;
  image?: any;
}

export function Button({ title, iconName, outline, image, ...props }: Props) {
  return (
    <Container {...props} outline={outline}>
      {iconName && <Icon name={iconName} />}
      {image && <Image src={image} />}
      {title}
    </Container>
  );
}
