import { format } from 'date-fns';
import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';

import { toDropdownItemProps } from '../../../helpers/toDropdownItemProps';
import { getFleetListService } from '../../../services/fleets';
import { getFleetTypeService } from '../../../services/fleetTypeService';
import { getProjectListService } from '../../../services/project';
import {
  getScheduleTaskPrioritiesService,
  getScheduleService,
  getScheduleTaskStatusService,
  getScheduleHolidayListService,
} from '../../../services/schedule';
import { getUserListService } from '../../../services/user';
import {
  FLEET_OPTIONS_QUERYKEY,
  USERS_QUERYKEY,
} from '../../../settings/constants';
import { ScheduleActivityFormData } from '../../../types/schedule';

export function useScheduleRequests(
  control: Control<ScheduleActivityFormData, any>,
) {
  const { data: priorityOptions, isLoading: isLoadingPriorityOptions } =
    useQuery('PRIORITIES_QUERYKEY', async () =>
      toDropdownItemProps(await getScheduleTaskPrioritiesService(), true),
    );

  const { data: statusOptions, isLoading: isLoadingStatusOptions } = useQuery(
    'SCHEDULE_TASK_STATUS_QUERYKEY',
    async () => toDropdownItemProps(await getScheduleTaskStatusService(), true),
  );

  const { data: fleetTypeOptions, isLoading: isLoadingFleetTypeOptions } =
    useQuery('FLEET_TYPE_QUERYKEY', async () =>
      toDropdownItemProps(await getFleetTypeService()),
    );

  const { data: scheduleData, isLoading: isLoadingScheduleData } = useQuery(
    'scheduleData',
    getScheduleService,
  );

  const taskTypeOptions = useMemo(
    () => toDropdownItemProps(scheduleData?.datasets),
    [scheduleData?.datasets],
  );

  const taskTypeId = useWatch({ control, name: 'typeActivityId' });

  const taskOptions = useMemo(() => {
    const taskList = scheduleData?.datasets?.find(
      ({ id }) => id === taskTypeId,
    );

    return toDropdownItemProps(taskList?.values);
  }, [taskTypeId, scheduleData?.datasets]);

  const frequenceOptions = useMemo(() => {
    return toDropdownItemProps(scheduleData?.frequencies);
  }, [scheduleData?.frequencies]);

  const { data: projectOptions, isLoading: isLoadingProjectOptions } = useQuery(
    'PROJECT_OPTIONS',
    async () => {
      const data = await getProjectListService({
        size: 1000,
        page: 0,
      });

      return toDropdownItemProps(data?._embedded.projects);
    },
  );

  const { data: fleetOptions, isLoading: isLoadingFleetOptions } = useQuery(
    FLEET_OPTIONS_QUERYKEY,
    async () => {
      const data = await getFleetListService({
        page: 0,
        size: 1000,
      });

      return toDropdownItemProps(data?._embedded.fleets);
    },
  );

  const { data: userOptions, isLoading: isLoadingUserOptions } = useQuery(
    USERS_QUERYKEY,
    getUserListService,
  );

  const { data: holidayList, isLoading: isLoadingHolidayList } = useQuery(
    'HOLIDAY_LIST_FORMATED',
    async () => {
      const response = await getScheduleHolidayListService();

      return response.map(holiday => ({
        ...holiday,
        holidayDate: format(new Date(holiday.holidayDate), 'yyyy-MM-dd'),
      }));
    },
  );

  return {
    statusOptions,
    isLoadingStatusOptions,

    priorityOptions,
    isLoadingPriorityOptions,

    fleetTypeOptions,
    isLoadingFleetTypeOptions,

    taskTypeOptions,
    taskOptions,
    frequenceOptions,
    isLoadingScheduleData,

    projectOptions,
    isLoadingProjectOptions,

    fleetOptions,
    isLoadingFleetOptions,

    userOptions,
    isLoadingUserOptions,

    holidayList,
    isLoadingHolidayList,
  };
}
