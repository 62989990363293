import { GenericObject } from '../types/fleet';
import api from './api';

const TIRE_POSITIONS = '/tirePositions';

export async function getTirePositionService(): Promise<GenericObject[]> {
  const response = await api.get(`${TIRE_POSITIONS}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createTirePositionService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${TIRE_POSITIONS}/`, item);

  return response.data;
}

export async function updateTirePositionService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${TIRE_POSITIONS}/`, item);

  return response.data;
}

export async function deleteTirePositionService(id: number) {
  await api.delete(`${TIRE_POSITIONS}/${id}`);
}
