import I18n from 'i18n-js';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../services/queryClient';
import { updateScheduleTaskService } from '../../../services/schedule';
import { SCHEDULE_TASKS } from '../../../settings/constants';
import { ScheduleActivityFormData } from '../../../types/schedule';

interface Props {
  onRequestClose(): void;
}

export function useUpdateScheduleTask({ onRequestClose }: Props) {
  return useMutation(
    async (data: ScheduleActivityFormData) => {
      return updateScheduleTaskService(data, false);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SCHEDULE_TASKS);
        toast.success(I18n.t('page.schedule.toast.success.create'));

        onRequestClose();
      },
      onError: () => {
        toast.error(I18n.t('page.schedule.toast.error.create'));
      },
    },
  );
}
