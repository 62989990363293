import { useEffect } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useQuery } from 'react-query';

import { getScheduleActivityByIdService } from '../../../services/schedule';
import { ScheduleActivityFormData } from '../../../types/schedule';
import toScheduleFormData from '../components/ScheduleTaskFormModal/utils/toScheduleFormData';

interface IProps {
  scheduleTaskId?: number;
  reset: UseFormReset<ScheduleActivityFormData>;
}

export function useScheduleTaskById({ scheduleTaskId, reset }: IProps) {
  const { data } = useQuery(
    'task_data_by_id',
    async () => getScheduleActivityByIdService(scheduleTaskId),
    {
      enabled: !!scheduleTaskId,
    },
  );

  useEffect(() => {
    if (data && scheduleTaskId) {
      reset(toScheduleFormData(data));
    } else {
      reset(undefined);
    }
  }, [data]);

  return {
    userEmail: data?.user.email,
    isStatusNotCompleted: !!data?.isNotCompleted,
    taskData: data ? toScheduleFormData(data) : undefined,
  };
}
