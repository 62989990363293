import {
  Icon,
  Pagination as PaginationSUI,
  PaginationProps,
} from 'semantic-ui-react';

interface IProps {
  page: number;
  totalPages?: number;
  handlePageChange(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps,
  ): void;
}

export function Pagination({ page, totalPages, handlePageChange }: IProps) {
  return (
    <PaginationSUI
      activePage={page}
      totalPages={totalPages ?? 0}
      onPageChange={handlePageChange}
      boundaryRange={1}
      firstItem={{ content: <Icon name="angle double left" />, icon: true }}
      lastItem={{ content: <Icon name="angle double right" />, icon: true }}
      prevItem={{ content: <Icon name="angle left" />, icon: true }}
      nextItem={{ content: <Icon name="angle right" />, icon: true }}
      siblingRange={1}
      size="mini"
    />
  );
}
