import { useQuery } from 'react-query';

import { getFleetListService } from '../services/fleets';
import { getFleetTypeService } from '../services/fleetTypeService';
import { getUserListService } from '../services/user';
import { USERS_QUERYKEY } from '../settings/constants';

export function useFleetQueries(page: number, query: any) {
  const { data: fleets, isLoading: isLoadingFleets } = useQuery(
    ['fleets', { page, ...query }],
    () => {
      return getFleetListService({
        page: page - 1,
        ...query,
      });
    },
  );

  const { data: fleetType } = useQuery('fleet_type', async () => {
    const response = await getFleetTypeService();

    return response.map(user => ({
      key: user.id,
      text: user.label,
      value: user.id,
    }));
  });

  const { data: users } = useQuery(`${USERS_QUERYKEY}_dropdown`, async () => {
    const response = await getUserListService();

    return response.map(user => ({
      key: user.id,
      text: user.name,
      value: user.id,
    }));
  });

  return {
    fleets,
    isLoadingFleets,
    fleetType,
    users,
  };
}
