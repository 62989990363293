import { GenericObject } from '../types/fleet';
import api from './api';

const SWEEP_RADIUS = '/sweepRadius';

export async function getScrapingRadius(): Promise<GenericObject[]> {
  const response = await api.get(`${SWEEP_RADIUS}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createScrapingRadius(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${SWEEP_RADIUS}/`, item);

  return response.data;
}

export async function updateScrapingRadius(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${SWEEP_RADIUS}/`, item);

  return response.data;
}

export async function deleteScrapingRadius(id: number) {
  await api.delete(`${SWEEP_RADIUS}/${id}`);
}
