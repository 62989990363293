import axios from 'axios';

import { City, State } from '../types/localities';

export async function getStateService(): Promise<State[]> {
  const response = await axios.get(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`,
  );

  return response.data;
}

export async function getStateByIdService(stateId?: number): Promise<State> {
  const response = await axios.get(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}`,
  );

  return response.data;
}

export async function getCityByNameService(): Promise<City[]> {
  const response = await axios.get(
    `https://servicodados.ibge.gov.br/api/v1/localidades/municipios`,
  );

  return response.data;
}

export async function getCitiesByStateIdService(
  stateId?: number,
): Promise<City[]> {
  const response = await axios.get(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`,
  );

  return response.data;
}
