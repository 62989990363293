import {
  ChartDataProps,
  ResponseFleetsPerSegment,
  ResponseProjectsPerEngineer,
  ResponseProjectsPerSegment,
  ResponseProjectsPerTestType,
  GenericResponseScheduleTask,
  ResponseScheduleTaskPerName,
  ResponseTiresPerEngineer,
  ResponseTiresPerFleet,
  ResponseTiresPerSegment,
  TireTestTypeItemProps,
  ResponseScheduleTaskType,
  BasicChartsProps,
  TestTypeGroupProps,
} from '../types/dashboard';
import api from './api';

export interface IDashProjectsPerTestTypeAndReport {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
  }[];
}

export async function getBasicChartsService(): Promise<BasicChartsProps> {
  const response = await api.get('/dashboard/cards');

  return response.data[0];
}

export async function getProjectsPerTestTypeService(): Promise<ChartDataProps> {
  const { data } = await api.get<ResponseProjectsPerTestType[]>(
    '/dashboard/projects-test-type',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ qty }) => qty),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getProjectsPerTestTypeJoinnedService(): Promise<ChartDataProps> {
  const { data } = await api.get<TestTypeGroupProps[]>(
    '/dashboard/test-type-group',
  );

  const projectsPerTestTypelabels = data.map(({ label }) => label);
  const projectsPerTestTypeQty = data.map(({ qty }) => qty);
  const projectsPerTestTypePercent = data.map(({ percent }) =>
    percent?.toFixed(2),
  );

  return {
    labels: projectsPerTestTypelabels,
    datasets: [
      {
        data: projectsPerTestTypeQty,
        dataInPercent: projectsPerTestTypePercent,
      },
    ],
  };
}

export async function getTiresPerTestTypeService(): Promise<ChartDataProps> {
  const { data } = await api.get<ResponseProjectsPerTestType[]>(
    '/dashboard/tires-test-type',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ qty }) => qty),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getTiresPerTestTypeJoinnedService(): Promise<ChartDataProps> {
  const { data } = await api.get<TireTestTypeItemProps[]>(
    '/dashboard/test-type-tires-group',
  );

  const tiresTestTypesLabels = data.map(({ label }) => label);
  const tiresPerTestTypeQty = data.map(({ qty }) => qty);

  const tiresPerTestTypePercent = data.map(({ percent }) =>
    percent?.toFixed(2),
  );

  return {
    labels: tiresTestTypesLabels,
    datasets: [
      {
        data: tiresPerTestTypeQty,
        dataInPercent: tiresPerTestTypePercent,
      },
    ],
  };
}

export async function getProjectsPerTestTypeAndReportService(): Promise<ChartDataProps> {
  const { data } = await api.get<ChartDataProps>(
    '/dashboard/projects-per-test-type-and-report',
  );

  return {
    labels: data.labels,
    datasets: data.datasets,
  };
}

export async function getTiresPerTestTypeAndReportService(): Promise<ChartDataProps> {
  const { data } = await api.get<ChartDataProps>(
    '/dashboard/tires-per-test-type-and-report',
  );

  return {
    labels: data.labels,
    datasets: data.datasets,
  };
}

export async function getProjectsPerSegmentAndEngineerService(): Promise<ChartDataProps> {
  const { data } = await api.get<ChartDataProps>(
    '/dashboard/projects-per-service-user',
  );

  return {
    labels: data.labels,
    datasets: data.datasets,
  };
}

export async function getProjectsPerSegmentService(): Promise<ChartDataProps> {
  const { data } = await api.get<ResponseProjectsPerSegment[]>(
    '/dashboard/projects-persegment',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ qtdProjects }) => qtdProjects),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getProjectsPerEngineerService(): Promise<ChartDataProps> {
  const { data } = await api.get<ResponseProjectsPerEngineer[]>(
    '/dashboard/total-projects-by-engineers',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ projects }) => projects),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getFleetsPerSegmentService(): Promise<ChartDataProps> {
  const { data } = await api.get<ResponseFleetsPerSegment[]>(
    '/dashboard/fleets-per-service',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ fleetsQuantity }) => fleetsQuantity),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getTiresPerTestTypeAndEngineerService(): Promise<ChartDataProps> {
  const { data } = await api.get<ChartDataProps>(
    '/dashboard/total-tires-test-type-by-engineers',
  );

  return {
    labels: data.labels,
    datasets: data.datasets,
  };
}

export async function getProjectsPerTestTypeAndEngineerService(): Promise<ChartDataProps> {
  const { data } = await api.get<ChartDataProps>(
    '/dashboard/total-projects-test-type-by-engineers',
  );

  return {
    labels: data.labels,
    datasets: data.datasets,
  };
}

export async function getProjectsPerStatusAndEngineerService(): Promise<ChartDataProps> {
  const { data } = await api.get<ChartDataProps>(
    '/dashboard/total-projects-per-status-engineers',
  );

  return {
    labels: data.labels,
    datasets: data.datasets,
  };
}

export async function getTiresPerStatusAndEngineerService(): Promise<ChartDataProps> {
  const { data } = await api.get<ChartDataProps>(
    '/dashboard/total-tires-per-status-engineers',
  );

  console.log('total-tires-per-status-engineers', data);

  return {
    labels: data.labels,
    datasets: data.datasets,
  };
}

export async function getTiresPerFleet(): Promise<ChartDataProps> {
  const { data } = await api.get<ResponseTiresPerFleet[]>(
    '/dashboard/tires-per-fleet',
    {
      params: {
        sort: 'name,asc',
      },
    },
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ tiresQuantity }) => tiresQuantity),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getTiresPerSegmentService(): Promise<ChartDataProps> {
  const { data } = await api.get<ResponseTiresPerSegment[]>(
    '/dashboard/total-tires-per-service',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ tireQuantity }) => tireQuantity),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getTiresPerEngineerService(): Promise<ChartDataProps> {
  const { data } = await api.get<ResponseTiresPerEngineer[]>(
    '/dashboard/tires-per-engineer',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ tireQuantity }) => tireQuantity),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getSchedulePerBusiness(): Promise<ChartDataProps> {
  const { data } = await api.get<GenericResponseScheduleTask[]>(
    '/dashboard/schedule-per-type-fleet',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ quantity }) => quantity),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getSchedulePerTaskType(): Promise<ChartDataProps> {
  const { data } = await api.get<ResponseScheduleTaskType>(
    '/dashboard/schedule-per-type-activity',
  );

  return {
    labels: data.type.map(({ label }) => label),
    datasets: [
      {
        data: data.type.map(({ quantity }) => quantity),
        dataInPercent: data.type.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getSchedulePerTask(): Promise<ChartDataProps> {
  const { data } = await api.get<ResponseScheduleTaskPerName>(
    '/dashboard/schedule-per-activity',
  );

  return {
    labels: data.task.map(({ label }) => label),
    datasets: [
      {
        data: data.task.map(({ quantity }) => quantity),
        dataInPercent: data.task.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getSchedulePerPriority(): Promise<ChartDataProps> {
  const { data } = await api.get<GenericResponseScheduleTask[]>(
    '/dashboard/schedule-per-priority',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ quantity }) => quantity),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getSchedulePerStatus(): Promise<ChartDataProps> {
  const { data } = await api.get<GenericResponseScheduleTask[]>(
    '/dashboard/schedule-per-status',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ quantity }) => quantity),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getSchedulePerProject(): Promise<ChartDataProps> {
  const { data } = await api.get<GenericResponseScheduleTask[]>(
    '/dashboard/schedule-per-project',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ quantity }) => quantity),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getSchedulePerFleet(): Promise<ChartDataProps> {
  const { data } = await api.get<GenericResponseScheduleTask[]>(
    '/dashboard/schedule-per-fleet',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ quantity }) => quantity),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getSchedulePerResponsible(): Promise<ChartDataProps> {
  const { data } = await api.get<GenericResponseScheduleTask[]>(
    '/dashboard/schedule-per-user',
  );

  return {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ quantity }) => quantity),
        dataInPercent: data.map(({ percent }) => percent?.toFixed(2)),
      },
    ],
  };
}

export async function getScheduleTasksPerStatusAndEngineerService(): Promise<ChartDataProps> {
  const { data } = await api.get<ChartDataProps>(
    '/dashboard/schedules-per-task-status',
  );

  console.log('total-tasks-per-status-engineers', data);

  return {
    labels: data.labels,
    datasets: data.datasets,
  };
}
