import { GenericObject } from '../types/fleet';
import api from './api';

const CONTROL_TYPE = '/controlType/';

export async function getControlTypeService(): Promise<GenericObject[]> {
  const response = await api.get(CONTROL_TYPE, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createControlTypeService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(CONTROL_TYPE, item);

  return response.data;
}

export async function updateControlTypeService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(CONTROL_TYPE, item);

  return response.data;
}

export async function deleteControlTypeService(id: number) {
  await api.delete(`/controlType/${id}`);
}
