import { useQuery } from 'react-query';

import { toDropdownItemProps } from '../../../helpers/toDropdownItemProps';
import { getFleetListService } from '../../../services/fleets';

export function useTireEvaluationFilterFormRequests() {
  const { data: fleetOptions, isLoading: isFleetOptionsLoading } = useQuery(
    'fleetsOptions',
    async () => {
      const data = await getFleetListService({
        page: 0,
        size: 1000,
      });

      return toDropdownItemProps(data?._embedded.fleets);
    },
  );

  return { fleetOptions, isFleetOptionsLoading };
}
