import styled from 'styled-components';

export const Container = styled.div`
  height: 75vh;
  width: 100%;

  display: flex;
  gap: 1em;

  .steps {
    margin: 0;

    .step {
      display: flex !important;
      flex-wrap: nowrap;

      .icon {
        color: #004ea8;
        transition: ${({ theme }) => theme.transitions.fast};
      }

      .title {
        color: #004ea8;
        transition: ${({ theme }) => theme.transitions.fast};
      }
    }

    .active.step {
      background-color: #004ea8;

      .icon {
        color: #feda00;
      }

      .title {
        color: #feda00;
      }

      &::after {
        background-color: #004ea8;
      }
    }

    .disabled.step {
      .icon {
        color: rgba(34, 36, 38, 0.15);
      }
    }
  }

  .form__container {
    display: flex;
    flex-direction: column;
    flex: 1;

    .form__title {
      display: none;
    }

    form {
      display: flex;
      flex-direction: column;
      flex: 1;

      transition: ${({ theme }) => theme.transitions.fast};
      overflow: hidden;

      .form__body {
        height: 100%;
        padding: 10px 10px 10px 0;

        overflow: hidden auto;

        ::-webkit-scrollbar {
          width: 4px;
        }

        ::-webkit-scrollbar-track {
          background: none;
        }
      }

      .form__footer {
        padding-top: 10px;
        border-top: 1px solid rgba(34, 36, 38, 0.15);

        display: flex;
        justify-content: start;

        .fields {
          margin: 0 !important;

          display: flex;
          flex-wrap: nowrap;

          .field {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }
  }

  @media (max-width: 460px) {
    display: inline;

    .steps {
      height: 60px;
      width: 100%;

      .step {
        justify-content: center !important;
        padding: 1em !important;

        .icon {
          margin: 0 !important;
          font-size: 2em;
        }
      }
    }

    .form__container {
      .form__title {
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        padding: 10px 0;
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        margin: 0;
        display: block;
      }
      .form__body {
        height: 60vh !important;
      }
    }
  }
`;
