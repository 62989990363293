import I18n from 'i18n-js';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Confirm, Form, Input, Loader } from 'semantic-ui-react';

import { FleetDetailsModal } from '..';

import Container from '../../components/Container';
import { FilterGroup } from '../../components/FilterGroup';
import { Page } from '../../components/Page';
import { Toolbar } from '../../components/Toolbar';
import { useFleetQueries } from '../../hooks/useFleetQueries';
import { deleteFleetService } from '../../services/fleets';
import { queryClient } from '../../services/queryClient';
import { Table } from './components/Table';
import { FleetFormModal } from './FleetFormModal';

interface FilterParamsProps {
  name?: string;
  region?: string;
  regionAdvisor?: number;
  type?: number;
}

export function Fleets() {
  const [page, setPage] = useState(1);
  const [isFleetFormModalOpen, setIsFleetFormModalOpen] = useState(false);
  const [isFleetDetailsOpen, setIsFleetDetailsOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [selectedFleetId, setSelectedFleetId] = useState<number>();
  const [query, setQuery] = useState<FilterParamsProps>({
    name: undefined,
    region: undefined,
    regionAdvisor: undefined,
    type: undefined,
  });
  const [filterParams, setFilterParams] = useState<FilterParamsProps>({
    name: undefined,
    region: undefined,
    regionAdvisor: undefined,
    type: undefined,
  });

  const { fleets, isLoadingFleets, fleetType, users } = useFleetQueries(
    page,
    query,
  );

  function handleFleetDetailsModalOpen(fleetId: number) {
    setSelectedFleetId(fleetId);
    setIsFleetDetailsOpen(true);
  }

  function handleFleetDetailsModalClose() {
    setSelectedFleetId(undefined);
    setIsFleetDetailsOpen(false);
  }

  function handleFleetFormModalOpen(fleetId?: number) {
    if (fleetId) {
      setSelectedFleetId(fleetId);
    }
    setIsFleetFormModalOpen(true);
  }

  function handleFleetFormModalClose() {
    setSelectedFleetId(undefined);
    setIsFleetFormModalOpen(false);
  }

  function handleFleetConfirmationModalOpen(fleetId: number) {
    setSelectedFleetId(fleetId);

    setIsConfirmationModalOpen(true);
  }

  function handleFleetConfirmationModalClose() {
    setSelectedFleetId(undefined);
    setIsConfirmationModalOpen(false);
  }

  const handleDeleteFleet = useMutation(
    async (id: number) => {
      const response = await deleteFleetService(id);

      handleFleetConfirmationModalClose();

      return response;
    },
    {
      onSuccess: () => {
        toast.success('Project deleted successfully');
        queryClient.invalidateQueries('fleets');
      },
      onError: () => {
        toast.error('Error deleting project');
      },
    },
  );

  function handleFilterParamsFleetState(
    value: React.ChangeEvent<HTMLInputElement>,
  ) {
    setFilterParams({
      ...filterParams,
      [value.currentTarget.name]:
        value.currentTarget.value === ''
          ? undefined
          : value.currentTarget.value,
    });
  }

  function handleResponsibleChange(value: number) {
    setFilterParams({
      ...filterParams,
      regionAdvisor: value,
    });
  }

  function handleFleetTypeChange(value: number) {
    setFilterParams({
      ...filterParams,
      type: value,
    });
  }

  function handleClickApplyFilter() {
    setQuery({
      ...filterParams,
    });
    setPage(1);
  }

  return (
    <Page title="Fleets">
      <Container>
        <div className="page__title">{I18n.t('title.fleets')}</div>

        <Toolbar
          onAddClick={() => handleFleetFormModalOpen()}
          onApplyClick={handleClickApplyFilter}
        >
          <FilterGroup>
            <Form.Dropdown
              search
              label={I18n.t('page.fleets.label.generalInformation.pfc')}
              selection
              clearable
              name="regionAdvisor"
              options={users ?? []}
              value={filterParams.regionAdvisor || ''}
              onChange={(_, data) =>
                handleResponsibleChange(data.value as number)
              }
              fluid
            />

            <Form.Field
              control={Input}
              name="name"
              label={I18n.t('page.fleets.label.generalInformation.fleetsName')}
              value={filterParams.name || ''}
              onChange={handleFilterParamsFleetState}
              fluid
            />

            <Form.Dropdown
              search
              label={I18n.t('page.fleets.label.generalInformation.type')}
              selection
              clearable
              name="type"
              options={fleetType ?? []}
              value={filterParams.type || ''}
              onChange={(_, data) =>
                handleFleetTypeChange(data.value as number)
              }
              fluid
            />

            <Form.Field
              control={Input}
              name="region"
              label={I18n.t('page.fleets.label.generalInformation.state')}
              value={filterParams.region || ''}
              onChange={handleFilterParamsFleetState}
              fluid
            />
          </FilterGroup>
        </Toolbar>

        {isLoadingFleets ? (
          <Loader size="large" active />
        ) : (
          <Table
            fleets={fleets}
            page={page}
            setPage={setPage}
            handleFleetDetailsModalOpen={handleFleetDetailsModalOpen}
            handleFleetFormModalOpen={handleFleetFormModalOpen}
            handleFleetDeleteModalOpen={handleFleetConfirmationModalOpen}
          />
        )}
      </Container>

      {isFleetDetailsOpen && (
        <FleetDetailsModal
          fleetId={selectedFleetId}
          isOpen={isFleetDetailsOpen}
          onRequestClose={handleFleetDetailsModalClose}
        />
      )}

      {isFleetFormModalOpen && (
        <FleetFormModal
          fleetId={selectedFleetId}
          isOpen={isFleetFormModalOpen}
          onRequestClose={handleFleetFormModalClose}
        />
      )}

      {isConfirmationModalOpen && selectedFleetId && (
        <Confirm
          header={I18n.t('confirm.delete.title')}
          content={I18n.t('confirm.delete.message')}
          open={isConfirmationModalOpen}
          cancelButton={{
            content: I18n.t('confirm.delete.cancel'),
          }}
          confirmButton={{
            content: I18n.t('confirm.delete.confirm'),
            negative: true,
            icon: 'trash',
            labelPosition: 'left',
          }}
          onCancel={handleFleetConfirmationModalClose}
          onConfirm={() => {
            handleDeleteFleet.mutateAsync(selectedFleetId);
          }}
          size="mini"
        />
      )}
    </Page>
  );
}
