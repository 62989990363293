import I18n from 'i18n-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Icon,
  PaginationProps,
  Table as TableSemantic,
} from 'semantic-ui-react';

import { Pagination } from '../../../../components/Pagination';
import { usePermission } from '../../../../hooks/usePermission';
import { PROJECTS_PAGE } from '../../../../settings/constants';
import { ProjectsResponseDataProps } from '../../../../types/project';
import { FleetDetailsModal } from '../../../Fleets/FleetDetailsModal';
import { TableBody } from './components/TableBody';

interface TableProps {
  projects?: ProjectsResponseDataProps;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  handleProjectFormModalOpen(projectId: number): void;
  handleProjectDeleteModalOpen(projectId: number): void;
}

const LABEL_DATE = 'label.date';

export function Table({
  projects,
  page,
  setPage,
  handleProjectFormModalOpen,
  handleProjectDeleteModalOpen,
}: TableProps) {
  const navigate = useNavigate();
  const { isEditor } = usePermission();

  const [isFleetDetailsOpen, setIsFleetDetailsOpen] = useState(false);
  const [selectedFleetId, setSelectedFleetId] = useState<number>();

  function handleFleetDetailsModalClose() {
    setSelectedFleetId(undefined);
    setIsFleetDetailsOpen(false);
  }

  function handleFleetDetailsModalOpen(fleetId: number) {
    setSelectedFleetId(fleetId);
    setIsFleetDetailsOpen(true);
  }

  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps,
  ) {
    setPage(Number(activePage));
    navigate(`${PROJECTS_PAGE}/${activePage}`);
  }

  return (
    <span>
      <div className="table__scrollable">
        <TableSemantic className="table" unstackable>
          <TableSemantic.Header className="thead">
            <TableSemantic.Row className="tr">
              <TableSemantic.HeaderCell className="th id">
                Id
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th folder">
                {I18n.t('page.projects.label.folder')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th folder">
                {I18n.t('page.projects.label.status')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th focusRequest">
                {I18n.t('page.projects.label.focusRequest')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th testType">
                {I18n.t('page.projects.label.testType')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th description">
                {I18n.t('page.projects.label.description')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th service">
                {I18n.t('page.projects.label.service')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th tirePosition">
                {I18n.t('page.projects.label.tirePosition')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th fleet">
                {I18n.t('page.projects.label.fleet')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th responsible">
                {I18n.t('page.projects.label.responsible')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th reportLink">
                {I18n.t('page.projects.label.totalTires')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th startDate">
                {`${I18n.t('page.projects.label.startDate')} (${I18n.t(
                  LABEL_DATE,
                )})`}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th startDate">
                {`${I18n.t('page.projects.label.averageDate')} (${I18n.t(
                  LABEL_DATE,
                )})`}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th finishDate">
                {`${I18n.t('page.projects.label.finishDate')} (${I18n.t(
                  LABEL_DATE,
                )})`}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th reportLink">
                {I18n.t('page.projects.label.reportLink')}
              </TableSemantic.HeaderCell>

              {isEditor && (
                <TableSemantic.HeaderCell className="th action">
                  <Icon name="cog" size="large" />
                </TableSemantic.HeaderCell>
              )}
            </TableSemantic.Row>
          </TableSemantic.Header>

          <TableBody
            projects={projects}
            handleFleetDetailsModalOpen={handleFleetDetailsModalOpen}
            handleProjectFormModalOpen={handleProjectFormModalOpen}
            handleProjectDeleteModalOpen={handleProjectDeleteModalOpen}
          />
        </TableSemantic>
      </div>

      <div className="table__pagination">
        <Pagination
          page={page}
          totalPages={projects?.page.totalPages}
          handlePageChange={handlePageChange}
        />
      </div>

      {isFleetDetailsOpen && (
        <FleetDetailsModal
          fleetId={selectedFleetId}
          isOpen={isFleetDetailsOpen}
          onRequestClose={handleFleetDetailsModalClose}
        />
      )}
    </span>
  );
}
