import { format } from 'date-fns';

import {
  ScheduleActivity,
  ScheduleActivityFormData,
} from '../../../../../types/schedule';

export default function toScheduleFormData(
  data: ScheduleActivity,
): ScheduleActivityFormData {
  return {
    id: data.id,
    batch: data?.batch,
    title: data?.title,
    priorityId: data.priority?.id,
    activityStatusId: data.activityStatus?.id,
    typeFleetId: data.typeFleet?.id,
    typeActivityId: data.typeActivity?.id,
    activityId: data.activity?.id,
    projectId: data.project?.id,
    fleetId: data.fleet?.id,
    userId: data.user?.id,
    planningScheduleId: data.frequency?.id,
    repetitions: data?.repetition,
    initialDate: format(new Date(data.initialDate), 'yyyy-MM-dd'),
    description: data?.description,
    type: data.type,
  };
}
