import I18n from 'i18n-js';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Form } from 'semantic-ui-react';

import { EditableDropdown } from '../../../../components/Form/EditableDropdown';
import { toDropdownItemProps } from '../../../../helpers/toDropdownItemProps';
import {
  createEngineTypesService,
  deleteEngineTypesService,
  getEngineTypesService,
  updateEngineTypesService,
} from '../../../../services/engineTypeService';
import {
  createNumberVehiclesService,
  deleteNumberVehiclesService,
  getNumberVehiclesService,
  updateNumberVehiclesService,
} from '../../../../services/numberVehiclesService';
import {
  createTruckAxlesService,
  deleteTruckAxlesService,
  getTruckAxlesService,
  updateTruckAxlesService,
} from '../../../../services/truckAxleService';
import {
  createTruckBrandService,
  deleteTruckBrandService,
  getTruckBrandService,
  updateTruckBrandService,
} from '../../../../services/truckBrandService';
import {
  createTruckModelService,
  deleteTruckModelService,
  getTruckModelService,
} from '../../../../services/truckModelService';
import {
  createTruckService,
  deleteTruckService,
  getTruckService,
  updateTruckService,
} from '../../../../services/truckService';
import {
  FLEET_ENGINETYPES_QUERYKEY,
  FLEET_NUMBERVEHICLES_QUERYKEY,
  FLEET_TRUCKBRANDS_QUERYKEY,
  FLEET_TRUCKMODELS_QUERYKEY,
  FLEET_TRUCKS_QUERYKEY,
  FLEET_TRUCKTRACTIONS_QUERYKEY,
  MAX_CHARS_TEXT_AREA,
} from '../../../../settings/constants';

export default function Vehicles() {
  const { control } = useFormContext();

  const { data: numberVehicles } = useQuery('number_vehicles', async () => {
    return toDropdownItemProps(await getNumberVehiclesService());
  });

  const { data: truckBrand } = useQuery('truck_brands', async () => {
    return toDropdownItemProps(await getTruckBrandService());
  });

  const { data: truckTraction } = useQuery('truck_tractions', async () => {
    return toDropdownItemProps(await getTruckAxlesService());
  });

  const { data: engineType } = useQuery('engine_types', async () => {
    return toDropdownItemProps(await getEngineTypesService());
  });

  const { data: trucks } = useQuery('trucks', async () => {
    return toDropdownItemProps(await getTruckService());
  });

  const { data: truckModel } = useQuery('truck_models', async () => {
    return toDropdownItemProps(await getTruckModelService());
  });

  return (
    <>
      <EditableDropdown
        controllerControl={control}
        controllerName="vehicles.numberVehicles"
        label={I18n.t('page.fleets.label.vehicles.quantity')}
        queryKey={FLEET_NUMBERVEHICLES_QUERYKEY}
        multiple
        options={numberVehicles}
        createItemService={createNumberVehiclesService}
        updateItemService={updateNumberVehiclesService}
        deleteItemService={deleteNumberVehiclesService}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName={FLEET_TRUCKBRANDS_QUERYKEY}
        label={I18n.t('page.fleets.label.vehicles.brand')}
        multiple
        queryKey="truck_brands"
        options={truckBrand}
        createItemService={createTruckBrandService}
        updateItemService={updateTruckBrandService}
        deleteItemService={deleteTruckBrandService}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="vehicles.engineTypes"
        label={I18n.t('page.fleets.label.vehicles.engineTypes')}
        queryKey={FLEET_ENGINETYPES_QUERYKEY}
        options={engineType}
        multiple
        createItemService={createEngineTypesService}
        updateItemService={updateEngineTypesService}
        deleteItemService={deleteEngineTypesService}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="vehicles.axles"
        label={I18n.t('page.fleets.label.vehicles.tractionConfiguration')}
        multiple
        queryKey={FLEET_TRUCKTRACTIONS_QUERYKEY}
        options={truckTraction}
        createItemService={createTruckAxlesService}
        updateItemService={updateTruckAxlesService}
        deleteItemService={deleteTruckAxlesService}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="vehicles.trucks"
        label={I18n.t('page.fleets.label.vehicles.trailerConfiguration')}
        multiple
        queryKey={FLEET_TRUCKS_QUERYKEY}
        options={trucks}
        createItemService={createTruckService}
        updateItemService={updateTruckService}
        deleteItemService={deleteTruckService}
      />

      <Controller
        control={control}
        name="vehicles.totalGrossWeight"
        render={({ field: { onChange, onBlur, value } }) => (
          <Form.TextArea
            type="text"
            maxLength={MAX_CHARS_TEXT_AREA}
            label={I18n.t('page.fleets.label.vehicles.totalGrossWheightKg')}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="vehicles.truckModels"
        label={I18n.t('page.fleets.label.vehicles.vehiclesCategory')}
        multiple
        queryKey={FLEET_TRUCKMODELS_QUERYKEY}
        options={truckModel}
        createItemService={createTruckModelService}
        deleteItemService={deleteTruckModelService}
      />
    </>
  );
}
