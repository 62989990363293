import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { updateLinkReportService } from '../../../../../../../services/linkReportService';
import { queryClient } from '../../../../../../../services/queryClient';
import { LinkReportFormData } from '../../../../../../../types/project';

interface IProps {
  setActiveAccordion: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IRequestParamsProps {
  data: LinkReportFormData;
  isUpdateMoreReports: boolean;
}

export function useEditReportLink({
  setActiveAccordion,
  setIsSubmitLoading,
}: IProps) {
  const editReportLink = useMutation(
    async ({ data, isUpdateMoreReports }: IRequestParamsProps) => {
      await updateLinkReportService(data, isUpdateMoreReports);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('LINK_REPORTS');
        toast.success('Report Link updated successfully');
        setActiveAccordion(false);
        setIsSubmitLoading(false);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error?.message);
        setIsSubmitLoading(false);
      },
    },
  );

  return { editReportLink };
}
