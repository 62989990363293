import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from 'react-router-dom';

import Content from './components/Layout/Content';
import { Header } from './components/Layout/Header';
import { useAuth } from './contexts/auth';
import { AccessDenied } from './pages/AccessDenied';
import {
  Dashboard,
  Fleets,
  Projects,
  Search,
  TireEvaluations,
} from './pages/index';
import Login from './pages/Login';
import { Reports } from './pages/Reports';
import { Schedule } from './pages/Schedule';
import { TireEvaluationFormModal } from './pages/TireEvaluations/components/TireEvaluationFormModal';
import {
  ADMIN_USER_ROLE,
  BASE_URL,
  EDITOR_USER_ROLE,
  FLEETS_PAGE,
  PROJECTS_PAGE,
  READER_USER_ROLE,
  SCHEDULER_PAGE,
  SEARCH_PAGE,
} from './settings/constants';

function Routes() {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const { currentUserRoles } = useAuth();

  const accessAllowed = [
    ADMIN_USER_ROLE,
    EDITOR_USER_ROLE,
    READER_USER_ROLE,
  ].some(userRole => currentUserRoles?.includes(userRole));

  const state = location.state as { backgroundLocation?: Location };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location.pathname, location.search]);

  return (
    <>
      <Switch location={state?.backgroundLocation || location}>
        {isAuthenticated && accessAllowed && (
          <Route
            path={BASE_URL}
            element={
              <Header>
                <Content />
              </Header>
            }
          >
            <Route path={BASE_URL} element={<Dashboard />} />
            <Route path={SCHEDULER_PAGE} element={<Schedule />} />
            <Route path={`${PROJECTS_PAGE}/:projectPage`}>
              <Route index element={<Projects />} />
              <Route
                path=":projectId/tire-evaluation/:tireEvaluationPage"
                element={<TireEvaluations />}
              />
              <Route
                path=":projectId/tire-evaluation/:tireEvaluationPage/:tireEvaluationId/reports"
                element={<Reports />}
              />
            </Route>
            <Route path={FLEETS_PAGE} element={<Fleets />} />
            <Route path={SEARCH_PAGE} element={<Search />} />
          </Route>
        )}

        {isAuthenticated && !accessAllowed && (
          <Route path={BASE_URL} element={<AccessDenied />} />
        )}

        {!isAuthenticated && !accessAllowed && (
          <Route path={BASE_URL} element={<Login />} />
        )}

        <Route path="*" element={<Navigate to={BASE_URL} replace />} />
      </Switch>

      {state?.backgroundLocation && (
        <Switch>
          <Route
            path={`${PROJECTS_PAGE}/:projectPage/:projectId/tire-evaluation/:tireEvaluationPage/new`}
            element={<TireEvaluationFormModal />}
          />
          <Route
            path={`${PROJECTS_PAGE}/:projectPage/:projectId/tire-evaluation/:tireEvaluationPage/edit/:tireEvaluationId`}
            element={<TireEvaluationFormModal />}
          />
        </Switch>
      )}
    </>
  );
}

export default Routes;
