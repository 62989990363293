import { DropdownItemProps } from 'semantic-ui-react';

import { City, State } from '../types/localities';

export function convertCityToDropdownOption(cities: City[]) {
  return cities.map((city: City): any => ({
    key: city.id,
    text: city.nome,
    value: city.nome,
  }));
}

export function convertCityAndStateToDropdownOption(cities: City[]) {
  return cities.map(
    (city: City): DropdownItemProps => ({
      key: city.id,
      text: `${city.nome}, ${city.microrregiao.mesorregiao.UF.sigla}`,
      value: `${city.nome}, ${city.microrregiao.mesorregiao.UF.sigla}`,
    }),
  );
}

export function convertStateToDropdownOption(states: State[]) {
  return states.map(
    (state: State): DropdownItemProps => ({
      key: state.id,
      text: state.sigla,
      value: state.sigla,
    }),
  );
}
