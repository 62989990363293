import styled from 'styled-components';

export const ScheduleContainer = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 3px -3px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  gap: 20px;

  .fc {
    .fc-bg-event {
      opacity: 0.5 !important;

      span {
        .fc-event-title {
          color: #ffffff !important;
          font-weight: bold;
          .fc-event-text {
          }
        }
      }
    }

    .fc-media-screen {
      width: 100%;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      flex: 1;

      .fc-header-toolbar {
        margin-bottom: 10px;

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;

        .fc-toolbar-chunk {
          .fc-toolbar-title {
            font-size: 1.2rem;
          }
          .fc-toolbar-title::first-letter {
            text-transform: uppercase;
          }
        }
      }

      .fc-view-harness {
        min-height: 55vh;
        width: 100%;

        overflow: auto !important;

        .fc-daygrid {
        }

        .fc-dayGridMonth-view,
        .fc-dayGridWeek-view {
          min-width: 960px;
        }
      }
    }
  }

  table {
    border-radius: 5px;
    box-shadow: 0;

    thead {
      background-color: ${({ theme }) => `${theme.colors.gray} !important`};

      tr {
        th {
          .fc-scrollgrid-sync-inner {
            a {
              color: ${({ theme }) => `${theme.colors.white} !important`};
            }
          }
        }
      }
    }

    tbody {
      background-color: #fff;
    }
  }

  .fc-button {
    background-color: ${({ theme }) => `${theme.colors.primary} !important`};
    text-transform: capitalize;
    border-radius: 5px;
    border: 0;

    :hover {
      background-color: ${({ theme }) =>
        `${theme.colors.primaryHover} !important`};
    }
  }

  .fc-button-active {
    background-color: ${({ theme }) =>
      `${theme.colors.primaryHover} !important`};
  }

  .fc-button-group {
    .fc-next-button {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .fc-prev-button {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .fc-today-button {
  }

  .fc-daygrid-day-top {
    font-weight: bold;

    a {
      color: ${({ theme }) => `${theme.colors.gray} !important`};
    }
  }

  .fc-daygrid-day {
    .fc-daygrid-day-frame {
      .fc-daygrid-day-top {
      }

      .fc-daygrid-day-events {
        .ui.mini.label {
          outline: 3px solid #ffffff90;
        }
      }
    }
  }

  .fc-daygrid-event {
    font-size: 1em;
    padding: 6px;
    white-space: normal;
    margin-bottom: 8px;
    border-radius: 5px;
    box-shadow: 0 5px 3px -3px rgba(0, 0, 0, 0.4);

    .fc-event-main {
      background-color: #00000020;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .fc-event-title {
        font-size: 1rem;
        font-weight: bold;
        padding: 4px 8px;

        .fc-event-labels {
          .fc-event-priority,
          .fc-event-status > span {
            display: none;
          }
        }
      }

      .fc-event-author {
        font-size: 0.8rem;
        padding: 4px 8px;
        border-radius: 5px;
      }
    }
  }

  .fc-list-event {
    .fc-list-event-time {
      display: none !important;
    }

    .fc-list-event-graphic {
      display: none !important;
    }

    .fc-list-event-graphic {
      .fc-list-event-dot::before {
      }
    }

    .fc-list-event-title {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .fc-event-title {
        display: flex;
        gap: 8px;

        .fc-event-labels {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .fc-event-priority {
            margin: 0 !important;

            position: static;

            z-index: auto;

            span {
              display: inline-block;
            }
          }

          .fc-event-status {
            margin: 0 !important;

            position: static;

            z-index: auto;

            span {
              display: inline-block;
            }
          }
        }
      }

      .fc-event-author::before {
        /* content: 'AUTHOR: '; */
      }
    }
  }

  .fc-scrollgrid-sync-inner {
    .fc-event-main {
      .fc-event-main-frame {
        .fc-event-title-container {
          .fc-event-title {
          }
        }
      }
    }
  }

  @media (max-width: 460px) {
    width: 100%;

    .fc-media-screen {
      .fc-header-toolbar {
        justify-content: space-evenly;

        .fc-toolbar-chunk {
        }
      }

      .fc-view-harness {
      }
    }

    .fc-list-event {
      .fc-list-event-title {
        .fc-event-priority.ui.mini.label,
        .fc-event-status.ui.mini.label {
          font-size: 0.64285714rem !important;
        }
        .fc-event-author {
          display: flex;
          justify-content: end;
        }
      }
    }
  }
`;
