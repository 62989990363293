import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 63px;
  padding: 20px 60px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 460px) {
    margin: 54px 0 0;
    padding: 0;
  }
`;
