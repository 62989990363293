import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css';

import App from './App';
import { AuthProvider } from './contexts/auth';
import { msalConfig } from './settings/authConfig';
// create PublicClientApplication instance
export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <MsalProvider instance={msalInstance}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </MsalProvider>,
);
