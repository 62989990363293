import I18n from 'i18n-js';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../../../services/queryClient';
import { deleteScheduleHolidayService } from '../../../../../services/schedule';

export function useHandleDeleteHoliday() {
  const handleDeleteHoliday = useMutation(
    async (id: number) => {
      await deleteScheduleHolidayService(id);
    },
    {
      onSuccess: () => {
        toast.success(I18n.t('generics.toast.success.delete'));
        queryClient.invalidateQueries('HOLIDAY_LIST_MANAGEMENT');
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error(I18n.t('generics.toast.error.delete'));
        }
      },
    },
  );

  return {
    handleDeleteHoliday,
  };
}
