import {
  HolidayCreateItemProps,
  HolidayItemProps,
  HolidayListProps,
  InspectionAndReportTasksProps,
  ScheduleActivity,
  ScheduleActivityFormData,
  ScheduleProps,
} from '../types/schedule';
import api from './api';

const SCHEDULE_PATH = '/schedule';

export async function getScheduleActivityByIdService(
  id?: number,
): Promise<ScheduleActivity> {
  const response = await api.get(`${SCHEDULE_PATH}/${id}`);

  return response.data;
}

interface ScheduleActivityByUserIdProps {
  typeFleetId?: number;
  typeActivityId?: number;
  activityId?: number;
  priorityId?: number;
  activityStatusId?: number;
  projectId?: number;
  fleetId?: number;
  emails?: string[];
}

export async function getScheduleActivityByUserIdService({
  typeFleetId,
  typeActivityId,
  activityId,
  priorityId,
  activityStatusId,
  projectId,
  fleetId,
  emails,
}: ScheduleActivityByUserIdProps): Promise<ScheduleActivity[]> {
  const response = await api.get(`${SCHEDULE_PATH}/find-per-user/`, {
    params: {
      typeFleetId,
      typeActivityId,
      activityId,
      priorityId,
      activityStatusId,
      projectId,
      fleetId,
      emails: emails?.join(','),
    },
  });

  return response.data;
}

export async function getScheduleActivityService(): Promise<
  ScheduleActivity[]
> {
  const response = await api.get(`${SCHEDULE_PATH}/all`);

  return response.data;
}

export async function getScheduleService(): Promise<ScheduleProps> {
  const response = await api.get(`${SCHEDULE_PATH}/`, {
    params: { sort: 'name,asc' },
  });

  return response.data;
}

export async function createScheduleTaskService(
  data: ScheduleActivityFormData,
) {
  const response = await api.post(`${SCHEDULE_PATH}/`, {
    ...data,
    initialDate: data.initialDate && new Date(data.initialDate),
    planningScheduleId:
      data.planningScheduleId === 5 ? 3 : data.planningScheduleId,
    repetitions: data.planningScheduleId === 5 ? 1 : data.repetitions,
  });

  return response.data;
}

export async function updateScheduleTaskService(
  data: ScheduleActivityFormData,
  isUpdateMoreReports: boolean,
) {
  const response = await api.put(
    `${SCHEDULE_PATH}/`,
    {
      ...data,
      initialDate: data.initialDate && new Date(data.initialDate),
      finalDate: data.finalDate && new Date(data.finalDate),
      batch: data.batch,
      type: data.type,
    },
    {
      params: {
        isUpdateMoreReports,
      },
    },
  );

  return response.data;
}

export async function deleteScheduleService(id?: number) {
  await api.delete(`${SCHEDULE_PATH}/${id}`);
}

export async function deleScheduleTaskSeriesServices(id?: number) {
  await api.delete(`${SCHEDULE_PATH}/all/${id}`);
}

export async function createInspectionStage(id?: number) {
  const response = await api.post(
    `${SCHEDULE_PATH}/trigger/tire-evaluation/${id}`,
  );

  return response.data;
}

export async function createInspectionAndReportTasks(
  data: InspectionAndReportTasksProps,
) {
  const response = await api.post(`${SCHEDULE_PATH}/trigger/project/`, {
    ...data,
    frequency: data.frequency === 5 ? 3 : data.frequency,
    repetitions: data.frequency === 5 ? 1 : data.repetitions,
  });

  return response.data;
}

export async function getScheduleTaskPrioritiesService() {
  const response = await api.get(`${SCHEDULE_PATH}/priorities`);

  return response.data;
}

export async function getScheduleTaskStatusService() {
  const response = await api.get(`${SCHEDULE_PATH}/activity-status`);

  return response.data;
}

export async function getScheduleHolidayListService(): Promise<HolidayListProps> {
  const response = await api.get('/holiday');

  return response.data;
}

export async function createScheduleHolidayService(
  data: HolidayCreateItemProps,
): Promise<void> {
  const response = await api.post('/holiday', {
    ...data,
    holidayDate: new Date(data.holidayDate),
  });

  return response.data;
}

export async function updateScheduleHolidayService(
  data: HolidayItemProps,
): Promise<void> {
  const response = await api.put('/holiday', {
    ...data,
    holidayDate: new Date(data.holidayDate),
  });

  return response.data;
}

export async function deleteScheduleHolidayService(id?: number) {
  await api.delete(`/holiday/${id}`);
}
