import { useQuery } from 'react-query';

import { FilterParams } from '..';

import { getTireEvaluationListService } from '../../../services/tireEvaluation';

export function useTireEvaluationRequest(
  projectId: number,
  page: number,
  query: FilterParams,
) {
  const { data: tireEvaluations, isLoading: isTireEvaluationsLoading } =
    useQuery(
      ['tireEvaluations', { projectId, page, ...query }],
      () => {
        return getTireEvaluationListService({
          page: page - 1,
          project: Number(projectId),
          ...query,
        });
      },
      {
        enabled: !!projectId,
        retry: false,
      },
    );

  return {
    tireEvaluations,
    isTireEvaluationsLoading,
  };
}
