import { ENV } from '../types/env';

// BASES
export const BASE_URL = `${ENV.REACT_APP_BASE_URL}`;
export const BASE_API_URL = `${ENV.REACT_APP_BASE_API_URL}`;
export const BASE_AUTHORIZE_BASE_API_URL = `${ENV.REACT_APP_AUTHORIZE_BASE_API_URL}`;
export const REACT_APP_CLIENT_ID = `${ENV.REACT_APP_CLIENT_ID}`;
export const REACT_APP_REDIRECT_URI = `${ENV.REACT_APP_REDIRECT_URI}`;

// GOOGLE ANALYTICS
export const REACT_APP_TAG_GOOGLE = `${ENV.REACT_APP_TAG_GOOGLE}`;

// PAGES
export const LOGIN_PAGE = `${ENV.REACT_APP_BASE_URL}login`;
export const SCHEDULER_PAGE = `${ENV.REACT_APP_BASE_URL}scheduler`;
export const PROJECTS_PAGE = `${ENV.REACT_APP_BASE_URL}projects`;
export const FLEETS_PAGE = `${ENV.REACT_APP_BASE_URL}fleets`;
export const REPORTS_PAGE = `${ENV.REACT_APP_BASE_URL}reports`;
export const TIRE_EVALUATION_PAGE = `${ENV.REACT_APP_BASE_URL}tire-evaluation`;
export const SEARCH_PAGE = `${ENV.REACT_APP_BASE_URL}search`;
export const ACCESS_DENIED = `${ENV.REACT_APP_BASE_URL}${LOGIN_PAGE}/access-denied`;

// STORAGE
export const LOCAL_STORAGE_TOKEN_KEY = `@Goodyear:token`;

// QUERYKEYS
export const SERVICE_QUERYKEY = 'service';
export const TIRE_POSITION_QUERYKEY = 'tire_position';
export const PROJECT_STATUS_QUERYKEY = 'project_status';
export const PROJECT_STATUS_COLORS_QUERYKEY = 'project_status_colors';
export const PROJECT_TESTTYPE_QUERYKEY = 'project_testType';
export const FLEET_TYPE_QUERYKEY = 'fleet_type';
export const USERS_QUERYKEY = 'users';
export const FLEET_ACCOUNT_QUERYKEY = 'account_type';
export const FLEET_CONTROL_QUERYKEY = 'control_type';
export const FLEET_CRISSCROSS_QUERYKEY = 'criss_cross_type';
export const FLEET_NUMBERVEHICLES_QUERYKEY = 'number_vehicles';
export const FLEET_TRUCKBRANDS_QUERYKEY = 'vehicles.truckBrands';
export const FLEET_ENGINETYPES_QUERYKEY = 'engine_types';
export const FLEET_TRUCKTRACTIONS_QUERYKEY = 'truck_tractions';
export const FLEET_TRUCKS_QUERYKEY = 'trucks';
export const FLEET_TRUCKMODELS_QUERYKEY = 'truck_models';
export const FLEET_TIREBRAND_QUERYKEY = 'tire_brand';
export const FLEET_TIREAVGKMMONTH_QUERYKEY = 'tire_average_KM_month';
export const TIRESIZE_QUERYKEY = 'tire_size';
export const USEDBAND_QUERYKEY = 'used_band';
export const FLEET_BUFFINGRADIUS_QUERYKEY = 'buffing_radius';
export const TIRES_QUERYKEY = 'tires';
export const REPORTS_QUERYKEY = 'reports';
export const REPORT_PERCENTAGE_QUERYKEY = 'reports';
export const FLEET_OPTIONS_QUERYKEY = 'fleet_options';

export const SCHEDULE_TASKS = 'schedule_tasks';

// USER ROLES
export const ADMIN_USER_ROLE = 'GG_FIELDENG_PROJ_ADMINS_BRA';
export const EDITOR_USER_ROLE = 'GG_FIELDENG_PROJ_EDITORS_BRA';
export const READER_USER_ROLE = 'GG_FIELDENG_PROJ_READERS_BRA';

// FORMS
export const MAX_CHARS_TEXT_AREA = 5000;

// FORMAT
export const DATE_FORMAT = 'yyyy-MM-dd';
export const FIELD_ERROR_MESSAGE = 'message.error.thisFieldIsRequired';

// FLEET INITIAL VALUES
export const initialValuesFleet = {
  id: null,
  generalInformation: {
    name: '',
    type: null,
    city: null,
    region: null,
    fleetContact: [],
    regionAdvisor: null,
    account: null,
    shipTo: '',
    soldTo: '',
    subsidiaries: '',
    shareBusiness: [],
    competitonTest: null,
  },
  routesAndControl: {
    services: [],
    segment: '',
    fixedFleetRoutes: null,
    averageDistance: '',
    routeSeverity: '',
    tireControl: null,
    controlTypes: [],
    systemName: '',
    haveMaintenance: null,
    pressureControl: null,
    pressureUsed: '',
    tireRotation: null,
    kmRodizio: null,
    crissCrossType: [],
    crissCrossObservation: '',
  },
  vehicles: {
    numberVehicles: [],
    truckBrands: [],
    engineTypes: [],
    axles: [],
    trucks: [],
    totalGrossWeight: '',
    truckModels: [],
  },
  rubberShopAndTires: {
    tireBrands: [],
    tireAveragesKm: [],
    mediaKmMm: null,
    cpk: null,
    competitorPerfomance: '',
    tireSize: [],
    tireExitReason: null,
  },
  tireRecap: {
    grooveRecappingBoMm: null,
    fleetRecapping: [],
    usedBands: [],
    bandModel: '',
    sweepRadius: [],
    sweepRadiusDescription: '',
    pickers: null,
  },
  additionalInformation: {
    additionalRemarks: '',
  },
};

// BACKGROUND COLORS FOR DASHBOARD CHARTS
export const backgroundAnalogousColors = [
  '#fdcc00',
  '#fcf374',
  '#59de5b',
  '#75ff78',
  '#3f93e8',
  '#5eafff',
];

export const backgroundTriadColors = ['#75ff78', '#fcf374', '#5eafff'];
