import { format } from 'date-fns';
import I18n from 'i18n-js';

export function getDateFormat(date?: string | Date) {
  if (date) {
    return format(new Date(date), I18n.t('label.date'));
  }
  return '';
}

export function getDateFormatView(data: string) {
  return format(new Date(data), 'yyyy/MM/dd');
}
