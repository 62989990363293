import { GenericObject } from '../types/fleet';
import api from './api';

const TIRE_BRAND = '/tireBrand';

export async function getTireBrandService(): Promise<GenericObject[]> {
  const response = await api.get(`${TIRE_BRAND}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createTireBrandService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${TIRE_BRAND}/`, item);

  return response.data;
}

export async function updateTireBrandService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${TIRE_BRAND}/`, item);

  return response.data;
}

export async function deleteTireBrandService(id: number) {
  await api.delete(`${TIRE_BRAND}/${id}`);
}
