import { Loader, Label } from 'semantic-ui-react';

import useIsMobileScreen from '../../../../hooks/useIsMobileScreen';
import { Project } from '../../../../types/project';
import { getSemanticColor } from '../../../../utils/getSemanticColor';
import { Container } from './styles';

interface IProps {
  isProjectLoading?: boolean;
  project?: Project;
}

export function TireEvaluationLabelStatus({
  isProjectLoading,
  project,
}: IProps) {
  const { isMobileScreen } = useIsMobileScreen(460);

  return (
    <Container>
      {isProjectLoading ? (
        <Loader active inline size="tiny" />
      ) : (
        <Label
          circular
          color={getSemanticColor(project?.projectStatus?.label)}
          size={isMobileScreen ? 'mini' : 'tiny'}
        />
      )}
    </Container>
  );
}
