import { User } from '../types/user';
import api from './api';

const USER = '/user';

export async function getUserListService(): Promise<User[]> {
  const response = await api.get(`${USER}/`, {
    params: { sort: 'name,asc' },
  });

  return response.data;
}

export async function createUserService(data: User): Promise<User> {
  const response = await api.post(`${USER}/`, data);

  return response.data;
}

export async function updateUserService(data: User): Promise<User> {
  const response = await api.put(`${USER}/`, data);

  return response.data;
}

export async function deleteUserService(id: number) {
  await api.delete(`${USER}/${id}`);
}
