import { SemanticCOLORS } from 'semantic-ui-react';

interface StatusColorsProps {
  label: string;
  color?: SemanticCOLORS;
}

export function getSemanticColor(
  statusLabel?: string,
): SemanticCOLORS | undefined {
  const statusColorList: StatusColorsProps[] = [
    { label: 'NOT STARTED', color: 'orange' },
    { label: 'PENDING', color: 'orange' },
    { label: 'UNDER DISCUSSION', color: 'orange' },
    { label: 'ON GOING', color: 'blue' },
    { label: 'ON HOLD', color: undefined },
    { label: 'TOTAL CYCLE', color: 'yellow' },
    { label: 'FINISHED', color: 'green' },
    { label: 'CANCELED', color: 'red' },
    { label: 'EXPIRED', color: 'purple' },
    { label: 'COMPLETED', color: 'green' },
    { label: 'NOT COMPLETED', color: 'grey' },

    { label: 'CRITICAL', color: 'red' },
    { label: 'HIGH', color: 'orange' },
    { label: 'MEDIUM', color: 'blue' },
    { label: 'LOW', color: 'green' },

    { label: '#db2828', color: 'red' },
    { label: '#f2711c', color: 'orange' },
    { label: '#2185d0', color: 'blue' },
    { label: '#21ba45', color: 'green' },
  ];

  const statusColorSelected = statusColorList.find(
    ({ label }) => label === statusLabel,
  );

  return statusColorSelected?.color ?? undefined;
}
