import { UseMutationResult } from 'react-query';
import { Table as TableSUI, Input, Icon } from 'semantic-ui-react';

import { ItemsProps } from '../..';
import { GenericObject } from '../../../../../../../types/fleet';
import { handleChangeLabelItem } from '../../utils/handleChangeLabelItem';
import { handleClickCheckItem } from '../../utils/handleClickCheckItem';
import { handleClickEditItem } from '../../utils/handleClickEditItem';

interface Props {
  items?: ItemsProps[];
  setItems: React.Dispatch<ItemsProps[]>;
  handleUpdateItem: UseMutationResult<
    GenericObject,
    unknown,
    GenericObject,
    unknown
  >;
  handleDeleteItem: UseMutationResult<void, unknown, number, unknown>;
}

export function Table({
  items,
  setItems,
  handleUpdateItem,
  handleDeleteItem,
}: Props) {
  return (
    <TableSUI compact striped unstackable size="small">
      <TableSUI.Header>
        <TableSUI.Row>
          <TableSUI.HeaderCell>Name</TableSUI.HeaderCell>
          <TableSUI.HeaderCell colSpan="4">Mask</TableSUI.HeaderCell>
        </TableSUI.Row>
      </TableSUI.Header>
      <TableSUI.Body>
        {items?.map(({ id, label, mask, isEdit }) => (
          <TableSUI.Row key={`${id}`}>
            <TableSUI.Cell width="8">
              {isEdit ? (
                <Input
                  name="label"
                  value={label}
                  onChange={(e, { value }) =>
                    handleChangeLabelItem({ items, setItems, e, id, value })
                  }
                  fluid
                />
              ) : (
                label
              )}
            </TableSUI.Cell>
            <TableSUI.Cell>
              {isEdit ? (
                <Input
                  name="mask"
                  value={mask}
                  onChange={(e, { value }) =>
                    handleChangeLabelItem({ items, setItems, e, id, value })
                  }
                  fluid
                />
              ) : (
                mask
              )}
            </TableSUI.Cell>
            <TableSUI.Cell width="1">
              {isEdit ? (
                <Icon
                  link
                  name="check"
                  color="green"
                  onClick={() =>
                    handleClickCheckItem({
                      items,
                      setItems,
                      id,
                      handleUpdateItem,
                    })
                  }
                />
              ) : undefined}
            </TableSUI.Cell>
            <TableSUI.Cell width="1">
              <Icon
                link
                name="pencil"
                color={isEdit ? undefined : 'yellow'}
                onClick={() => handleClickEditItem({ items, setItems, id })}
                disabled={isEdit}
              />
            </TableSUI.Cell>
            <TableSUI.Cell width="1">
              <Icon
                link
                name="trash"
                color={isEdit ? undefined : 'red'}
                onClick={() => handleDeleteItem.mutateAsync(id as number)}
                disabled={isEdit}
              />
            </TableSUI.Cell>
          </TableSUI.Row>
        ))}
      </TableSUI.Body>
    </TableSUI>
  );
}
