import {
  Fleet,
  FleetDataCategorized,
  FleetRecapping,
  GenericObject,
} from '../types/fleet';

export function convertFleetDataToCategorizedData(
  fleet: Fleet,
): FleetDataCategorized {
  return {
    id: fleet.id,
    generalInformation: {
      name: fleet.name,
      type: fleet.type,
      city: fleet.city,
      region: fleet.region,
      fleetContact: fleet.fleetContact,
      regionAdvisor: fleet.regionAdvisor,
      account: fleet.account,
      shipTo: fleet.shipTo,
      soldTo: fleet.soldTo,
      subsidiaries: fleet.subsidiaries,
      shareBusiness: fleet.shareBusiness,
      competitonTest: fleet.competitonTest,
    },
    routesAndControl: {
      route: fleet.route,
      services: fleet.services,
      segment: fleet.segment,
      fixedFleetRoutes: fleet.fixedFleetRoutes,
      averageDistance: fleet.averageDistance,
      routeSeverity: fleet.routeSeverity,
      tireControl: fleet.tireControl,
      controlTypes: fleet.controlTypes,
      systemName: fleet.systemName,
      haveMaintenance: fleet.haveMaintenance,
      pressureControl: fleet.pressureControl,
      pressureUsed: fleet.pressureUsed,
      crissCrossType: fleet.crissCrossType,
      crissCrossObservation: fleet.crissCrossObservation,
    },
    vehicles: {
      numberVehicles: fleet.numberVehicles,
      truckBrands: fleet.truckBrands,
      engineTypes: fleet.engineTypes,
      truckModels: fleet.truckModels,
      axles: fleet.axles,
      trucks: fleet.trucks,
      totalGrossWeight: fleet.totalGrossWeight,
    },
    rubberShopAndTires: {
      tireBrands: fleet.tireBrands,
      tireAveragesKm: fleet.tireAveragesKm,
      mediaKmMm: fleet.mediaKmMm,
      cpk: fleet.cpk,
      competitorPerfomance: fleet.competitorPerfomance,
      tireSize: fleet.tireSize,
      tireExitReason: fleet.tireExitReason,
    },
    tireRecap: {
      grooveRecappingBoMm: fleet.grooveRecappingBoMm,
      fleetRecapping: fleet.fleetRecapping,
      usedBands: fleet.usedBands,
      bandModel: fleet.bandModel,
      sweepRadius: fleet.sweepRadius,
      sweepRadiusDescription: fleet.sweepRadiusDescription,
    },
    additionalInformation: {
      additionalInformation: fleet.additionalInformation,
      createdBy: fleet.createdBy,
      createdDate: fleet.createdDate,
      modifiedBy: fleet.modifiedBy,
      modifiedDate: fleet.modifiedDate,
    },
  };
}

export function convertFleetDataToCategorizedDataForFormData(
  fleet: Fleet,
  tireRecapping?: GenericObject[],
) {
  const fleetArray: FleetRecapping[] = [];

  tireRecapping?.forEach(tireRecappingItem => {
    const fleetTemp: FleetRecapping = {};

    fleet.fleetRecapping?.forEach(f => {
      if (f.recapping?.id === tireRecappingItem.id) {
        fleetTemp.recapping = f.recapping;
        fleetTemp.fleetId = fleet.id;
        fleetTemp.grooveMM = f.grooveMM;
        fleetTemp.brandTotalKM = f.brandTotalKM;
        fleetTemp.recapping = f.recapping;

        fleetArray.push(fleetTemp);
      }
    });

    if (Object.values(fleetTemp).length === 0) {
      fleetTemp.recapping = undefined;
      fleetTemp.fleetId = undefined;
      fleetTemp.grooveMM = undefined;
      fleetTemp.brandTotalKM = undefined;
      fleetTemp.recapping = undefined;
      fleetArray.push(fleetTemp);
    }
  });

  return {
    id: fleet.id,
    generalInformation: {
      name: fleet.name,
      type: fleet.type?.id,
      city: fleet.city,
      region: fleet.region,
      fleetContact: fleet.fleetContact,
      regionAdvisor: fleet.regionAdvisor?.id,
      account: fleet.account?.id,
      shipTo: fleet.shipTo,
      soldTo: fleet.soldTo,
      subsidiaries: fleet.subsidiaries,
      shareBusiness: fleet.shareBusiness,
      competitonTest: fleet.competitonTest,
    },
    routesAndControl: {
      route: fleet.route,
      services: fleet.services?.map(({ id }) => id),
      segment: fleet.segment,
      fixedFleetRoutes: fleet.fixedFleetRoutes,
      averageDistance: fleet.averageDistance,
      routeSeverity: fleet.routeSeverity,
      tireControl: fleet.tireControl,
      controlTypes: fleet.controlTypes?.map(({ id }) => id),
      systemName: fleet.systemName,
      haveMaintenance: fleet.haveMaintenance,
      pressureControl: fleet.pressureControl,
      pressureUsed: fleet.pressureUsed,
      tireRotation: fleet.tireRotation,
      crissCrossType: fleet.crissCrossType?.map(({ id }) => id),
      crissCrossObservation: fleet.crissCrossObservation,
    },
    vehicles: {
      numberVehicles: fleet.numberVehicles?.map(({ id }) => id),
      truckBrands: fleet.truckBrands?.map(({ id }) => id),
      engineTypes: fleet.engineTypes?.map(({ id }) => id),
      axles: fleet.axles?.map(({ id }) => id),
      trucks: fleet.trucks?.map(({ id }) => id),
      totalGrossWeight: fleet.totalGrossWeight,
      truckModels: fleet.truckModels?.map(({ id }) => id),
    },
    rubberShopAndTires: {
      tireBrands: fleet.tireBrands?.map(({ id }) => id),
      tireAveragesKm: fleet.tireAveragesKm?.map(({ id }) => id),
      mediaKmMm: fleet.mediaKmMm,
      cpk: fleet.cpk,
      competitorPerfomance: fleet.competitorPerfomance,
      tireSize: fleet.tireSize?.map(({ id }) => id),
      tireExitReason: fleet.tireExitReason,
    },
    tireRecap: {
      grooveRecappingBoMm: fleet.grooveRecappingBoMm,
      fleetRecapping: fleetArray,
      usedBands: fleet.usedBands?.map(({ id }) => id),
      bandModel: fleet.bandModel,
      sweepRadius: fleet.sweepRadius?.map(({ id }) => id),
      sweepRadiusDescription: fleet.sweepRadiusDescription,
    },
    additionalInformation: {
      additionalInformation: fleet.additionalInformation,
    },
  };
}
