import {
  ReportFormData,
  Report,
  ReportsResponseDataProps,
} from '../types/report';
import api from './api';

export async function getReportListService({
  page = 0,
  size = 10,
  tireEvaluationId,
  percentage,
  started,
  finished,
}: {
  page?: number;
  size?: number;
  tireEvaluationId?: number;
  percentage?: number;
  started?: string | Date;
  finished?: string | Date;
}): Promise<ReportsResponseDataProps> {
  if (started) {
    started = new Date(started as Date);
  }
  if (finished) {
    finished = new Date(finished as Date);
  }

  const response = await api.get<ReportsResponseDataProps>(
    `/tireEvaluations/${tireEvaluationId}/reports`,
    {
      params: {
        percentage,
        started,
        finished,
        page,
        size,
        sort: '',
      },
    },
  );

  return response.data;
}

export async function getReportByIdService(id: number): Promise<Report> {
  const response = await api.get(`/reports/${id}`);

  return response.data;
}

export async function createReportService(
  data: ReportFormData,
): Promise<Report> {
  data.started = new Date(data?.started as Date);
  data.finished = new Date(data?.finished as Date);
  data.reportDate = new Date(data?.reportDate as Date);
  data.dateIssued = new Date(data?.dateIssued as Date);
  const response = await api.post('/reports/', data);

  return response.data;
}

export async function updateReportService(
  data: ReportFormData,
): Promise<Report> {
  data.started = new Date(data?.started as Date);
  data.finished = new Date(data?.finished as Date);
  data.reportDate = new Date(data?.reportDate as Date);
  data.dateIssued = new Date(data?.dateIssued as Date);

  const response = await api.put(`/reports/`, data);

  return response.data;
}

export async function deleteReportService(id: number): Promise<void> {
  await api.delete(`/reports/${id}`);
}
