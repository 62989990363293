import { DropdownProps, Form } from 'semantic-ui-react';

import { FilterGroup } from '../../../../components/FilterGroup';
import { ApiSearchProps } from '../../../../types/search';
import { IFilterOptionsProps } from '../../Hooks/useFiltersOptions';

interface IFilterFormDataProps {
  filtersOptions: IFilterOptionsProps;
  querySelected: ApiSearchProps;
  handleQuerySelected(
    _: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ): void;
}

export function FilterFormData({
  filtersOptions,
  querySelected,
  handleQuerySelected,
}: IFilterFormDataProps) {
  const dropdownProps = {
    clearable: true,
    search: true,
    selection: true,
    multiple: true,
    fluid: true,
  };

  const {
    folders,
    fleetIds,
    typeFleetIds,
    servicesIds,
    responsibleIds,
    brands,
    models,
    sizeIds,
    modifications,
  } = querySelected;

  return (
    <>
      <FilterGroup title="Project">
        <Form.Dropdown
          {...dropdownProps}
          name="folders"
          label="Folder"
          value={folders}
          onChange={handleQuerySelected}
          options={filtersOptions?.folderOptions ?? []}
        />
      </FilterGroup>

      <FilterGroup title="Fleet">
        <Form.Dropdown
          {...dropdownProps}
          name="fleetIds"
          label="Name"
          value={fleetIds}
          onChange={handleQuerySelected}
          options={filtersOptions?.fleetNameOptions ?? []}
        />
        <Form.Dropdown
          {...dropdownProps}
          name="typeFleetIds"
          label="Type"
          value={typeFleetIds}
          onChange={handleQuerySelected}
          options={filtersOptions?.fleetTypeOptions ?? []}
        />
        <Form.Dropdown
          {...dropdownProps}
          name="servicesIds"
          label="Service"
          value={servicesIds}
          onChange={handleQuerySelected}
          options={filtersOptions?.fleetServiceOptions ?? []}
        />
      </FilterGroup>

      <FilterGroup title="Tire Evaluation">
        <Form.Dropdown
          {...dropdownProps}
          name="responsibleIds"
          label="Responsible"
          value={responsibleIds}
          onChange={handleQuerySelected}
          options={filtersOptions?.usersOptions ?? []}
        />
      </FilterGroup>

      <FilterGroup title="Tire">
        <Form.Dropdown
          {...dropdownProps}
          name="brands"
          label="Brand"
          value={brands}
          onChange={handleQuerySelected}
          options={filtersOptions?.tireBrandOptions ?? []}
        />
        <Form.Dropdown
          {...dropdownProps}
          name="models"
          label="Model"
          value={models}
          onChange={handleQuerySelected}
          options={filtersOptions?.tireModelOptions ?? []}
        />
        <Form.Dropdown
          {...dropdownProps}
          name="sizeIds"
          label="Size"
          value={sizeIds}
          onChange={handleQuerySelected}
          options={filtersOptions?.tireSizeOptions ?? []}
        />
        <Form.Dropdown
          {...dropdownProps}
          name="modifications"
          label="Modification"
          value={modifications}
          onChange={handleQuerySelected}
          options={filtersOptions?.tireModificationOptions ?? []}
        />
      </FilterGroup>
    </>
  );
}
