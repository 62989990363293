import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  .goodyear-logo-img {
    width: 600px;
    margin: 20px 20px 0;
  }

  .access-denied-img {
    width: 180px;
    margin: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Description = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text_light};
`;
