import React from 'react';

import { ItemsProps } from '..';

interface Props {
  items: ItemsProps[];
  setItems: React.Dispatch<ItemsProps[]>;
  id?: number;
  e: React.ChangeEvent<HTMLInputElement>;
  value: string;
}
export function handleChangeLabelItem({
  items,
  setItems,
  id,
  e,
  value,
}: Props) {
  const index = items?.findIndex(item => item?.id === id);

  const updatingItems = [...(items ?? [])];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  if (e.currentTarget.name === 'label') {
    updatingItems[index][e.currentTarget.name] = value.toUpperCase();
  }
  if (e.currentTarget.name === 'mask') {
    updatingItems[index][e.currentTarget.name] = value;
  }

  setItems([...updatingItems]);
}
