interface IArrayProps {
  id?: number;
  label?: string;
}

export function getValuesFromArrayOfObjects(array?: IArrayProps[]) {
  if (array) {
    return array?.map(object => object.label).join(', ');
  }
  return 'NÃO CONSTA ******';
}
