import React from 'react';
import { UseMutationResult } from 'react-query';

import { ItemsProps } from '..';

import { User } from '../../../../../../types/user';
import { handleClickEditItem } from './handleClickEditItem';

interface Props {
  items: ItemsProps[];
  setItems: React.Dispatch<ItemsProps[]>;

  id?: number;

  handleUpdateItem: UseMutationResult<User, unknown, any, unknown>;
}
export function handleClickCheckItem({
  items,
  setItems,

  id,

  handleUpdateItem,
}: Props) {
  const index = items?.findIndex(item => item?.id === id);
  const data = {
    id: items[index].id,
    name: items[index].name,
    phone: items[index].phone,
    email: items[index].email,
  };

  handleClickEditItem({ setItems, items, id });

  handleUpdateItem.mutateAsync(data);
}
