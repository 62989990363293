import { TireEvaluation } from '../../../../../types/tireEvaluation';

interface ListFleetTreatment {
  id: number;
  name?: string;
  quantity: number;
}

function getRGB(c: string): any {
  return parseInt(c, 16) || c;
}

function getsRGB(c: any) {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : ((getRGB(c) / 255 + 0.055) / 1.055) ** 2.4;
}

function getLuminance(hexColor: string) {
  return (
    0.2126 * getsRGB(hexColor?.slice(1, 2)) +
    0.7152 * getsRGB(hexColor?.slice(3, 2)) +
    0.0722 * getsRGB(hexColor?.slice(-2))
  );
}

function getContrast(f: string, b: string) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);

  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

export function getTextColor(bgColor: string) {
  const whiteContrast = getContrast(bgColor, '#ffffff');
  const blackContrast = getContrast(bgColor, '#000000');

  return blackContrast > whiteContrast ? '#ffffff' : '#000000';
}

export function fleetTreatment(
  tireEvaluation?: TireEvaluation[],
): ListFleetTreatment[] {
  const listFleet: ListFleetTreatment[] = [];

  tireEvaluation?.forEach(t => {
    const index = listFleet.findIndex(f => f.id === t.fleet.id);

    if (index < 0) {
      listFleet.push({
        id: t.fleet.id,
        name: t.fleet.name,
        quantity: 1,
      });
    } else {
      listFleet[index].quantity += 1;
    }
  });

  return listFleet;
}
