interface StatusColorsProps {
  label: string;
  color?: string;
}

export function getHexadecimalColor(statusLabel?: string): string | undefined {
  const statusColorList: StatusColorsProps[] = [
    { label: 'CRITICAL', color: '#db2828' },
    { label: 'HIGH', color: '#f2711c' },
    { label: 'LOW', color: '#2185d0' },
    { label: 'MEDIUM', color: '#21ba45' },
  ];

  const statusColorSelected = statusColorList.find(
    ({ label }) => label === statusLabel,
  );

  return statusColorSelected?.color ?? undefined;
}
