import { useAuth } from '../contexts/auth';
import { ADMIN_USER_ROLE, EDITOR_USER_ROLE } from '../settings/constants';

export function usePermission() {
  const { currentUserRoles } = useAuth();

  const isAdmin = currentUserRoles?.includes(ADMIN_USER_ROLE);

  const isEditor = [ADMIN_USER_ROLE, EDITOR_USER_ROLE].some(userRole =>
    currentUserRoles?.includes(userRole),
  );

  return {
    isAdmin,
    isEditor,
  };
}
