import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

interface ButtonProps {
  outline: boolean;
}

export const Container = styled(Button)<ButtonProps>`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  gap: 0.5rem;

  border: ${({ theme, outline }) =>
    outline && `2px solid ${theme.colors.primary}`} !important;
  background-color: ${({ theme, outline }) =>
    outline ? theme.colors.white : theme.colors.primary} !important;
  color: ${({ theme, outline }) =>
    outline ? theme.colors.primary : theme.colors.white} !important;

  i {
    opacity: 1 !important;
    color: ${({ theme, outline }) =>
      outline ? theme.colors.primary : theme.colors.white} !important;
  }
`;

export const Text = styled.p``;

export const Image = styled.img`
  align-self: center;
  height: 20px;

  filter: brightness(0) invert(1);
`;
