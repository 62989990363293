// eslint-disable-next-line import-helpers/order-imports
import FullCalendar, { EventApi } from '@fullcalendar/react';
// eslint-disable-next-line import-helpers/order-imports
import dayGridPlugin from '@fullcalendar/daygrid';
// eslint-disable-next-line import-helpers/order-imports
import interactionPlugin from '@fullcalendar/interaction';
// eslint-disable-next-line import-helpers/order-imports
import listPlugin from '@fullcalendar/list';
// eslint-disable-next-line import-helpers/order-imports
import brLocale from '@fullcalendar/core/locales/pt-br';

import I18n from 'i18n-js';
import { useState } from 'react';
import { Loader } from 'semantic-ui-react';

import { Container } from '../../components';
import { ConfirmModal } from '../../components/ConfirmModal';
import { Page } from '../../components/Page';
import { Toolbar } from '../../components/Toolbar';
import { usePermission } from '../../hooks/usePermission';
import {
  getScheduleActivityByIdService,
  updateScheduleTaskService,
} from '../../services/schedule';
import { ScheduleActivityFormData } from '../../types/schedule';
import { EventContent } from './components/EventContent';
import FilterContent from './components/FilterContent';
import { HolidayModal } from './components/HolidayModal';
import ScheduleFormModal from './components/ScheduleTaskFormModal';
import { UploadTasksConfirmModal } from './components/UploadTasksConfirmModal';
import { useScheduleTaskRequests } from './hooks/useScheduleTaskRequests';
import { ScheduleContainer } from './styles';
import { validHolidayDate } from './utils/getIsHolidayDate';

export interface ISelectedScheduleTaskProps {
  taskId?: number;
  taskSeriesId?: number;
}

export interface FilterParamsProps {
  selectedBusinessId?: number;
  selectedTaskTypeId?: number;
  selectedTaskNameId?: number;
  selectedPriorityId?: number;
  selectedStatusId?: number;
  selectedProjectId?: number;
  selectedFleetId?: number;
  selectedUser?: string[];
}

export type FilterParamsNamesProps =
  | 'selectedBusinessId'
  | 'selectedTaskTypeId'
  | 'selectedTaskNameId'
  | 'selectedPriorityId'
  | 'selectedStatusId'
  | 'selectedProjectId'
  | 'selectedFleetId'
  | 'selectedUser';

export function Schedule() {
  const { isEditor } = usePermission();
  const [taskData, setTaskData] = useState<ScheduleActivityFormData>();

  const [isScheduleFormModalOpen, setScheduleFormModalOpen] = useState(false);
  const [isHolidayConfirmationModalOpen, setIsHolidayConfirmationModalOpen] =
    useState(false);
  const [isHolidayModalOpen, setHolidayModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [isUpdateMoreReports, setIsUpdateMoreReports] = useState(false);

  const [selectedScheduleTask, setSelectedScheduleTask] =
    useState<ISelectedScheduleTaskProps>({
      taskId: undefined,
      taskSeriesId: undefined,
    });
  const [selectedScheduleTaskDate, setSelectedScheduleTaskDate] =
    useState<Date>();

  const [filterParams, setFilterParams] = useState<FilterParamsProps>({
    selectedBusinessId: undefined,
    selectedTaskTypeId: undefined,
    selectedTaskNameId: undefined,
    selectedPriorityId: undefined,
    selectedStatusId: undefined,
    selectedProjectId: undefined,
    selectedFleetId: undefined,
    selectedUser: undefined,
  });

  const [newStartDate, setNewStartDate] = useState<string>();
  const [isTriggerRefetchSchedule, setIsTriggerRefetchSchedule] =
    useState(false);

  const { allEvents, isAllEventsLoading } = useScheduleTaskRequests({
    filterParams,
    newStartDate,
    isTriggerRefetchSchedule,
  });

  function handleScheduleFormModalClose() {
    setSelectedScheduleTask({});
    setScheduleFormModalOpen(false);
  }

  function handleScheduleTaskFormModalOpen(event?: EventApi, date?: Date) {
    const isHoliday = validHolidayDate(allEvents, date);

    if (isHoliday) {
      setIsHolidayConfirmationModalOpen(true);
    } else {
      setScheduleFormModalOpen(true);
    }

    if (event?.extendedProps.taskId) {
      setSelectedScheduleTask(prev => ({
        ...prev,
        taskId: event?.extendedProps.taskId,
      }));
    }

    if (event?.extendedProps.taskSeriesId) {
      setSelectedScheduleTask(prev => ({
        ...prev,
        taskSeriesId: event?.extendedProps.taskSeriesId,
      }));
    }

    if (date) {
      setSelectedScheduleTaskDate(date);
    }

    if (!date) {
      setSelectedScheduleTaskDate(undefined);
    }
  }

  function handleHolidayModalOpen() {
    setHolidayModalOpen(true);
  }

  function handleHolidayModalClose() {
    setHolidayModalOpen(false);
  }

  function handleConfirmationModalOpen() {
    setIsConfirmationModalOpen(true);
  }

  function handleConfirmationModalClose() {
    setIsConfirmationModalOpen(false);
    setIsTriggerRefetchSchedule(prev => !prev);
  }

  function handleConfirmHolidayWarning() {
    setIsHolidayConfirmationModalOpen(false);
    setScheduleFormModalOpen(true);
  }

  async function handleScheduleTaskDrop({
    extendedProps: { taskId },
    startStr,
    endStr,
  }: EventApi) {
    return getScheduleActivityByIdService(taskId)
      .then(async res => {
        const isDifferentDates = String(res.initialDate) !== startStr;
        const isReportTask = res.type === 1;
        const taskValues = {
          id: res.id,
          batch: res.batch,
          title: res.title,
          typeFleetId: res.typeFleet.id,
          typeActivityId: res.typeActivity.id,
          activityId: res.activity.id,
          fleetId: res.fleet?.id,
          projectId: res.project?.id,
          userId: res.user.id,
          planningScheduleId: res.frequency.id,
          priorityId: res.priority?.id,
          activityStatusId: res.activityStatus?.id,
          repetitions: res.repetition,
          initialDate: startStr,
          finalDate: endStr,
          description: res.description,
          type: res.type,
        };

        setTaskData(taskValues);

        if (isDifferentDates && isReportTask) {
          return handleConfirmationModalOpen();
        }

        return updateScheduleTaskService(taskValues, false)
          .then(() => {
            setNewStartDate(startStr);
          })
          .catch(err => console.log('updateScheduleTaskService', err));
      })
      .catch(err => console.log('getScheduleActivityByIdService', err));
  }

  return (
    <Page title="Schedule">
      <Container>
        <div className="page__title">{I18n.t('title.scheduler')}</div>

        <Toolbar
          onAddClick={() => handleScheduleTaskFormModalOpen()}
          onApplyClick={() => null}
          onHolidayClick={handleHolidayModalOpen}
          enableHolidayButton
        >
          <FilterContent
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        </Toolbar>

        <ScheduleContainer>
          {isAllEventsLoading && <Loader active size="large" />}
          <FullCalendar
            locale={I18n.currentLocale() === 'pt_BR' ? brLocale : undefined}
            plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,dayGridWeek,dayGridDay,listMonth',
            }}
            eventResize={({ event }) => handleScheduleTaskDrop(event)}
            editable
            selectable
            selectMirror
            weekends
            eventContent={({ event }) => (
              <EventContent
                event={event}
                selectedUser={filterParams?.selectedUser}
              />
            )}
            eventDrop={({ event }) => handleScheduleTaskDrop(event)}
            dateClick={({ date }) =>
              isEditor
                ? handleScheduleTaskFormModalOpen(undefined, date)
                : undefined
            }
            eventClick={({ event }) => handleScheduleTaskFormModalOpen(event)}
            events={allEvents}
          />
        </ScheduleContainer>

        <div />
      </Container>

      {isHolidayConfirmationModalOpen && (
        <ConfirmModal
          open={isHolidayConfirmationModalOpen}
          header={I18n.t('page.schedule.modal.holidayConfirmation.header')}
          onCancel={() => setIsHolidayConfirmationModalOpen(false)}
          onConfirm={handleConfirmHolidayWarning}
          cancelBtnContent={I18n.t('generics.modal.button.cancel')}
          confirmBtnContent={I18n.t('generics.modal.button.confirm')}
          content={I18n.t('page.schedule.modal.holidayConfirmation.content')}
        />
      )}

      {isScheduleFormModalOpen && (
        <ScheduleFormModal
          isFormModalOpen={isScheduleFormModalOpen}
          selectedScheduleTask={selectedScheduleTask}
          setSelectedScheduleTask={setSelectedScheduleTask}
          selectedScheduleTaskDate={selectedScheduleTaskDate}
          onScheduleFormModalClose={handleScheduleFormModalClose}
        />
      )}

      {isHolidayModalOpen && (
        <HolidayModal
          isModalOpen={isHolidayModalOpen}
          onRequestClose={handleHolidayModalClose}
        />
      )}

      {isConfirmationModalOpen && (
        <UploadTasksConfirmModal
          data={taskData}
          setNewStartDate={setNewStartDate}
          handleConfirmationModalClose={handleConfirmationModalClose}
          isConfirmationModalOpen={isConfirmationModalOpen}
          isUpdateMoreReports={isUpdateMoreReports}
          setIsUpdateMoreReports={setIsUpdateMoreReports}
        />
      )}
    </Page>
  );
}
