import { useQuery } from 'react-query';

import { toDropdownItemProps } from '../../../helpers/toDropdownItemProps';
import { getProjectStatusService } from '../../../services/project';
import { getServiceTypeService } from '../../../services/serviceTypeService';
import { getTestTypesService } from '../../../services/testTypeService';
import { getTirePositionService } from '../../../services/tirePositionService';
import { getUserListService } from '../../../services/user';
import {
  PROJECT_TESTTYPE_QUERYKEY,
  SERVICE_QUERYKEY,
  TIRE_POSITION_QUERYKEY,
  USERS_QUERYKEY,
} from '../../../settings/constants';

interface IProps {
  isReportTasksGenerated: boolean;
}

export function useRequests({ isReportTasksGenerated }: IProps) {
  const { data: testTypes } = useQuery(
    PROJECT_TESTTYPE_QUERYKEY,
    getTestTypesService,
  );
  const { data: projectStatusOptions } = useQuery(
    ['PROJECT_STATUS_OPTIONS', isReportTasksGenerated],
    async () =>
      toDropdownItemProps(
        await getProjectStatusService(),
        true,
        isReportTasksGenerated,
      ),
  );

  const { data: serviceTypeOptions } = useQuery(SERVICE_QUERYKEY, async () =>
    toDropdownItemProps(await getServiceTypeService()),
  );

  const { data: tirePositionOptions } = useQuery(
    TIRE_POSITION_QUERYKEY,
    async () => toDropdownItemProps(await getTirePositionService()),
  );

  const { data: users } = useQuery(USERS_QUERYKEY, getUserListService);

  return {
    testTypes,
    projectStatusOptions,
    serviceTypeOptions,
    tirePositionOptions,
    users,
  };
}
