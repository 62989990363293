export const mainTableHeaderCells = [
  {
    key: 1,
    name: 'Folder',
    value: 'folder',
  },
  {
    key: 2,
    name: 'Description',
    value: 'description',
  },
  {
    key: 3,
    name: 'Fleet',
    value: 'fleet',
  },
  {
    key: 4,
    name: 'Fleet Type',
    value: 'fleetType',
  },
  {
    key: 5,
    name: 'Service',
    value: 'service',
  },
  {
    key: 6,
    name: 'Brand',
    value: 'brand',
  },
  {
    key: 7,
    name: 'Model',
    value: 'model',
  },
  {
    key: 8,
    name: 'Size',
    value: 'size',
  },
  {
    key: 9,
    name: 'Modification',
    value: 'modification',
  },
  {
    key: 10,
    name: 'Tires Qty',
    value: 'tiresQty',
  },
  {
    key: 11,
    name: 'Responsible',
    value: 'responsible',
  },
];

export const reportTableHeaderCells = [
  {
    key: 1,
    name: 'Report',
  },
  {
    key: 2,
    name: 'AVG NSK (mm)',
  },
  {
    key: 3,
    name: 'Worn Out (%)',
  },
  {
    key: 4,
    name: 'KM Run',
  },
  {
    key: 5,
    name: 'KM/mm',
  },
  {
    key: 6,
    name: 'KM/mm Rate',
  },
  {
    key: 7,
    name: 'KM Projected',
  },
  {
    key: 8,
    name: 'KM Projected Rate (%)',
  },
  {
    key: 9,
    name: 'Report Inssue (Data Inssue O.L)',
  },
  {
    key: 10,
    name: 'Cycle',
  },
  {
    key: 11,
    name: 'Retread Index',
  },
  {
    key: 12,
    name: 'Date Issued (Total Cycle)',
  },
];
