import styled from 'styled-components';

interface Props {
  errors: any;
}

export const Container = styled.div<Props>`
  .field {
    margin-bottom: 2em !important;

    label {
      /* color: rgba(0, 0, 0, 0.60) !important; */
      font-weight: 600 !important;
    }
  }
`;
