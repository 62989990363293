import { useQuery } from 'react-query';

import { IsOpenOverviewProps } from '..';

import {
  getTiresPerTestTypeAndReportService,
  getTiresPerFleet,
  getFleetsPerSegmentService,
  getTiresPerSegmentService,
} from '../../../services/dashboard';
import { FleetsOverviewDataProps } from '../../../types/dashboard';

export default function useFleetsChartData(
  isOpenOverview: IsOpenOverviewProps,
): FleetsOverviewDataProps {
  const {
    data: tiresPerTestTypeAndReport,
    isLoading: IsLoadingTiresPerTestTypeAndReport,
  } = useQuery(
    'chart_tires_per_testType_and_report',
    getTiresPerTestTypeAndReportService,
    {
      enabled: isOpenOverview.fleets,
    },
  );

  const { data: tiresPerFleet, isLoading: isLoadingTiresPerFleet } = useQuery(
    'chart_tires_per_fleet',
    getTiresPerFleet,
    {
      enabled: isOpenOverview.fleets,
    },
  );
  const { data: fleetsPerSegment, isLoading: isLoadingFleetsPerSegment } =
    useQuery('chart_fleets_per_segment', getFleetsPerSegmentService, {
      enabled: isOpenOverview.fleets,
    });

  const { data: tiresPerSegment, isLoading: isLoadingTiresPerSegment } =
    useQuery('chart_tires_per_segment', getTiresPerSegmentService, {
      enabled: isOpenOverview.fleets,
    });
  return {
    fleetsPerSegment,
    isLoadingFleetsPerSegment,

    tiresPerSegment,
    isLoadingTiresPerSegment,

    tiresPerFleet,
    isLoadingTiresPerFleet,

    tiresPerTestTypeAndReport,
    IsLoadingTiresPerTestTypeAndReport,
  };
}
