/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from 'react';
import {
  Controller,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  Segment,
  Form,
  Label,
  Icon,
  Input,
  TextArea,
  Accordion,
  Button,
} from 'semantic-ui-react';

import { MAX_CHARS_TEXT_AREA } from '../../../../../../settings/constants';

interface IProps {
  index: number;
  remove: UseFieldArrayRemove;
}

export function FleetContact({ index, remove }: IProps) {
  const { control } = useFormContext();

  const [activeAccordion, setActiveAccordion] = useState(true);

  const contactName = useWatch({
    control,
    name: `generalInformation.fleetContact.${index}.name`,
  });

  return (
    <Accordion as={Segment} style={{ marginTop: 0 }}>
      <Form.Group grouped>
        <Accordion.Title active={activeAccordion}>
          <Label
            as="a"
            style={{ display: 'flex', justifyContent: 'space-between' }}
            attached="top"
            onClick={() => setActiveAccordion(prev => !prev)}
          >
            <Icon name="dropdown" />
            {contactName
              ? `${contactName.toUpperCase()}`
              : `CONTACT ${index + 1}`}
            <Icon name="delete" color="red" onClick={() => remove(index)} />
          </Label>
        </Accordion.Title>

        <Accordion.Content active={activeAccordion}>
          <Controller
            control={control}
            name={`generalInformation.fleetContact.${index}.name`}
            render={({ field: { value, onChange } }) => (
              <Form.Field
                maxLength={200}
                control={Input}
                label="Name"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name={`generalInformation.fleetContact.${index}.phone`}
            render={({ field: { value, onChange } }) => (
              <Form.Field>
                <label>Phone</label>
                <InputMask
                  mask="(99) 9 9999-9999"
                  alwaysShowMask={false}
                  maskChar={null}
                  value={value}
                  onChange={onChange}
                >
                  {/* @ts-ignore */}
                  {inputProps => <Form.Input {...inputProps} />}
                </InputMask>
              </Form.Field>
            )}
          />
          <Controller
            control={control}
            name={`generalInformation.fleetContact.${index}.email`}
            render={({ field: { value, onChange } }) => (
              <Form.Field
                maxLength={200}
                control={Input}
                type="email"
                label="Email"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name={`generalInformation.fleetContact.${index}.profile`}
            render={({ field: { value, onChange } }) => (
              <Form.Field
                maxLength={200}
                control={Input}
                label="Job title"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name={`generalInformation.fleetContact.${index}.observation`}
            render={({ field: { value, onChange } }) => (
              <Form.Field>
                <Form.Field
                  control={TextArea}
                  maxLength={MAX_CHARS_TEXT_AREA}
                  label="Observation"
                  value={value}
                  onChange={onChange}
                />
              </Form.Field>
            )}
          />

          <Button
            type="button"
            content="Done"
            color="green"
            onClick={() => setActiveAccordion(false)}
            fluid
          />
        </Accordion.Content>
      </Form.Group>
    </Accordion>
  );
}
