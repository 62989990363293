import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  border-bottom: 1px solid #f2f2f2;

  .filterGroup__title {
    opacity: 0.8;
    font-size: 1.2em;
    font-weight: bold;
    padding-bottom: 8px;
  }

  .filterGroup__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    label {
      font-weight: bold;
      opacity: 0.6;
    }
  }
`;
