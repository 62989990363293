import React from 'react';
import { UseMutationResult } from 'react-query';

import { ItemsProps } from '..';

import { TireFormData } from '../../../../../../types/tire';
import { handleClickEditItem } from './handleClickEditItem';

interface Props {
  items: ItemsProps[];
  setItems: React.Dispatch<ItemsProps[]>;

  id?: number;

  handleUpdateItem: UseMutationResult<TireFormData, unknown, any, unknown>;
}
export function handleClickCheckItem({
  items,
  setItems,

  id,

  handleUpdateItem,
}: Props) {
  const index = items?.findIndex(item => item?.id === id);
  const data = {
    id: items[index].id,
    brand: items[index].brand,
    model: items[index].model,
    modification: items[index].modification,
    tireSizeId: items[index].tireSize.id,
  };

  handleClickEditItem({ setItems, items, id });

  handleUpdateItem.mutateAsync(data);
}
