import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Checkbox, Loader } from 'semantic-ui-react';

import { ChartDataProps } from '../../../../../types/dashboard';
import { Container } from './styles';

interface IDataProps {
  title: string;
  data?: ChartDataProps;
  isLoading?: boolean;
  indexAxis?: 'x' | 'y';
  anchor?: string;
  position?: string;
  backgroundColor?: (string | undefined)[];
  disablePercent?: boolean;
}

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  ChartDataLabels,
);

export function DoughnutChart({
  title,
  data,
  isLoading,
  indexAxis,
  anchor,
  position,
  backgroundColor,
  disablePercent = false,
}: IDataProps) {
  const [isDataInPercent, setIsDataInPercent] = useState(false);

  function handleTogglePercent() {
    setIsDataInPercent(prevState => !prevState);
  }

  return (
    <Container>
      <div className="minichartbox__header">
        <div className="minichartbox__title">{title}</div>
        {!disablePercent && (
          <Checkbox
            checked={isDataInPercent}
            onChange={handleTogglePercent}
            label="%"
          />
        )}
      </div>
      <div className="minichartbox__content">
        {isLoading ? (
          <Loader active inline />
        ) : (
          <Pie
            data={{
              labels: data?.labels,
              datasets: [
                {
                  data: isDataInPercent
                    ? data?.datasets[0].dataInPercent
                    : data?.datasets[0].data,
                  backgroundColor,
                  borderWidth: 0,
                  datalabels: {
                    anchor: 'center',
                  },
                },
              ],
            }}
            options={{
              indexAxis: indexAxis ?? ('y' as const),
              responsive: true,
              plugins: {
                datalabels: {
                  formatter: (value: any) => {
                    if (isDataInPercent && value) {
                      return `${value}%`;
                    }
                    return value === 0 ? null : value;
                  },
                  backgroundColor: '#FFFFFF60',
                  anchor: anchor ?? ('end' as any),
                  color: 'black',
                  borderRadius: 25,
                  borderWidth: 2,
                  labels: {
                    title: {
                      font: {
                        weight: 'bold',
                      },
                    },
                    value: {
                      color: '#333333',
                    },
                  },
                },
                legend: {
                  display: true,
                  position: position ?? ('bottom' as any),
                },
              },
            }}
          />
        )}
      </div>
    </Container>
  );
}
