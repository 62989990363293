import { GenericObject } from '../types/fleet';
import api from './api';

const TIRE_SIZES = '/tireSizes';

export async function getTireSizeService(): Promise<GenericObject[]> {
  const response = await api.get(`${TIRE_SIZES}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createTireSizeService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${TIRE_SIZES}/`, item);

  return response.data;
}

export async function updateTireSizeService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${TIRE_SIZES}/`, item);

  return response.data;
}

export async function deleteTireSizeService(id: number) {
  await api.delete(`${TIRE_SIZES}/${id}`);
}
