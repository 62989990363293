import { Control, Controller } from 'react-hook-form';
import {
  DropdownItemProps,
  Form,
  StrictFormDropdownProps,
} from 'semantic-ui-react';

type Props = Omit<StrictFormDropdownProps, 'control' | 'error'> & {
  controllerControl: Control<any>;
  controllerName: string;
  options?: DropdownItemProps[];
  required?: boolean;
  error?: any;
};

export function ControllerFormDropdown({
  controllerControl,
  controllerName,
  options,
  required,
  ...rest
}: Props) {
  return (
    <Controller
      control={controllerControl}
      name={controllerName}
      rules={{
        required,
      }}
      render={({ field: { value, onChange } }) => (
        <Form.Dropdown
          required={required}
          clearable
          search
          selection
          value={value}
          options={options ?? []}
          onChange={(_, data) => onChange(data.value)}
          {...rest}
        />
      )}
    />
  );
}
