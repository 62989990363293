import I18n from 'i18n-js';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../../../services/queryClient';
import { updateScheduleHolidayService } from '../../../../../services/schedule';
import { HolidayItemProps } from '../../../../../types/schedule';

export function useHandleUpdateHoliday() {
  const handleUpdateHoliday = useMutation(
    (data: HolidayItemProps) => {
      return updateScheduleHolidayService(data);
    },
    {
      onSuccess: () => {
        toast.success(I18n.t('generics.toast.success.edit'));
        queryClient.invalidateQueries('HOLIDAY_LIST_MANAGEMENT');
      },
      onError: () => {
        toast.error(I18n.t('generics.toast.error.edit'));
      },
    },
  );

  return {
    handleUpdateHoliday,
  };
}
