import { GenericObject } from '../types/fleet';
import api from './api';

const TRUCK_BRAND = '/truckBrand';

export async function getTruckBrandService(): Promise<GenericObject[]> {
  const response = await api.get(`${TRUCK_BRAND}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createTruckBrandService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${TRUCK_BRAND}/`, item);

  return response.data;
}

export async function updateTruckBrandService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${TRUCK_BRAND}/`, item);

  return response.data;
}

export async function deleteTruckBrandService(id: number) {
  await api.delete(`${TRUCK_BRAND}/${id}`);
}
