/* eslint-disable @typescript-eslint/ban-ts-comment */
import I18n from 'i18n-js';
import {
  Header,
  PaginationProps,
  Table as SemanticTable,
} from 'semantic-ui-react';

import { IShowColumnsProps } from '../..';
import { Pagination } from '../../../../components/Pagination';
import { SearchResponseDataProps } from '../../../../types/search';
import {
  mainTableHeaderCells,
  reportTableHeaderCells,
} from '../../utils/columns';
import TableBody from './components/TableBody';

interface ITableProps {
  showColumns: IShowColumnsProps;
  searchData?: SearchResponseDataProps;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export function PrimaryTable({
  showColumns,
  searchData,
  page,
  setPage,
}: ITableProps) {
  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps,
  ) {
    setPage(activePage as number);
  }

  const headerCellQty =
    mainTableHeaderCells.length + reportTableHeaderCells.length;

  return (
    <span className="primary-table">
      <div className="table__scrollable primary-table">
        <SemanticTable className="table primary-table" unstackable>
          <SemanticTable.Header className="thead primary-table">
            <SemanticTable.Row className="tr primary-table">
              {mainTableHeaderCells.map(mainHeadercell => {
                // @ts-ignore
                if (showColumns[mainHeadercell.value]) {
                  return (
                    <SemanticTable.HeaderCell
                      key={mainHeadercell.key}
                      className="th primary-table"
                      content={mainHeadercell.name}
                    />
                  );
                }
                return undefined;
              })}

              {reportTableHeaderCells.map(reportHeadercell => (
                <SemanticTable.HeaderCell
                  key={reportHeadercell.key}
                  className="th primary-table"
                  content={reportHeadercell.name}
                />
              ))}
            </SemanticTable.Row>
          </SemanticTable.Header>

          {searchData ? (
            searchData?._embedded.tireEvaluations?.map(tireEvaluation => (
              <TableBody
                key={tireEvaluation.id}
                tireEvaluation={tireEvaluation}
                showColumns={showColumns}
              />
            ))
          ) : (
            <SemanticTable.Body>
              <SemanticTable.Row>
                <SemanticTable.Cell colSpan={headerCellQty}>
                  <Header>{I18n.t('error.noDataFound')}</Header>
                </SemanticTable.Cell>
              </SemanticTable.Row>
            </SemanticTable.Body>
          )}
        </SemanticTable>
      </div>

      <div className="table__pagination">
        <Pagination
          page={page}
          totalPages={searchData?.page.totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </span>
  );
}
