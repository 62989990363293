import I18n from 'i18n-js';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { Label, Progress, Segment } from 'semantic-ui-react';
import { useTheme } from 'styled-components';

import { getTireSizeService } from '../../../../services/tireSizeService';
import { TIRESIZE_QUERYKEY } from '../../../../settings/constants';
import { useTireDetailsRequests } from '../../hooks/useTireDetailsRequests';
import { Container, ContainerProgressBar } from './styles';

interface IProps {
  show: boolean;
  projectId?: string;
  totalTires?: number;
}

export default function TireDetailsBar({
  show,
  projectId,
  totalTires,
}: IProps) {
  const theme = useTheme();

  const { data: tireSizes } = useQuery(TIRESIZE_QUERYKEY, async () =>
    getTireSizeService(),
  );

  const getTireSizeLabel = useCallback(
    (tireSizeId: number) => {
      const test = tireSizes?.find(tireSize => tireSize.id === tireSizeId);

      return test?.label;
    },
    [tireSizes],
  );

  const { tires, totalTiresPerFleet, totalTiresFleetTotal } =
    useTireDetailsRequests(projectId);

  return (
    <Container show={show} theme={theme} className="tireDetails__cards">
      <div className="tireDetails__card">
        <div className="tireDetails__wrapper">
          <div className="tireDetails__title">
            {I18n.t('page.tireEvaluations.chart.totalTiresPerModel')}
          </div>
          <Label className="tireDetails__totalTires" circular>
            {totalTires}
          </Label>
        </div>

        <div className="tireDetails__overflow">
          <Segment basic>
            {tires?.map(tire => (
              <ContainerProgressBar key={tire.id} theme={theme}>
                <div className="progress__wrapper">
                  <div className="progress__title">
                    {`${tire.brand} ${tire.model} ${getTireSizeLabel(
                      tire.tireSizeId,
                    )} `}
                    {`(${tire.modification})`}
                  </div>
                  <Label circular size="mini">
                    {tire.totalTires}
                  </Label>
                </div>
                <Progress
                  value={tire.totalTires}
                  total={totalTires}
                  size="tiny"
                />
              </ContainerProgressBar>
            ))}
          </Segment>
        </div>
      </div>

      <div className="tireDetails__card fleet">
        <div className="tireDetails__wrapper fleet">
          <div className="tireDetails__title fleet">
            {' '}
            {I18n.t('page.tireEvaluations.chart.totalTiresPerFleet')}
          </div>
          <Label className="tireDetails__totalTires fleet" circular>
            {totalTiresFleetTotal || 0}
          </Label>
        </div>

        <div className="tireDetails__overflow fleet">
          <Segment basic>
            {totalTiresPerFleet?.map(fleet => (
              <ContainerProgressBar
                key={`${fleet.totalTires} - ${fleet.fleet}`}
                theme={theme}
              >
                <div className="progress__wrapper fleet">
                  <div className="progress__title fleet">{fleet.fleet}</div>
                  <Label circular size="mini">
                    {fleet?.totalTires}
                  </Label>
                </div>
                <Progress
                  value={fleet?.totalTires}
                  total={totalTiresFleetTotal || 0}
                  size="tiny"
                />
              </ContainerProgressBar>
            ))}
          </Segment>
        </div>
      </div>
    </Container>
  );
}
