import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

import { DashboardOverview } from '../..';
import { Container } from './styles';

interface IChartsBoxProps {
  overviewName: DashboardOverview;
  toggleOpen(value: DashboardOverview): void;
  children: React.ReactNode;
  title?: string;
}

export function ChartsBox({
  overviewName,
  toggleOpen,
  children,
  title,
}: IChartsBoxProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  function handleCollapsed() {
    setIsCollapsed(prevState => !prevState);
    toggleOpen(overviewName);
  }
  return (
    <Container isCollapsed={isCollapsed}>
      <div className="chartsbox__title">
        <Button
          circular
          basic
          icon={isCollapsed ? 'angle up' : 'angle down'}
          onClick={handleCollapsed}
        />
        {title}
      </div>
      {isCollapsed && <div className="chartsbox__content">{children}</div>}
    </Container>
  );
}
