import { format } from 'date-fns';
import I18n from 'i18n-js';
import { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Header,
  Image,
  Table as TableSemantic,
  Label,
  LabelGroup,
  Popup,
} from 'semantic-ui-react';

import { ButtonGDY } from '../../../../../components/Form/ButtonGDY';
import GenerateScheduleTaskFormModal from '../../../../../components/GenerateScheduleTaskFormModal';
import { theme } from '../../../../../global/styles/theme';
import { usePermission } from '../../../../../hooks/usePermission';
import { downloadFileService } from '../../../../../services/fileService';
import { ProjectsResponseDataProps } from '../../../../../types/project';
import { getDateFormat } from '../../../../../utils/getDateFormat';
import { getSemanticColor } from '../../../../../utils/getSemanticColor';
import { fleetTreatment } from '../utils/colors';
import { TableColumnActions } from './TableColumnActions';

interface TableBodyProps {
  projects?: ProjectsResponseDataProps;
  handleFleetDetailsModalOpen(id: number): void;
  handleProjectFormModalOpen(projectId: number): void;
  handleProjectDeleteModalOpen(projectId: number): void;
}

export function TableBody({
  projects,
  handleFleetDetailsModalOpen,
  handleProjectFormModalOpen,
  handleProjectDeleteModalOpen,
}: TableBodyProps) {
  const { isEditor } = usePermission();
  const [
    isInspAndRepTasksConfirmationModalOpen,
    setIsInspectionAndReportTasksConfirmationModalOpen,
  ] = useState(false);
  const [projectIdSelected, setProjectIdSelected] = useState<number>();
  const { tireEvaluationPage } = useParams<'tireEvaluationPage'>();
  const location = useLocation();

  function onFocusRequestDownload(fileName: string) {
    downloadFileService(fileName, 'project')
      .then(() => {
        toast.success('File download sucess');
      })
      .catch(() => {
        toast.error('File download error');
      });
  }

  function handleInspAndRepConfirmationModalOpen(projectId: number) {
    setIsInspectionAndReportTasksConfirmationModalOpen(true);
    setProjectIdSelected(projectId);
  }

  function handleInspAndRepConfirmationModalClose() {
    setIsInspectionAndReportTasksConfirmationModalOpen(false);
  }

  return (
    <>
      <TableSemantic.Body className="tbody">
        {projects ? (
          projects?._embedded.projects?.map(project => {
            return (
              <TableSemantic.Row key={project.id} className="tr noRowspan">
                <TableSemantic.Cell className="td id">
                  {project.id}
                </TableSemantic.Cell>
                <TableSemantic.Cell className="td folder">
                  <Header>
                    <Header.Content>
                      <Link
                        to={`${location.pathname}/${
                          project.id
                        }/tire-evaluation/${tireEvaluationPage ?? 1}`}
                        state={project.folder}
                      >
                        {project.folder.toUpperCase()}
                      </Link>
                    </Header.Content>
                  </Header>
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td status">
                  <Label
                    size="small"
                    color={getSemanticColor(project.projectStatus?.label)}
                    content={project.projectStatus?.label}
                  />
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td focusRequest">
                  {project.focusRequest && (
                    <Popup
                      inverted
                      content="Download File"
                      trigger={
                        <ButtonGDY
                          size="mini"
                          icon="cloud download"
                          onClick={() =>
                            onFocusRequestDownload(
                              project.focusRequest as string,
                            )
                          }
                        />
                      }
                    />
                  )}
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td testType">
                  {project.testType?.label}
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td table-description">
                  {project.description}
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td service">
                  {project.services?.[0]?.label}
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td tirePosition">
                  {project.tirePosition?.label}
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td fleet">
                  <Label.Group size="small">
                    {fleetTreatment(project.tireEvaluations).map(fleet => {
                      return (
                        <Label
                          style={{
                            cursor: 'pointer',
                            backgroundColor: theme.colors.primary,
                            color: theme.colors.white,
                          }}
                          key={`${project.id}-${fleet.id}`}
                          onClick={() => {
                            handleFleetDetailsModalOpen(fleet.id);
                          }}
                        >
                          {fleet.name}
                          <Label.Detail>{fleet.quantity}</Label.Detail>
                        </Label>
                      );
                    })}
                  </Label.Group>
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td">
                  <Header as="h4" image>
                    <Image
                      src={`https://ui-avatars.com/api/?rounded=true&background=random&color=random&name=${project.responsible.name}`}
                      rounded
                      size="mini"
                    />

                    <Header.Content>
                      {project.responsible.name}
                      <Header.Subheader>
                        {project.responsible.email}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td table-totalTires">
                  {project.totalTires}
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td startDate">
                  {getDateFormat(project.started)}
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td averageDate">
                  {getDateFormat(project.averageDate)}
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td finishDate">
                  {getDateFormat(project.finished)}
                </TableSemantic.Cell>

                <TableSemantic.Cell className="td reportLink">
                  <LabelGroup>
                    {project?.linkReports?.map(
                      (linkReport, linkReportIndex: number) => (
                        <Label
                          as="a"
                          href={linkReport.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={`${project.id}-${linkReport.id}`}
                          color={getSemanticColor(linkReport.status.label)}
                        >
                          {`${linkReportIndex + 1}° Report - ${
                            linkReport.percentageReport?.label
                          } -
                        ${format(
                          new Date(linkReport?.estimatedDate),
                          'MM/dd/yyyy',
                        )} - 
                        ${linkReport.status?.label}`}
                        </Label>
                      ),
                    )}
                  </LabelGroup>
                </TableSemantic.Cell>

                {isEditor && (
                  <TableColumnActions
                    project={project}
                    handleProjectFormModalOpen={handleProjectFormModalOpen}
                    handleProjectDeleteModalOpen={handleProjectDeleteModalOpen}
                    handleInspAndRepConfirmationModalOpen={
                      handleInspAndRepConfirmationModalOpen
                    }
                  />
                )}
              </TableSemantic.Row>
            );
          })
        ) : (
          <TableSemantic.Row>
            <TableSemantic.Cell colSpan="20">
              <Header>{I18n.t('error.noDataFound')}</Header>
            </TableSemantic.Cell>
          </TableSemantic.Row>
        )}
      </TableSemantic.Body>

      {isInspAndRepTasksConfirmationModalOpen && (
        <GenerateScheduleTaskFormModal
          isModalOpen={isInspAndRepTasksConfirmationModalOpen}
          projectId={projectIdSelected}
          onClose={handleInspAndRepConfirmationModalClose}
          setIsRefetchProjectById={() => false}
        />
      )}
    </>
  );
}
