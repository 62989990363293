import I18n from 'i18n-js';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Form, Icon, Input, Table } from 'semantic-ui-react';

export function ShareBusiness() {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'generalInformation.shareBusiness',
  });

  function removeItem(index: number) {
    remove(index);
  }

  function addItem() {
    append({});
  }

  return (
    <Form.Field>
      <label>
        {I18n.t('page.fleets.label.generalInformation.shareBusiness')}
      </label>
      <Table
        style={{ marginTop: 0 }}
        celled
        striped
        compact
        unstackable
        size="small"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Brand</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={6}>
              Share
            </Table.HeaderCell>
            <Table.HeaderCell collapsing width={2} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fields.map((item, index) => (
            <Table.Row key={item.id}>
              <Table.Cell>
                <Controller
                  control={control}
                  name={`generalInformation.shareBusiness.${index}.brand`}
                  render={({ field: { value, onChange } }) => (
                    <Form.Input
                      maxLength={200}
                      value={value}
                      onChange={onChange}
                      fluid
                    />
                  )}
                />
              </Table.Cell>
              <Table.Cell>
                <Controller
                  control={control}
                  name={`generalInformation.shareBusiness.${index}.share`}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      label="%"
                      labelPosition="right"
                      type="number"
                      value={value}
                      onChange={onChange}
                      fluid
                    />
                  )}
                />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon
                  link
                  name="delete"
                  color="red"
                  onClick={() => removeItem(index)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Form.Button
        type="button"
        basic
        color="blue"
        content="Add"
        onClick={() => addItem()}
        size="small"
        fluid
      />
    </Form.Field>
  );
}
