/* eslint-disable import-helpers/order-imports */
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import ReactGA from 'react-ga4';

import { AuthProvider } from './contexts/auth';
import { GlobalStyle, theme } from './global/styles/theme';
import './locales';
import Routes from './Routes';
import { queryClient } from './services/queryClient';
import { REACT_APP_TAG_GOOGLE } from './settings/constants';

ReactGA.initialize(REACT_APP_TAG_GOOGLE);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Routes />
          </AuthProvider>
          <GlobalStyle />
          <ToastContainer />
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
