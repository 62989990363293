import { useQuery } from 'react-query';

import { IsOpenOverviewProps } from '..';

import { getBasicChartsService } from '../../../services/dashboard';
import { ChartOverviewDataProps } from '../../../types/dashboard';
import useDepartamentChartData from './useDepartamentChartData';
import useEngineersChartData from './useEngineersChartData';
import useFleetsChartData from './useFleetsChartData';
import useProjectsChartData from './useProjectsChartData';
import useScheduleChartData from './useScheduleChartData';

export function useChartData(
  isOpenOverview: IsOpenOverviewProps,
): ChartOverviewDataProps {
  const { data: basicCharts, isLoading: isLoadingBasicCharts } = useQuery(
    'dashboard_basic_charts',
    getBasicChartsService,
  );

  const departamentChartData = useDepartamentChartData(isOpenOverview);

  const projectsChartData = useProjectsChartData(isOpenOverview);

  const scheduleChartData = useScheduleChartData(isOpenOverview);

  const engineersChartData = useEngineersChartData(isOpenOverview);

  const fleetsChartData = useFleetsChartData(isOpenOverview);

  return {
    macro: {
      basicCharts,
      isLoadingBasicCharts,
    },
    departament: departamentChartData,
    projects: projectsChartData,
    schedule: scheduleChartData,
    fleets: fleetsChartData,
    engineers: engineersChartData,
  };
}
