import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ISelectedScheduleTaskProps } from '../../..';
import { ScheduleActivityFormData } from '../../../../../types/schedule';

interface IProps {
  methods: UseFormReturn<ScheduleActivityFormData, any>;
  setIsCopyConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedScheduleTask: React.Dispatch<
    React.SetStateAction<ISelectedScheduleTaskProps>
  >;
}

export function onCopyScheduleTask({
  methods,
  setIsCopyConfirmationModalOpen,
  setSelectedScheduleTask,
}: IProps) {
  methods.setValue('id', undefined);
  methods.setValue('initialDate', '');
  methods.setValue('planningScheduleId', 5);

  setIsCopyConfirmationModalOpen(false);

  setSelectedScheduleTask({
    taskId: undefined,
    taskSeriesId: undefined,
  });

  toast.success('Schedule Task Copied');
}
