import I18n from 'i18n-js';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Table as SUITable, PaginationProps } from 'semantic-ui-react';

import { Pagination } from '../../../../components/Pagination';
import { queryClient } from '../../../../services/queryClient';
import { createTireEvaluationService } from '../../../../services/tireEvaluation';
import { PROJECTS_PAGE } from '../../../../settings/constants';
import {
  TireEvaluation,
  TireEvaluationFormData,
  TireEvaluationResponseDataProps,
} from '../../../../types/tireEvaluation';
import duplicateObject from '../duplicateObject';
import TableBody from './TableBody';
import { TableHeader } from './TableHeader';

interface ITableProps {
  tireEvaluations?: TireEvaluationResponseDataProps;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  handleTireEvaluationDeleteModalOpen(tireEvaluationId: number): void;
}

export function Table({
  tireEvaluations,
  page,
  setPage,
  handleTireEvaluationDeleteModalOpen,
}: ITableProps) {
  const { projectPage, projectId } = useParams<'projectPage' | 'projectId'>();
  const navigate = useNavigate();

  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps,
  ) {
    setPage(Number(activePage));
    navigate(
      `${PROJECTS_PAGE}/${projectPage}/${projectId}/tire-evaluation/${activePage}`,
    );
  }

  const duplicateTireEvaluation = useMutation(
    async (data: TireEvaluationFormData) => {
      return createTireEvaluationService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tireEvaluations');
        toast.success(I18n.t('page.tireEvaluations.toast.success.create'));
      },
      onError: () => {
        toast.error(I18n.t('page.tireEvaluations.toast.error.create'));
      },
    },
  );

  const handleDuplicateLine = (data: TireEvaluation) => {
    return async () => {
      await duplicateTireEvaluation.mutateAsync(duplicateObject(data));
    };
  };

  return (
    <span>
      <div className="table__scrollable">
        <SUITable className="table" unstackable>
          <TableHeader />
          <TableBody
            tireEvaluations={tireEvaluations}
            handleDeleteModalOpen={handleTireEvaluationDeleteModalOpen}
            handleDuplicateLine={handleDuplicateLine}
          />
        </SUITable>
      </div>

      <div className="table__pagination">
        <Pagination
          page={page}
          totalPages={tireEvaluations?.page.totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </span>
  );
}
