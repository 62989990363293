import I18n from 'i18n-js';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Form, Input } from 'semantic-ui-react';

import { EditableDropdown } from '../../../../components/Form/EditableDropdown';
import { toDropdownItemProps } from '../../../../helpers/toDropdownItemProps';
import {
  createScrapingRadius,
  deleteScrapingRadius,
  getScrapingRadius,
  updateScrapingRadius,
} from '../../../../services/sweepRadiusService';
import {
  createUsedBandService,
  deleteUsedBandService,
  getUsedBandService,
  updateUsedBandService,
} from '../../../../services/usedBandService';
import {
  FLEET_BUFFINGRADIUS_QUERYKEY,
  MAX_CHARS_TEXT_AREA,
  USEDBAND_QUERYKEY,
} from '../../../../settings/constants';
import { InputRecapping } from './components/InputRecapping';

export default function TireRecap() {
  const { control } = useFormContext();

  const { data: usedBand } = useQuery(USEDBAND_QUERYKEY, async () => {
    return toDropdownItemProps(await getUsedBandService());
  });

  const { data: scrapingRadius } = useQuery(
    FLEET_BUFFINGRADIUS_QUERYKEY,
    async () => {
      return toDropdownItemProps(await getScrapingRadius());
    },
  );

  return (
    <>
      <Controller
        control={control}
        name="tireRecap.grooveRecappingBoMm"
        render={({ field: { value, onChange, onBlur } }) => (
          <Form.Field>
            <label>
              {I18n.t('page.fleets.label.tireRecap.pullPointRetread')}
            </label>
            <Input
              label={{ content: 'mm' }}
              labelPosition="right"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Form.Field>
        )}
      />

      <InputRecapping />

      <EditableDropdown
        controllerControl={control}
        controllerName="tireRecap.usedBands"
        label={I18n.t('page.fleets.label.tireRecap.retreadBrand')}
        queryKey={USEDBAND_QUERYKEY}
        multiple
        options={usedBand}
        createItemService={createUsedBandService}
        updateItemService={updateUsedBandService}
        deleteItemService={deleteUsedBandService}
      />

      <Controller
        control={control}
        name="tireRecap.bandModel"
        render={({ field: { value, onChange, onBlur } }) => (
          <Form.Input
            maxLength={200}
            label={I18n.t('page.fleets.label.tireRecap.treadModel')}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="tireRecap.sweepRadius"
        label={I18n.t('page.fleets.label.tireRecap.buffingRadius')}
        queryKey={FLEET_BUFFINGRADIUS_QUERYKEY}
        multiple
        options={scrapingRadius}
        createItemService={createScrapingRadius}
        updateItemService={updateScrapingRadius}
        deleteItemService={deleteScrapingRadius}
      />

      <Controller
        control={control}
        name="tireRecap.sweepRadiusDescription"
        render={({ field: { value, onChange, onBlur } }) => (
          <Form.TextArea
            type="text"
            maxLength={MAX_CHARS_TEXT_AREA}
            label={I18n.t('page.fleets.label.tireRecap.retreadShop')}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />
    </>
  );
}
