import { Tire } from '../types/project';
import api from './api';

export async function getProjectTotalTiresService(
  id?: number,
): Promise<Tire[]> {
  const response = await api.get(`/projects/${id}/tires/totalTires/`);

  return response.data;
}
