import styled from 'styled-components';

import gdyNameLogo from '../../../assets/img/goodyear-logo-footer.png';
import gdyWingFootLogo from '../../../assets/img/wingfoot-logo-yellow.png';

export const Container = styled.div`
  header {
    border-bottom: 4px solid ${({ theme }) => theme.colors.secondary};

    position: fixed;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 56px;

    padding: 0 22px;

    background: ${({ theme }) => theme.colors.primary};

    z-index: 31;
  }

  .header__menu-bar {
    display: none;

    cursor: pointer;
  }

  @media (max-width: 460px) {
    header {
      position: fixed;
      top: 0;

      padding: 0 10px;
    }

    .header__menu-bar {
      display: block;
      font-size: 18px;
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const ApplicationName = styled.div`
  display: flex;
  align-items: center;

  .header__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;

    span {
      color: ${({ theme }) => theme.colors.white};

      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 460px) {
    .header__title {
      .header__title--systemName {
        margin-left: 5px;
      }
    }
  }
`;

export const Logo = styled.div`
  width: 160px;
  height: 30px;
  background-image: url(${gdyNameLogo});
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 460px) {
    background-image: url(${gdyWingFootLogo});
    width: 30px;
    padding-right: 5px;
  }
`;

export const UserProfile = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 460px) {
    .header__profile-menu {
      position: absolute;
      left: -90px !important;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;

    text-align: right;

    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  img {
    width: 40px;
    height: 40px;

    margin-left: 8px;

    border-radius: 20px;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
  }

  @media (max-width: 460px) {
    div {
      display: none;
    }
  }
`;
