import styled from 'styled-components';

interface IFilterbarProps {
  show: boolean;
}

export const Container = styled.div<IFilterbarProps>`
  background-color: #fff;
  width: 290px;
  margin-top: 0px;
  border-radius: 5px;
  box-shadow: -1px 1px 6px 0px rgba(0, 0, 0, 0.25);

  position: fixed;
  top: 196px;
  right: ${({ show }) => (show ? '50px' : '-300px')};

  transition: ${({ theme }) => theme.transitions.fast};
  z-index: 2;

  form {
    max-height: 60vh;
    display: flex;
    flex-direction: column;

    .filter__header {
      .filter__logo {
        display: none;
      }
      .filter__title {
        font-size: 1.4em;
        font-weight: bold;

        padding: 8px 16px;
        border-bottom: 1px solid #f2f2f2;
        opacity: 0.8;
      }
      .filter__close {
        display: none;
      }
    }

    .filter__body {
      min-height: 40vh;

      overflow-y: auto;

      ::-webkit-scrollbar-track {
        background: none;
      }
      ::-webkit-scrollbar {
        width: 4px;
        scroll-padding-top: 100px;
      }
    }
    .filter__submit {
      position: relative;
      bottom: 0;

      .button {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  @media (max-width: 460px) {
    height: 100%;
    width: 100vw;
    margin-top: 0;
    padding: 0;
    border-radius: 0;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;

    form {
      min-height: none;
      height: 100vh;
      max-height: none;
      position: relative;
      top: 0;
      bottom: 0;

      .filter__header {
        background-color: #004ea8;
        height: 56px;
        padding: 0 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .filter__logo {
          display: block;
        }
        .filter__title {
          color: #fff;
          border-bottom: 0;
          opacity: 1;
        }
        .filter__close {
          display: block;
          color: ${({ theme }) => theme.colors.secondary};
        }
      }

      .filter__body {
        height: 100%;
        padding: 14px;
        flex: 1;
      }

      .filter__submit {
        position: relative;
        bottom: 0;
        padding: 16px;

        box-shadow: 0 -5px 3px -3px rgba(0, 0, 0, 0.1);
      }
    }
  }
`;
