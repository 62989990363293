import { TestType } from '../../../../../types/project';

export const getProjectFolderMask = (
  testTypeList?: TestType[],
  testTypeSelectedId?: number,
): string => {
  if (testTypeList) {
    return testTypeList?.find(testType => testType.id === testTypeSelectedId)
      ?.mask as string;
  }

  return '';
};
