import { GenericObject } from '../types/fleet';
import api from './api';

const CRISS_CROSS_TYPE = '/crissCrossTypes';

export async function getCrissCrossTypeService(): Promise<GenericObject[]> {
  const response = await api.get(`${CRISS_CROSS_TYPE}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createCrissCrossTypeService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${CRISS_CROSS_TYPE}/`, item);

  return response.data;
}

export async function updateCrissCrossTypeService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${CRISS_CROSS_TYPE}/`, item);

  return response.data;
}

export async function deleteCrissCrossTypeService(id: number) {
  await api.delete(`${CRISS_CROSS_TYPE}/${id}`);
}
