import React from 'react';
import { UseMutationResult } from 'react-query';

import { GenericObject } from '../../../../types/fleet';
import { handleClickEditItem } from './handleClickEditItem';

interface ItemsProps {
  key: number;
  text: string;
  isEdit: boolean;
}

interface Props {
  setItems: React.Dispatch<ItemsProps[]>;
  items: ItemsProps[];

  key: number;

  handleUpdateItem: UseMutationResult<
    GenericObject,
    unknown,
    GenericObject,
    unknown
  >;
}
export function handleClickCheckItem({
  setItems,
  items,

  key,

  handleUpdateItem,
}: Props) {
  const index = items?.findIndex(item => item?.key === key);
  const data = {
    id: items[index].key,
    label: items[index].text,
  };

  handleClickEditItem({ setItems, items, key });

  handleUpdateItem.mutateAsync(data);
}
