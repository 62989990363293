import { UseFieldArrayRemove } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { deleteLinkReportService } from '../../../../../../../services/linkReportService';
import { queryClient } from '../../../../../../../services/queryClient';

interface IProps {
  reportLinkId?: number;
  index: number;
  remove: UseFieldArrayRemove;
  setIsTrashLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useDeleteReportLink({
  reportLinkId,
  index,
  remove,
  setIsTrashLoading,
}: IProps) {
  const deleteReportLink = useMutation(
    async () => {
      setIsTrashLoading(true);
      await deleteLinkReportService(reportLinkId as number);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('LINK_REPORTS');
        toast.success('Report Link deleted successfully');
        remove(index);
        setIsTrashLoading(false);
      },
      onError: (e: any) => {
        toast.error(e?.response?.data?.error?.message);
        setIsTrashLoading(false);
      },
    },
  );

  return { deleteReportLink };
}
