import { GenericObject } from '../types/fleet';
import api from './api';

const NUMBER_VEHICLES = '/numberVehicles';

export async function getNumberVehiclesService(): Promise<GenericObject[]> {
  const response = await api.get(`${NUMBER_VEHICLES}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createNumberVehiclesService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${NUMBER_VEHICLES}/`, item);

  return response.data;
}

export async function updateNumberVehiclesService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${NUMBER_VEHICLES}/`, item);

  return response.data;
}

export async function deleteNumberVehiclesService(id: number) {
  await api.delete(`${NUMBER_VEHICLES}/${id}`);
}
