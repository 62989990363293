import {
  TireEvaluation,
  TireEvaluationFormData,
} from '../../../types/tireEvaluation';

export default function duplicateObject(data: TireEvaluation) {
  const duplicateTireEvaluationObject: TireEvaluationFormData = {
    projectId: data.project.id,
    tireId: data.tire.id,
    reports: data.reports,
    fleetId: data.fleet.id,
    responsibleId: data.responsible.id as number,
    tireQuantity: data.tireQuantity,
    statusId: data.status.id,
    assemblyDate: data.assemblyDate,
  };
  return duplicateTireEvaluationObject;
}
