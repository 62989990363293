/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../../services/queryClient';
import { GenericObject } from '../../../../types/fleet';

interface Props {
  queryKey: string;
  updateItemService?: (item: GenericObject) => Promise<GenericObject>;
}

export function useHandleUpdateItem({ queryKey, updateItemService }: Props) {
  return useMutation(
    async (item: GenericObject) => {
      if (updateItemService) {
        return updateItemService(item);
      }

      return {} as GenericObject;
    },
    {
      onSuccess: () => {
        toast.success('Item updated successfully');
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        toast.error('Error updating item');
      },
    },
  );
}
