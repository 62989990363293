import I18n from 'i18n-js';

import enUS from './translations/en_US.json';
import ptBR from './translations/pt_BR.json';

enum LanguageLocationEnum {
  'en-US' = 'en_US',
  'pt-BR' = 'pt_BR',
}

I18n.translations = {
  en_US: enUS,
  pt_BR: ptBR,
};

I18n.fallbacks = true;

function setTranslate(language: string) {
  I18n.locale = language;
}

I18n.defaultLocale = LanguageLocationEnum['en-US'];

export { setTranslate, LanguageLocationEnum };
