import React from 'react';

interface Props {
  items: any;
  setItems: React.Dispatch<any[]>;
  id?: number;
  e: React.ChangeEvent<HTMLInputElement>;
  value: string;
  isLowerCase?: boolean;
}
export function handleChangeLabelItem({
  items,
  setItems,
  id,
  e,
  value,
  isLowerCase,
}: Props) {
  const index = items?.findIndex((item: any) => item?.id === id);

  const updatingItems = [...(items ?? [])];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  updatingItems[index][e.currentTarget.name] = isLowerCase
    ? value.toLowerCase()
    : value.toUpperCase();

  setItems([...updatingItems]);
}
