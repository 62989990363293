import I18n from 'i18n-js';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, TextArea } from 'semantic-ui-react';

import { MAX_CHARS_TEXT_AREA } from '../../../../settings/constants';

export default function AdditionalInformation() {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="additionalInformation.additionalInformation"
      render={({ field: { onChange, onBlur, value } }) => (
        <Form.Field
          type="text"
          maxLength={MAX_CHARS_TEXT_AREA}
          label={I18n.t('page.fleets.label.additionalInformation')}
          control={TextArea}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    />
  );
}
