import { GenericObject } from '../types/fleet';
import api from './api';

const AXLES = '/axles';

export async function getTruckAxlesService(): Promise<GenericObject[]> {
  const response = await api.get(`${AXLES}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createTruckAxlesService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${AXLES}/`, item);

  return response.data;
}

export async function updateTruckAxlesService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${AXLES}/`, item);

  return response.data;
}

export async function deleteTruckAxlesService(id: number) {
  await api.delete(`${AXLES}/${id}`);
}
