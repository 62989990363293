import I18n from 'i18n-js';
import { UseMutationResult } from 'react-query';
import { Input, Icon, Table as TableSUI } from 'semantic-ui-react';

import { ItemsProps } from '../..';
import { GenericObject } from '../../../../../types/fleet';
import { handleChangeLabelItem } from '../../utils/handleChangeLabelItem';
import { handleClickCheckItem } from '../../utils/handleClickCheckItem';
import { handleClickEditItem } from '../../utils/handleClickEditItem';

interface Props {
  items?: ItemsProps[];
  setItems: React.Dispatch<ItemsProps[]>;
  handleUpdateItem: UseMutationResult<
    GenericObject,
    unknown,
    GenericObject,
    unknown
  >;
  handleDeleteItem: UseMutationResult<void, unknown, number, unknown>;
}

export function Table({
  items,
  setItems,
  handleUpdateItem,
  handleDeleteItem,
}: Props) {
  return (
    <TableSUI striped unstackable compact size="small">
      <TableSUI.Header>
        <TableSUI.Row>
          <TableSUI.HeaderCell colspan="4">
            {I18n.t('component.editableDropdown.label.item')}
          </TableSUI.HeaderCell>
        </TableSUI.Row>
      </TableSUI.Header>
      <TableSUI.Body>
        {items?.map(({ key, text, isEdit }) => (
          <TableSUI.Row key={key}>
            {isEdit ? (
              <Input
                value={text}
                onChange={(_, { value }) =>
                  handleChangeLabelItem({
                    setItems,
                    items,
                    key,
                    value,
                  })
                }
                fluid
              />
            ) : (
              <TableSUI.Cell>{text}</TableSUI.Cell>
            )}
            {isEdit ? (
              <TableSUI.Cell width="1">
                <Icon
                  link
                  name="check"
                  color="green"
                  onClick={() =>
                    handleClickCheckItem({
                      setItems,
                      items,
                      key,
                      handleUpdateItem,
                    })
                  }
                />
              </TableSUI.Cell>
            ) : (
              <TableSUI.Cell />
            )}
            <TableSUI.Cell width="1">
              <Icon
                link
                name="pencil"
                color={isEdit ? undefined : 'yellow'}
                onClick={() => handleClickEditItem({ setItems, items, key })}
                disabled={isEdit}
              />
            </TableSUI.Cell>
            <TableSUI.Cell width="1">
              <Icon
                link
                name="trash"
                color={isEdit ? undefined : 'red'}
                onClick={() => handleDeleteItem.mutateAsync(key)}
                disabled={isEdit}
              />
            </TableSUI.Cell>
          </TableSUI.Row>
        ))}
      </TableSUI.Body>
    </TableSUI>
  );
}
