import React from 'react';

interface ItemsProps {
  key: number;
  text: string;
  isEdit: boolean;
}

interface Props {
  setItems: React.Dispatch<ItemsProps[]>;
  items: ItemsProps[];

  key: number;
}
export function handleClickEditItem({
  setItems,
  items,

  key,
}: Props) {
  const index = items?.findIndex(item => item?.key === key);

  const updatingItems = [...items];

  updatingItems[index].isEdit = !items[index].isEdit;

  setItems([...updatingItems]);
}
