import I18n from 'i18n-js';
import React from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { Modal, Form } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../../../../../components/Form/ControllerFormDropdown';
import { SpecialEditableDropdown } from '../../../../../../components/Form/SpecialEditableDropdown';
import { toDropdownItemProps } from '../../../../../../helpers/toDropdownItemProps';
import { getFleetListService } from '../../../../../../services/fleets';
import { getTireListService } from '../../../../../../services/tire';
import { getTireEvaluationStatusService } from '../../../../../../services/tireEvaluation';
import { getUserListService } from '../../../../../../services/user';
import {
  FIELD_ERROR_MESSAGE,
  TIRES_QUERYKEY,
  USERS_QUERYKEY,
} from '../../../../../../settings/constants';
import { TireEvaluationFormData } from '../../../../../../types/tireEvaluation';

interface IFormModalContentProps {
  control: Control<TireEvaluationFormData, object>;
  formRef: React.RefObject<HTMLFormElement>;
  formState: FormState<TireEvaluationFormData>;
  onSubmit: (data: TireEvaluationFormData) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<TireEvaluationFormData>;
}

export function FormModalContent({
  control,
  formRef,
  formState,
  onSubmit,
  handleSubmit,
}: IFormModalContentProps) {
  const { data: fleets } = useQuery('fleetsOptions', async () =>
    toDropdownItemProps(
      await getFleetListService({
        page: 0,
        size: 100,
      }),
    ),
  );

  const { data: status } = useQuery(
    'TIRE_EVALUATION_STATUS_OPTIONS',
    async () =>
      toDropdownItemProps(
        (await getTireEvaluationStatusService()).tireEvaluationStatus,
        true,
      ),
  );

  const { data: tires } = useQuery(TIRES_QUERYKEY, async () =>
    getTireListService({}),
  );

  const { data: users } = useQuery(USERS_QUERYKEY, async () =>
    getUserListService(),
  );

  return (
    <Modal.Content scrolling>
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <ControllerFormDropdown
          controllerControl={control}
          controllerName="fleetId"
          label={I18n.t('page.tireEvaluations.label.fleet')}
          options={fleets ?? []}
          fluid
          required
          error={formState.errors.fleetId?.type && I18n.t(FIELD_ERROR_MESSAGE)}
        />

        <SpecialEditableDropdown
          type="tires"
          controllerControl={control}
          controllerName="tireId"
          label={I18n.t('page.tireEvaluations.label.tire')}
          tires={tires}
          required
          error={formState.errors.tireId?.type && I18n.t(FIELD_ERROR_MESSAGE)}
          activateLabelError={!!formState.errors.tireId?.type}
        />

        <SpecialEditableDropdown
          type="users"
          controllerControl={control}
          controllerName="responsibleId"
          label={I18n.t('page.tireEvaluations.label.responsible')}
          users={users}
          required
          error={
            formState.errors.responsibleId?.type && I18n.t(FIELD_ERROR_MESSAGE)
          }
          activateLabelError={!!formState.errors.responsibleId?.type}
        />

        <Controller
          control={control}
          name="tireQuantity"
          render={({ field: { onChange, value } }) => (
            <Form.Input
              type="number"
              label={I18n.t('page.tireEvaluations.label.tiresQty')}
              onChange={onChange}
              value={value}
            />
          )}
        />

        <ControllerFormDropdown
          controllerControl={control}
          controllerName="statusId"
          label="Status"
          options={status ?? []}
          fluid
          required
          error={formState.errors.statusId?.type && I18n.t(FIELD_ERROR_MESSAGE)}
        />

        <Controller
          control={control}
          name="assemblyDate"
          render={({ field: { onChange, value } }) => (
            <Form.Input
              type="date"
              label={I18n.t('page.tireEvaluations.label.assemblyDate')}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </Form>
    </Modal.Content>
  );
}
