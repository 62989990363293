import { Control, Controller } from 'react-hook-form';
import {
  Form,
  Input,
  SemanticWIDTHS,
  StrictInputProps,
} from 'semantic-ui-react';

interface Props extends StrictInputProps {
  controllerControl: Control<any>;
  controllerName: string;
  required?: boolean;
  fieldLabel?: string;
  fieldWidth?: SemanticWIDTHS;
}

export function ControllerInput({
  controllerControl,
  controllerName,
  required,
  fieldLabel,
  fieldWidth,
  ...rest
}: Props) {
  return (
    <Controller
      control={controllerControl}
      name={controllerName}
      rules={{
        required,
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <Form.Field width={fieldWidth}>
          <label>{fieldLabel}</label>
          <Input {...rest} value={value} onChange={onChange} onBlur={onBlur} />
        </Form.Field>
      )}
    />
  );
}
