import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  overflow-x: hidden;

  margin-top: 56px;

  @media (max-width: 460px) {
    height: auto;
    width: 100vw;
    margin-top: 0;
    padding: 20px 10px;
  }
`;
