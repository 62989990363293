import { Control, Controller } from 'react-hook-form';
import {
  Form,
  Input,
  LabelProps,
  SemanticShorthandItem,
  StrictInputProps,
} from 'semantic-ui-react';

type Props = Omit<StrictInputProps, 'control' | 'error'> & {
  controllerControll: Control<any>;
  controllerName: string;
  controllerRequired?: boolean;
  fieldError?: SemanticShorthandItem<LabelProps>;
};

export function ControllerFormFieldInput({
  controllerControll,
  controllerName,
  controllerRequired,
  fieldError,
  ...rest
}: Props) {
  return (
    <Controller
      control={controllerControll}
      name={controllerName}
      rules={{ required: controllerRequired }}
      render={({ field: { onChange, value } }) => (
        <Form.Field style={{ marginTop: 0 }} error={fieldError}>
          <Input
            {...rest}
            value={value}
            onChange={(_, data) => onChange(data.value.replace(',', '.'))}
            size="small"
          />
        </Form.Field>
      )}
    />
  );
}
