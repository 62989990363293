/* eslint-disable @typescript-eslint/ban-ts-comment */
import I18n from 'i18n-js';
import { Control, Controller, FormState } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Form } from 'semantic-ui-react';

import { FIELD_ERROR_MESSAGE } from '../../../../../../../settings/constants';
import { ProjectFormData } from '../../../../../../../types/project';

interface IProps {
  control: Control<ProjectFormData, any>;
  formState: FormState<ProjectFormData>;
  folderMask: string;
}

export function ControllerFormInputMask({
  control,
  formState: { errors },
  folderMask,
}: IProps) {
  return (
    <Controller
      control={control}
      name="folder"
      rules={{
        required: true,
      }}
      render={({ field: { value, onChange } }) => (
        <Form.Field required error={!!errors.folder}>
          <label>{I18n.t('page.projects.label.folder')}</label>
          <InputMask
            mask={folderMask}
            maskChar={null}
            placeholder={folderMask && `Ex.: ${folderMask}`}
            value={value}
            onChange={onChange}
            error={errors.folder && I18n.t(FIELD_ERROR_MESSAGE)}
          >
            {/* @ts-ignore */}
            {inputProps => <Form.Input {...inputProps} />}
          </InputMask>
        </Form.Field>
      )}
    />
  );
}
