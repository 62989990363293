import React from 'react';
import { UseMutationResult } from 'react-query';

import { ItemsProps } from '..';

import { GenericObject } from '../../../../../../types/fleet';
import { handleClickEditItem } from './handleClickEditItem';

interface Props {
  items: ItemsProps[];
  setItems: React.Dispatch<ItemsProps[]>;

  id?: number;

  handleUpdateItem: UseMutationResult<
    GenericObject,
    unknown,
    GenericObject,
    unknown
  >;
}
export function handleClickCheckItem({
  items,
  setItems,

  id,

  handleUpdateItem,
}: Props) {
  const index = items?.findIndex(item => item?.id === id);
  const data = {
    id: items[index].id,
    label: items[index].label,
    mask: items[index].mask,
  };

  handleClickEditItem({ setItems, items, id });

  handleUpdateItem.mutateAsync(data);
}
