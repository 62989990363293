import { Button as ButtonSemantic, ButtonProps } from 'semantic-ui-react';
import styled from 'styled-components';

export const Button = styled(ButtonSemantic)<ButtonProps>`
  background-color: ${({ theme, active }) =>
    active
      ? `${theme.colors.primaryHover} !important`
      : `${theme.colors.primary} !important`};
  color: ${({ theme }) => `${theme.colors.secondary} !important`};

  :hover {
    background-color: ${({ theme }) =>
      `${theme.colors.primaryHover} !important`};

    transition: ${({ theme }) => theme.transitions.fast};
  }
`;
