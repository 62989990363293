import React from 'react';

interface ItemsProps {
  key: number;
  text: string;
  isEdit: boolean;
}

interface Props {
  setItems: React.Dispatch<ItemsProps[]>;
  items: ItemsProps[];

  key: number;
  value: string;
}
export function handleChangeLabelItem({
  setItems,
  items,

  key,
  value,
}: Props) {
  const index = items?.findIndex(item => item?.key === key);

  const updatingItems = [...items];

  updatingItems[index].text = value.toUpperCase();

  setItems([...updatingItems]);
}
