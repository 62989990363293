import I18n from 'i18n-js';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  Header as HeaderSemantic,
  Icon,
  Loader,
} from 'semantic-ui-react';

import { useAuth } from '../../../contexts/auth';
import useIsMobileScreen from '../../../hooks/useIsMobileScreen';
import { LanguageLocationEnum, setTranslate } from '../../../locales';
import { BASE_URL } from '../../../settings/constants';
import { Sidebar } from '../Sidebar';
import {
  ApplicationName,
  Container,
  Logo,
  Profile,
  UserProfile,
} from './styles';

interface IProps {
  children: React.ReactNode;
}

function Header({ children }: IProps) {
  const { user, isUserLoading, signOut } = useAuth();
  const { isMobileScreen } = useIsMobileScreen(460);

  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow(oldValue => !oldValue);

  const handleChangeLanguage = (language: string) => setTranslate(language);

  const handleLogout = () => {
    signOut();
  };

  const getUserInformation = useCallback(() => {
    if (isUserLoading) {
      return <Loader active inline />;
    }

    return (
      <>
        <div>
          <span>{user?.displayName}</span>
          <span>{user?.mail}</span>
        </div>
        <img
          src={
            user?.photo_url ??
            `https://ui-avatars.com/api/?rounded=true&background=random&color=random&name=${user?.displayName}`
          }
          alt="profile_photo"
        />
      </>
    );
  }, [isUserLoading, user]);

  return (
    <Container>
      <header>
        <Icon className="header__menu-bar" name="bars" onClick={handleShow} />

        <ApplicationName>
          <HeaderSemantic as="h2">
            <Link to={BASE_URL} className="header__title">
              <Logo />
              <span className="header__system-ame">
                Field Engineering Project
              </span>
            </Link>
          </HeaderSemantic>
        </ApplicationName>

        <UserProfile>
          <Dropdown
            trigger={<Profile>{getUserInformation()}</Profile>}
            icon={null}
          >
            {!isUserLoading ? (
              <Dropdown.Menu className="header__profile-menu">
                <Dropdown.Item>
                  <Dropdown
                    trigger={
                      <span>
                        <Icon name="language" /> {I18n.t('label.language')}
                      </span>
                    }
                    pointing="left"
                    direction={isMobileScreen ? 'left' : 'right'}
                    options={[
                      {
                        key: 1,
                        value: 1,
                        text: 'English',
                        onClick: () =>
                          handleChangeLanguage(LanguageLocationEnum['en-US']),
                        flag: 'us',
                      },
                      {
                        key: 2,
                        value: 2,
                        text: 'Português',
                        onClick: () =>
                          handleChangeLanguage(LanguageLocationEnum['pt-BR']),
                        flag: 'br',
                      },
                    ]}
                  />
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item text={I18n.t('label.information')} icon="info" />

                <Dropdown.Divider />
                <Dropdown.Item
                  text={I18n.t('label.logout')}
                  icon="sign out"
                  onClick={handleLogout}
                />
              </Dropdown.Menu>
            ) : undefined}
          </Dropdown>
        </UserProfile>
      </header>

      <Sidebar show={show} handleShow={handleShow} />
      {children}
    </Container>
  );
}

export { Header };
