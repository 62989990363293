import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getProjectByIdService } from '../../../services/project';

export function useFetchProjectById(projectId: number) {
  const [isRefetchProjectById, setIsRefetchProjectById] = useState(false);

  const [generateTaskButton, setGenerateTaskButton] = useState({
    isDisabled: true,
  });

  const { data: project, isLoading: isProjectLoading } = useQuery(
    ['project', isRefetchProjectById],
    async () => getProjectByIdService(projectId),
    {
      enabled: generateTaskButton.isDisabled,
      retry: false,
    },
  );

  useEffect(() => {
    if (!isProjectLoading) {
      setGenerateTaskButton({
        isDisabled: project?.projectStatus.label !== 'UNDER DISCUSSION',
      });
    }
  }, [isProjectLoading, project]);
  return {
    project,
    isProjectLoading,

    setIsRefetchProjectById,

    generateTaskButton,
  };
}
