import { GenericObject } from '../types/fleet';
import api from './api';

const ACCOUNT_FLEETS = '/accountFleets';

export async function getAccountFleetService(): Promise<GenericObject[]> {
  const response = await api.get(`${ACCOUNT_FLEETS}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createAccountFleetService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${ACCOUNT_FLEETS}/`, item);

  return response.data;
}

export async function updateAccountFleetService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${ACCOUNT_FLEETS}/`, item);

  return response.data;
}

export async function deleteAccountFleetService(id: number) {
  await api.delete(`${ACCOUNT_FLEETS}/${id}`);
}
