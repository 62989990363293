import I18n from 'i18n-js';

import AdditionalInformation from '../../FleetFormModal/AdditionalInformation';
import GeneralInformation from '../../FleetFormModal/GeneralInformation';
import RoutesAndControl from '../../FleetFormModal/RoutesAndControl';
import TireRecap from '../../FleetFormModal/TireRecap';
import TireShop from '../../FleetFormModal/TireShop';
import Vehicles from '../../FleetFormModal/Vehicles';

interface IProps {
  fleetId?: number;
}

export function useSteps({ fleetId }: IProps) {
  return [
    {
      icon: 'info circle',
      title: I18n.t('page.fleets.label.generalInformation.title'),
      element: <GeneralInformation />,
    },
    {
      icon: 'road',
      title: I18n.t('page.fleets.label.routesAndControl.title'),
      element: <RoutesAndControl fleetId={fleetId} />,
    },
    {
      icon: 'truck',
      title: I18n.t('page.fleets.label.vehicles.title'),
      element: <Vehicles />,
    },
    {
      icon: 'dot circle',
      title: I18n.t('page.fleets.label.tireShop.title'),
      element: <TireShop />,
    },
    {
      icon: 'wrench',
      title: I18n.t('page.fleets.label.tireRecap.title'),
      element: <TireRecap />,
    },
    {
      icon: 'plus circle',
      title: I18n.t('page.fleets.label.additionalInformation.title'),
      element: <AdditionalInformation />,
    },
  ];
}
