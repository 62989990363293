import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;

  > p {
    margin-top: 15px;

    color: ${({ theme }) => theme.colors.white};

    > a {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  .login__goodyear-logo {
    height: 50px;
  }
`;

export const AuthenticationContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 400px;
  max-width: 500px;

  margin-top: 38px;
  padding: 28px 28px;

  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};

  > h1 {
    font-size: 1.8rem;
    font-weight: 700;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.primary};
  }

  > p {
    color: ${({ theme }) => theme.colors.text_light};
    white-space: nowrap;
    margin: 1rem 0;
  }

  @media (max-width: 460px) {
    min-width: auto;

    > h1 {
      font-size: 1.5rem;
    }
  }
`;

export const Background = styled.img`
  position: fixed;
  z-index: -1;

  width: 100%;

  @media (max-width: 1600px) {
    & {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
