import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { createLinkReportService } from '../../../../../../../services/linkReportService';
import { queryClient } from '../../../../../../../services/queryClient';
import { LinkReportFormData } from '../../../../../../../types/project';

interface IProps {
  setActiveAccordion: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useCreateReportLink({
  setActiveAccordion,
  setIsSubmitLoading,
}: IProps) {
  const createReportLink = useMutation(
    async (data: LinkReportFormData) => {
      await createLinkReportService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('LINK_REPORTS');
        toast.success('Report Link created successfully');
        setActiveAccordion(false);
        setIsSubmitLoading(false);
      },
      onError: (e: any) => {
        toast.error(e?.response?.data?.error?.message);
        setIsSubmitLoading(false);
      },
    },
  );

  return { createReportLink };
}
