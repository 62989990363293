import React from 'react';

import { ItemsProps } from '..';

interface Props {
  items: ItemsProps[];
  setItems: React.Dispatch<ItemsProps[]>;

  id?: number;
}
export function handleClickEditItem({
  items,
  setItems,

  id,
}: Props) {
  const index = items?.findIndex(item => item?.id === id);

  const updatingItems = [...items];

  updatingItems[index].isEdit = !items[index].isEdit;

  setItems([...updatingItems]);
}
