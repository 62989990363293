import { LinkReport, LinkReportFormData } from '../types/project';
import api from './api';

export async function getLinkReportByProjectIdService(
  id: number,
): Promise<LinkReport[]> {
  const response = await api.get(`/link-report/project/${id}`);

  return response.data;
}

export async function createLinkReportService(data: LinkReportFormData) {
  const response = await api.post(`/link-report/`, {
    ...data,
    estimatedDate: new Date(data.estimatedDate),
  });

  return response.data;
}

export async function updateLinkReportService(
  data: LinkReportFormData,
  isUpdateMoreReports: boolean,
) {
  const response = await api.put(
    `/link-report/`,
    {
      ...data,
      estimatedDate: new Date(data.estimatedDate),
    },
    {
      params: {
        isUpdateMoreReports,
      },
    },
  );

  return response.data;
}

export async function deleteLinkReportService(id: number) {
  const response = await api.delete(`/link-report/${id}`);

  return response.data;
}
