import styled, { DefaultTheme } from 'styled-components';

interface IProps {
  show?: boolean;
  theme?: DefaultTheme;
}

export const Container = styled.div<IProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: ${({ show }) => (show ? '20px' : '0')};

  .tireDetails__card {
    background-color: #fff;
    min-width: 350px;
    min-height: 200px;
    padding: 10px;
    display: ${({ show }) => (show ? 'block' : 'none')};
    border-radius: 5px;
    transition: ${({ theme }) => theme.transitions.fast};

    .tireDetails__wrapper {
      height: 28px;
      padding: 8px 0;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .tireDetails__title {
        font-size: 1.2em;
        font-weight: bold;
        opacity: 0.8;
      }
    }
  }

  .tireDetails__overflow {
    height: calc(100% - 60px);
    border-top: 1px solid #f2f2f2;

    overflow-y: auto;

    ::-webkit-scrollbar-track {
      background: none;
    }
    ::-webkit-scrollbar {
      width: 4px;
    }
  }

  @media (max-width: 460px) {
    min-width: auto;
    /* display: ${({ show }) => (show ? 'block' : 'none')}; */

    .tireDetails__card {
      /* min-width: auto; */
    }

    .tireDetails__overflow {
      max-height: 240px;
    }
  }
`;

export const ContainerProgressBar = styled.div<IProps>`
  padding-top: 8px;

  .progress__wrapper {
    margin-bottom: 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .progress__title {
      font-weight: bold;
      font-size: 0.8em;
      margin-right: 20px;

      opacity: 0.8;
    }
    .progress__quantity {
      font-weight: bold;
      font-size: 0.8em;
      opacity: 0.8;
    }
  }

  .progress {
    .bar {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
