import styled from 'styled-components';

import gdyNameLogo from '../../../assets/img/goodyear-logo-footer.png';
import gdyWingFootLogo from '../../../assets/img/wingfoot-logo-yellow.png';

interface NavigationProps {
  show: boolean;
}

interface NavigationActiveProps {
  active: boolean;
}

export const Container = styled.div<NavigationProps>`
  background-color: ${({ theme }) => theme.colors.gray};
  padding: ${({ show }) => (show ? '25px 22px' : '25px 0')};
  height: 100vh;
  width: ${({ show }) => (show ? '180px' : '63px')};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  align-items: ${({ show }) => (show ? 'none' : 'center')};
  position: fixed;
  top: 0;
  left: 0;

  transition: ${({ theme }) => theme.transitions.fast};

  z-index: 30;

  @media (max-width: 460px) {
    left: ${({ show }) => (show ? '0' : '-180px')};

    transition: 0.4s;
  }
`;

export const Navigation = styled.nav<NavigationProps>`
  margin-top: 56px;
`;

export const Items = styled.ul<NavigationProps>`
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;

  list-style: none;

  li {
    margin-bottom: 18px;

    a {
      display: flex;
      align-items: center;
      gap: 16px;

      color: ${({ theme }) => theme.colors.white};

      i {
        font-size: 16px;
      }

      span {
        @media (min-width: 461px) {
          display: ${({ show }) => (show ? 'block' : 'none')};
        }
      }
    }

    :first-child {
      display: flex;
      justify-content: ${({ show }) => (show ? 'flex-end' : 'start')};

      color: ${({ theme }) => theme.colors.white};
      cursor: pointer;

      @media (max-width: 460px) {
        display: none;
      }
    }
  }
`;

export const Item = styled.li<NavigationActiveProps>`
  margin-bottom: 18px;

  a {
    display: flex;
    align-items: center;

    color: ${({ theme, active }) =>
      active ? theme.colors.secondary : theme.colors.white} !important;

    i {
      font-size: 16px;
    }
  }

  &:first-child {
    display: flex;

    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;

    @media (max-width: 460px) {
      display: none;
    }
  }
`;

export const Info = styled.div<NavigationProps>`
  color: ${({ theme }) => theme.colors.white};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .info__version-and-year {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;

    .info__version {
      display: ${({ show }) => (show ? 'flex' : 'none')};
    }

    .info__year {
    }
  }

  .info__sentence {
    text-align: center;

    display: ${({ show }) => (show ? 'flex' : 'none')};
  }

  @media (max-width: 460px) {
    width: 100%;
    justify-content: center;
  }
`;

export const Logo = styled.div`
  width: 100%;
  height: 30px;
  background-image: url(${gdyWingFootLogo});
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 460px) {
    background-image: url(${gdyNameLogo});
  }
`;
