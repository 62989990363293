import I18n from 'i18n-js';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  DropdownItemProps,
  Form,
  Input,
  Modal,
  ModalProps,
} from 'semantic-ui-react';

import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { ControllerFormDropdown } from '../../../../components/Form/ControllerFormDropdown';
import { ControllerFormField } from '../../../../components/Form/ControllerFormField';
import { ControllerInput } from '../../../../components/Form/ControllerInput';
import { toDropdownItemProps } from '../../../../helpers/toDropdownItemProps';
import { queryClient } from '../../../../services/queryClient';
import {
  createReportService,
  updateReportService,
} from '../../../../services/report';
import { getReportPercentageType } from '../../../../services/reportPercentageType';
import {
  FIELD_ERROR_MESSAGE,
  REPORTS_QUERYKEY,
  REPORT_PERCENTAGE_QUERYKEY,
} from '../../../../settings/constants';
import { ReportFormData } from '../../../../types/report';
import ModalFormGroup from '../ModalFormGroup';

type SlotFormModalProps = ModalProps & {
  isOpen: boolean;
  initialValues?: ReportFormData;
  users?: DropdownItemProps[];
  onRequestClose: () => void;
};

export function ReportFormModal({
  isOpen,
  initialValues,
  onRequestClose,
  ...rest
}: SlotFormModalProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const { tireEvaluationId } = useParams<'tireEvaluationId'>();
  const { control, formState, handleSubmit } = useForm<ReportFormData>({
    ...(initialValues && {
      defaultValues: {
        ...initialValues,
      },
    }),
  });

  const reportPercentage = useQuery(REPORT_PERCENTAGE_QUERYKEY, async () => {
    return toDropdownItemProps(await getReportPercentageType());
  });

  const createSlot = useMutation(
    async (data: ReportFormData) => {
      data.tireEvaluationId = Number(tireEvaluationId);
      return createReportService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(REPORTS_QUERYKEY);
        onRequestClose();
        toast.success('Report created successfully');
      },
      onError: () => {
        toast.error('Error creating report');
      },
    },
  );

  const editSlot = useMutation(
    async (data: ReportFormData) => {
      data.tireEvaluationId = Number(tireEvaluationId);
      return updateReportService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(REPORTS_QUERYKEY);
        onRequestClose();
        toast.success('Report updated successfully');
      },
      onError: () => {
        toast.error('Error updating report');
      },
    },
  );

  const onSubmit = useCallback(
    async (data: ReportFormData) => {
      if (data.id) {
        await editSlot.mutateAsync(data);
      } else {
        await createSlot.mutateAsync(data);
      }
    },
    [createSlot, editSlot],
  );

  return (
    <Modal
      {...rest}
      open={isOpen}
      size="tiny"
      onClose={onRequestClose}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>{I18n.t('title.reportRegistration')}</Modal.Header>

      <Modal.Content scrolling>
        <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="percentageId"
            label={I18n.t('page.reports.label.reportPercentage')}
            options={reportPercentage.data ?? []}
            required
            error={
              formState.errors.percentageId?.type === 'required' &&
              I18n.t(FIELD_ERROR_MESSAGE)
            }
          />

          <ControllerInput
            controllerControl={control}
            controllerName="avgNskMm"
            fieldLabel={I18n.t('page.reports.label.avgNsk')}
            label={{ content: 'mm' }}
            labelPosition="right"
            type="number"
          />

          <Form.Field>
            <Form.Group unstackable>
              <ControllerFormField
                controllerControl={control}
                controllerName="started"
                control={Input}
                type="date"
                label={I18n.t('page.reports.label.startedDate')}
                width="8"
                required
                error={
                  formState.errors.started?.type === 'required' &&
                  I18n.t(FIELD_ERROR_MESSAGE)
                }
              />
              <ControllerFormField
                controllerControl={control}
                controllerName="finished"
                control={Input}
                type="date"
                label={I18n.t('page.reports.label.finishedDate')}
                width="8"
              />
            </Form.Group>
          </Form.Field>

          <ControllerInput
            controllerControl={control}
            controllerName="wornOut"
            fieldLabel={I18n.t('page.reports.label.wornOut')}
            label={{ content: '%' }}
            labelPosition="right"
            type="number"
          />

          <ControllerInput
            controllerControl={control}
            controllerName="pullPoint"
            fieldLabel={I18n.t('page.reports.label.pullPoint')}
            label={{ content: 'mm' }}
            labelPosition="right"
            type="number"
          />

          <ControllerInput
            controllerControl={control}
            controllerName="kmRun"
            type="number"
            fieldLabel={I18n.t('page.reports.label.kmRun')}
            label={{ content: 'KM' }}
            labelPosition="right"
          />

          <ModalFormGroup controlParams={control} />

          <ControllerFormField
            controllerControl={control}
            controllerName="reportDate"
            control={Input}
            type="date"
            label={I18n.t('page.reports.label.reportDate')}
          />

          <ControllerFormField
            controllerControl={control}
            controllerName="cycle"
            label={I18n.t('page.reports.label.lifeStatus')}
            control={Input}
          />

          <ControllerFormField
            controllerControl={control}
            controllerName="retreadIndex"
            label={I18n.t('page.reports.label.retreadIndex')}
            control={Input}
            type="number"
          />

          <ControllerFormField
            controllerControl={control}
            controllerName="dateIssued"
            control={Input}
            type="date"
            label={I18n.t('page.reports.label.dateIssuedTotalCycle')}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <ButtonGDY
          primary
          loading={formState.isSubmitting}
          disabled={formState.isSubmitting}
          onClick={() => {
            formRef.current?.handleSubmit();
          }}
        >
          {I18n.t('page.reports.button.save')}
        </ButtonGDY>
      </Modal.Actions>
    </Modal>
  );
}
