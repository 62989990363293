/* eslint-disable @typescript-eslint/ban-ts-comment */
import I18n from 'i18n-js';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Form } from 'semantic-ui-react';

import { queryClient } from '../../../../../services/queryClient';
import {
  createUserService,
  deleteUserService,
  updateUserService,
} from '../../../../../services/user';
import { USERS_QUERYKEY } from '../../../../../settings/constants';
import { User } from '../../../../../types/user';
import { Table } from './components/Table';

interface IUserFormProps {
  users?: User[];
}

export interface ItemsProps {
  id?: number;
  name: string;
  phone: string;
  email: string;
  isEdit: boolean;
}

export function UserModalContent({ users }: IUserFormProps) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [items, setItems] = useState(
    users?.map(
      (item): ItemsProps => ({
        id: item.id,
        name: item.name,
        phone: item.phone,
        email: item.email,
        isEdit: false,
      }),
    ),
  );

  useEffect(() => {
    setItems(
      users?.map(item => ({
        id: item.id,
        name: item.name,
        phone: item.phone,
        email: item.email,
        isEdit: false,
      })),
    );
  }, [users]);

  const handleAddItem = useMutation(
    async (data: User) => {
      return createUserService(data);
    },
    {
      onSuccess: () => {
        toast.success('Item created successfully');

        if (users) {
          setName('');
          setPhone('');
          setEmail('');
        }

        queryClient.invalidateQueries(USERS_QUERYKEY);
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error('Error creating item');
        }
      },
    },
  );

  const handleUpdateItem = useMutation(
    async (data: any) => {
      return updateUserService(data);
    },
    {
      onSuccess: () => {
        toast.success('Item updated successfully');

        if (users) {
          setName('');
          setPhone('');
          setEmail('');
        }

        queryClient.invalidateQueries(USERS_QUERYKEY);
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error('Error updating item');
        }
      },
    },
  );

  const handleDeleteItem = useMutation(
    async (id: number) => {
      await deleteUserService(id);
    },
    {
      onSuccess: () => {
        toast.success('Item deleted successfully');
        queryClient.invalidateQueries(USERS_QUERYKEY);
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error('This item is already in use');
        }
      },
    },
  );

  return (
    <>
      <Form>
        <Form.Group grouped>
          <Form.Group widths="equal">
            <Form.Input
              label={I18n.t(
                'component.specialEditableDropdown.user.label.name',
              )}
              name="name"
              value={name}
              onChange={e => setName(e.currentTarget.value.toUpperCase())}
              width="16"
            />
          </Form.Group>

          <Form.Group unstackable>
            <InputMask
              mask="(99) 99999-9999"
              maskChar={null}
              value={phone}
              onChange={e => setPhone(e.currentTarget.value)}
            >
              {/* @ts-ignore */}
              {inputMask => (
                <Form.Input
                  {...inputMask}
                  label={I18n.t(
                    'component.specialEditableDropdown.user.label.phone',
                  )}
                  name="phone"
                  width="8"
                />
              )}
            </InputMask>

            <Form.Input
              type="email"
              label={I18n.t(
                'component.specialEditableDropdown.user.label.email',
              )}
              name="email"
              value={email}
              onChange={e => setEmail(e.currentTarget.value.toLowerCase())}
              width="8"
            />
          </Form.Group>
          <Form.Button
            content={I18n.t('component.specialEditableDropdown.button.add')}
            color="green"
            onClick={e => {
              e.preventDefault();
              handleAddItem.mutateAsync({ name, phone, email });
            }}
            disabled={!name || phone.length < 15 || !email}
            fluid
          />
        </Form.Group>
      </Form>
      <Table
        items={items}
        setItems={setItems}
        handleUpdateItem={handleUpdateItem}
        handleDeleteItem={handleDeleteItem}
      />
    </>
  );
}
