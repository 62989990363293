import I18n from 'i18n-js';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbSectionProps,
  Confirm,
  DropdownProps,
  Form,
  Loader,
} from 'semantic-ui-react';

import { ConfirmModal } from '../../components/ConfirmModal';
import { FilterGroup } from '../../components/FilterGroup';
import { Container } from '../../components/index';
import { Page } from '../../components/Page';
import { Toolbar } from '../../components/Toolbar';
import { BASE_URL } from '../../settings/constants';
import { Table } from './components/Table';
import TireDetailsBar from './components/TireDetailsBar';
import { TireEvaluationLabelStatus } from './components/TireEvaluationLabelStatus';
import { useCreateInspectionTasks } from './hooks/useCreateInspectionTasks';
import { useDeleteTireEvaluation } from './hooks/useDeleteTireEvaluation';
import { useFetchProjectById } from './hooks/useFetchProjectById';
import { useTireEvaluationFilterFormRequests } from './hooks/useTireEvaluationFilterFormRequests';
import { useTireEvaluationRequest } from './hooks/useTireEvaluationRequest';
import { Wrapper } from './styles';

export interface FilterParams {
  fleet?: number;
}

type TireEvaluationParams = 'projectPage' | 'projectId' | 'tireEvaluationPage';

const CONFIRM_DELETE_CANCEL = 'confirm.delete.cancel';
const TIRE_EVALUATION_PAGE = '@tireEvaluationPage';

export default function TireEvaluations() {
  const [showTireDetailsBar, setShowTireDetailsBar] = useState<boolean>(false);

  const [page, setPage] = useState(
    Number(sessionStorage?.getItem(TIRE_EVALUATION_PAGE)) || 1,
  );

  const { projectPage, projectId, tireEvaluationPage } =
    useParams<TireEvaluationParams>();

  useEffect(() => {
    sessionStorage.setItem(TIRE_EVALUATION_PAGE, String(page) || '1');
    setPage(Number(tireEvaluationPage));
  }, [page, tireEvaluationPage]);
  const { project, isProjectLoading } = useFetchProjectById(Number(projectId));

  const navigate = useNavigate();
  const location = useLocation();

  const sections: BreadcrumbSectionProps[] | undefined = [
    {
      key: 'projects',
      content: I18n.t('title.projects'),
      link: true,
      onClick: () => navigate(`${BASE_URL}projects/${projectPage}`),
    },
    {
      key: 'tireEvaluation',
      content: (
        <>
          <span>{project?.folder}</span>
          <TireEvaluationLabelStatus
            isProjectLoading={isProjectLoading}
            project={project}
          />
        </>
      ),
      active: true,
    },
  ];

  const [query, setQuery] = useState<FilterParams>({
    fleet: undefined,
  });

  const [filterParams, setFilterParams] = useState<FilterParams>({
    fleet: undefined,
  });

  const [isConfirmationDeleteModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);

  const [
    isInspectionTasksConfirmationModalOpen,
    setInspectionTasksConfirmationModalOpen,
  ] = useState(false);

  const [selectedTireEvaluation, setSelectedTireEvaluation] =
    useState<number>();

  const { tireEvaluations, isTireEvaluationsLoading } =
    useTireEvaluationRequest(Number(projectId), page, query);

  const { fleetOptions, isFleetOptionsLoading } =
    useTireEvaluationFilterFormRequests();

  function handleInspectionTasksConfirmationModalClose() {
    setSelectedTireEvaluation(undefined);
    setInspectionTasksConfirmationModalOpen(false);
  }

  function handleConfirmationDeleteModalOpen(tireEvaluationId: number) {
    setSelectedTireEvaluation(tireEvaluationId);
    setDeleteConfirmationModalOpen(true);
  }

  function handleDeleteConfirmationModalClose() {
    setSelectedTireEvaluation(undefined);
    setDeleteConfirmationModalOpen(false);
  }

  const handleDeleteTireEvaluation = useDeleteTireEvaluation(
    handleDeleteConfirmationModalClose,
  );

  const handleCreateInspectionTasks = useCreateInspectionTasks({
    handleInspectionTasksConfirmationModalClose,
  });

  function handleTireEvaluationChange(
    _: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) {
    setFilterParams({
      ...filterParams,
      fleet: data.value as number,
    });
  }

  function handleClickApplyFilter() {
    setQuery({
      ...filterParams,
    });
    setPage(1);
  }

  return (
    <Page title="Tire Evaluations">
      <Container>
        <div className="page__title">
          {I18n.t('title.tireEvaluation')}
          <div>
            <Breadcrumb
              icon="right angle"
              sections={sections as any}
              size="small"
            />
          </div>
        </div>

        <Toolbar
          onAddClick={() => {
            navigate(`${location.pathname}/new`, {
              state: {
                backgroundLocation: location,
              },
            });
          }}
          onApplyClick={handleClickApplyFilter}
          enableTireDetailsButton
          showTireDetailsBar={showTireDetailsBar}
          setShowTireDetailsBar={setShowTireDetailsBar}
          disableNewButton={['FINISHED', 'ON HOLD'].includes(
            project?.projectStatus.label ?? '',
          )}
        >
          <FilterGroup>
            <Form.Dropdown
              search
              clearable
              selection
              label={I18n.t('page.tireEvaluations.label.fleet')}
              value={filterParams?.fleet ?? ''}
              onChange={handleTireEvaluationChange}
              options={fleetOptions ?? []}
              loading={isFleetOptionsLoading}
              fluid
            />
          </FilterGroup>
        </Toolbar>

        <Wrapper className="dash_and_table_wrapper">
          <TireDetailsBar
            show={showTireDetailsBar}
            totalTires={project?.totalTires}
            projectId={projectId}
          />
          {isTireEvaluationsLoading ? (
            <Loader size="large" active />
          ) : (
            <Table
              tireEvaluations={tireEvaluations}
              page={page}
              setPage={setPage}
              handleTireEvaluationDeleteModalOpen={
                handleConfirmationDeleteModalOpen
              }
            />
          )}
        </Wrapper>
      </Container>

      {isConfirmationDeleteModalOpen && selectedTireEvaluation && (
        <Confirm
          header={I18n.t('confirm.delete.title')}
          content={I18n.t('confirm.delete.message')}
          open={isConfirmationDeleteModalOpen}
          cancelButton={{
            content: I18n.t('confirm.delete.cancel'),
          }}
          confirmButton={{
            content: I18n.t('confirm.delete.confirm'),
            negative: true,
            icon: 'trash',
            labelPosition: 'left',
          }}
          onCancel={handleDeleteConfirmationModalClose}
          onConfirm={() => {
            handleDeleteTireEvaluation.mutateAsync(selectedTireEvaluation);
          }}
          size="mini"
        />
      )}

      {isInspectionTasksConfirmationModalOpen && selectedTireEvaluation && (
        <ConfirmModal
          header="Generate Inspection Tasks"
          content="Are you sure you want to generate inspection tasks?"
          cancelBtnContent={I18n.t(CONFIRM_DELETE_CANCEL)}
          confirmBtnContent="Generate"
          open={isInspectionTasksConfirmationModalOpen}
          onCancel={handleInspectionTasksConfirmationModalClose}
          onConfirm={() => {
            handleCreateInspectionTasks.mutateAsync(selectedTireEvaluation);
          }}
        />
      )}
    </Page>
  );
}
