import styled from 'styled-components';

interface IProps {
  isCollapsed: boolean;
}

export const Container = styled.div<IProps>`
  width: 100%;
  margin: 20px 0;

  display: flex;
  flex-direction: column;

  .chartsbox__title {
    background-color: #fff;
    color: #333;
    font-size: 1.8em;
    font-weight: bold;

    padding: 8px 16px;
    margin-bottom: ${({ isCollapsed }) => (isCollapsed ? '20px' : '0')};

    border-radius: 5px;
    box-shadow: 0 5px 3px -3px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    gap: calc(20px - 3.5px);
  }

  .chartsbox__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
`;
