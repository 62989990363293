import I18n from 'i18n-js';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { CheckboxProps, Form, Input, Table } from 'semantic-ui-react';

import { getRecappingService } from '../../../../../../services/recappingService';
import { GenericObject } from '../../../../../../types/fleet';

export function InputRecapping() {
  const { control, setValue } = useFormContext();

  function handleSetValueRecappingId(index: number, item: GenericObject) {
    setValue(`tireRecap.fleetRecapping.${index}.recapping.id`, item.id);
  }

  const { data: tireRecapping } = useQuery(
    'tire_recapping',
    getRecappingService,
    {},
  );

  const sucataValue = useWatch({
    control,
    name: `tireRecap.fleetRecapping.3.recapping.id`,
  });

  function handleSucataChange(data: CheckboxProps) {
    if (data.checked) {
      setValue('tireRecap.fleetRecapping.3.recapping.id', 4);
    } else {
      setValue('tireRecap.fleetRecapping.3.recapping.id', undefined);
    }
  }

  return (
    <Form.Field>
      <label>{I18n.t('page.fleets.label.tireRecap.retreadLifes')}</label>
      <Table
        style={{ marginTop: 0 }}
        celled
        striped
        compact
        unstackable
        size="small"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Life" />
            <Table.HeaderCell content="Groove" />
            <Table.HeaderCell content="Total KM bands" />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tireRecapping?.map((item: GenericObject, index) =>
            item.label !== 'SUCATA' ? (
              <Table.Row key={item.id}>
                <Table.Cell width={2}>
                  <label>{item.label}</label>
                </Table.Cell>
                <Table.Cell width={6}>
                  <Controller
                    control={control}
                    name={`tireRecap.fleetRecapping.${index}.grooveMM`}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Input
                        label="mm"
                        labelPosition="right"
                        value={value}
                        onChange={(_, data) => {
                          onChange(data.value.replace(',', '.'));
                          handleSetValueRecappingId(index, item);
                        }}
                        onBlur={onBlur}
                        size="small"
                        fluid
                      />
                    )}
                  />
                </Table.Cell>
                <Table.Cell width={6}>
                  <Controller
                    control={control}
                    name={`tireRecap.fleetRecapping.${index}.brandTotalKM`}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Form.Input
                        value={value}
                        onChange={(_, data) => {
                          onChange(data.value);
                          handleSetValueRecappingId(index, item);
                        }}
                        onBlur={onBlur}
                        size="small"
                        fluid
                      />
                    )}
                  />
                </Table.Cell>
              </Table.Row>
            ) : (
              <Table.Row>
                <Table.Cell fluid>
                  <label>{item.label}</label>
                </Table.Cell>
                <Table.Cell colSpan="2">
                  <Form.Checkbox
                    checked={sucataValue}
                    onChange={(_, data) => handleSucataChange(data)}
                  />
                </Table.Cell>
              </Table.Row>
            ),
          )}
        </Table.Body>
      </Table>
    </Form.Field>
  );
}
