import I18n from 'i18n-js';
import { Button, Dropdown, Icon, Popup, Table } from 'semantic-ui-react';

import useIsMobileScreen from '../../../../../../hooks/useIsMobileScreen';
import { Project } from '../../../../../../types/project';

interface Props {
  project: Project;
  handleProjectFormModalOpen(projectId: number): void;
  handleProjectDeleteModalOpen(projectId: number): void;
  handleInspAndRepConfirmationModalOpen(projectId: number): void;
}

export function TableColumnActions({
  project,
  handleProjectFormModalOpen,
  handleProjectDeleteModalOpen,
  handleInspAndRepConfirmationModalOpen,
}: Props) {
  const { isMobileScreen } = useIsMobileScreen(460);

  return (
    <Table.Cell className="td action">
      <Dropdown
        pointing="right"
        trigger={<Icon name="ellipsis vertical" size="large" />}
        icon={null}
      >
        <Dropdown.Menu>
          <span className="action-buttons">
            <Popup
              disabled={isMobileScreen}
              content={I18n.t('page.projects.tooltip.action.edit')}
              trigger={
                <Button
                  icon="pencil"
                  size="mini"
                  color="yellow"
                  onClick={() => {
                    handleProjectFormModalOpen(project.id);
                  }}
                />
              }
            />

            <Popup
              disabled={isMobileScreen}
              content={I18n.t('page.projects.tooltip.action.delete')}
              trigger={
                <Button
                  icon="trash"
                  size="mini"
                  color="red"
                  onClick={() => handleProjectDeleteModalOpen(project.id)}
                />
              }
            />

            <Popup
              disabled={isMobileScreen}
              content={
                project.projectStatus?.label === 'UNDER DISCUSSION'
                  ? I18n.t(
                      'page.projects.tooltip.action.generateInspectionTasks.underDiscussion',
                    )
                  : I18n.t(
                      'page.projects.tooltip.action.generateInspectionTasks.noUnderDiscussion',
                    )
              }
              trigger={
                <span>
                  <Button
                    icon="play"
                    size="mini"
                    onClick={() =>
                      handleInspAndRepConfirmationModalOpen(project.id)
                    }
                    disabled={
                      project.projectStatus?.label !== 'UNDER DISCUSSION'
                    }
                  />
                </span>
              }
            />
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </Table.Cell>
  );
}
