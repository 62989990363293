import { Configuration } from '@azure/msal-browser';

import { ENV } from '../types/env';

export const msalConfig: Configuration = {
  auth: {
    clientId: ENV.REACT_APP_CLIENT_ID,
    redirectUri: ENV.REACT_APP_REDIRECT_URI,
    authority:
      'https://login.microsoftonline.com/939e8966-9285-4a9a-9f04-0887efe8aae0/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['user.read'],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
