import I18n from 'i18n-js';
import { useQuery } from 'react-query';

import GoodyearLogoImg from '../../assets/img/goodyear-logo.png';
import BackgroundImg from '../../assets/img/login-background_tire.png';
import { Page } from '../../components/Page';
import api from '../../services/api';
import { SignInButton } from './components/SignInButton';
import {
  AuthenticationContent,
  Background,
  Container,
  Content,
} from './styles';

function Login() {
  const { data: photo, isLoading } = useQuery('photo', async () => {
    const response = await api.get('file/find-background-images');

    return response.data;
  });

  return (
    <Page title="Login">
      <Container>
        <Content>
          <img
            className="login__goodyear-logo"
            src={GoodyearLogoImg}
            alt="Goodyear"
            draggable={false}
          />

          <AuthenticationContent>
            <h1>{I18n.t('application.name')}</h1>

            <p>{I18n.t('page.login.content.credentialsAccess')}</p>

            <SignInButton />
          </AuthenticationContent>
        </Content>

        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'rgb(0 0 0 / 36%)',
            zIndex: '-1',
          }}
        />

        {isLoading ? (
          <Background src={BackgroundImg} alt="Background" draggable={false} />
        ) : (
          <Background
            src={
              photo
                ? `data:image/png;base64,${photo?.byteArray}`
                : BackgroundImg
            }
            style={{
              filter: 'grayscale(50%)',
              zIndex: -2,
            }}
            alt="Background"
            draggable={false}
          />
        )}
      </Container>
    </Page>
  );
}

export default Login;
