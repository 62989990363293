import api from './api';

export interface ITotalTiresPerFleet {
  fleet: string;
  totalTires: number;
}

export async function getTotalTiresPerFleetsService(
  id?: number,
): Promise<ITotalTiresPerFleet[]> {
  const response = await api.get(`/projects/${id}/fleets/tires/`);
  return response.data;
}
