import fileDownload from 'js-file-download';

import { getFileFormData } from '../utils/toFormData';
import api from './api';

export interface IFileQueryParamsProps {
  byteArrayIncluded?: boolean;
  fileName?: string;
  path?: string;
}

export async function getFileService(params?: IFileQueryParamsProps) {
  const response = await api.get('/file/find', {
    params: {
      ...params,
    },
  });

  return response.data;
}

export async function uploadFileService(file: File, path: string) {
  const fileFormData = getFileFormData(file, path);

  const response = await api.post('/file/upload', fileFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
}

export async function downloadFileService(fileName: string, path: string) {
  const { data } = await api.get('/file/download', {
    responseType: 'arraybuffer',
    params: {
      path,
      byteArrayIncluded: true,
      fileName,
    },
  });

  fileDownload(data, fileName);
}

export async function deleteFileService(fileName: string, path: string) {
  await api.delete(`/file/delete`, {
    params: {
      path,
      fileName,
    },
  });
}
