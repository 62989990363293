import { TestType } from '../types/project';
import api from './api';

const TEST_TYPES = '/testTypes';

export async function getTestTypesService(): Promise<TestType[]> {
  const response = await api.get(`${TEST_TYPES}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createTestTypesService(
  item: TestType,
): Promise<TestType> {
  const response = await api.post(`${TEST_TYPES}/`, item);

  return response.data;
}

export async function updateTestTypesService(
  item: TestType,
): Promise<TestType> {
  const response = await api.put(`${TEST_TYPES}/`, item);

  console.log(item);

  return response.data;
}

export async function deleteTestTypesService(id: number) {
  await api.delete(`${TEST_TYPES}/${id}`);
}
