import { Helmet } from 'react-helmet';

type PageProps = {
  title: string;
};

export function Page({ title, children }: React.PropsWithChildren<PageProps>) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
}
