import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;

  box-shadow: 0 5px 3px -3px rgba(0, 0, 0, 0.1);

  display: flex;
  flex: 1;
  flex-direction: column;

  .minichartbox__title {
    font-size: 18px;
    line-height: 18px;
    padding: 16px;

    border-bottom: 2px solid #f2f2f2;

    font-weight: bold;
    color: #9f9f9f;
  }

  .minichartbox__content {
    height: 100%;
    min-width: 500px;
    max-width: 650px;
    padding: 16px;
    color: ${({ color }) => color ?? '#333'};

    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1420px) {
    .minichartbox__content {
      max-width: auto;
    }
  }
`;
