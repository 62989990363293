import I18n from 'i18n-js';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Confirm, Form } from 'semantic-ui-react';

import { queryClient } from '../../../../services/queryClient';
import { updateScheduleTaskService } from '../../../../services/schedule';
import { SCHEDULE_TASKS } from '../../../../settings/constants';
import { ScheduleActivityFormData } from '../../../../types/schedule';

interface IProps {
  data?: ScheduleActivityFormData;

  isUpdateMoreReports: boolean;
  setIsUpdateMoreReports: React.Dispatch<React.SetStateAction<boolean>>;

  setNewStartDate?: React.Dispatch<React.SetStateAction<string | undefined>>;

  isConfirmationModalOpen: boolean;
  handleConfirmationModalClose(): void;

  onScheduleFormModalClose?: () => void;
}

export function UploadTasksConfirmModal({
  data,

  setNewStartDate,

  isUpdateMoreReports,
  setIsUpdateMoreReports,

  isConfirmationModalOpen,
  handleConfirmationModalClose,

  onScheduleFormModalClose,
}: IProps) {
  const updateScheduleTaskChangeDate = useMutation(
    async () => {
      return updateScheduleTaskService({ ...data }, isUpdateMoreReports);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SCHEDULE_TASKS);

        if (setNewStartDate) {
          setNewStartDate(String(data?.initialDate));
        }

        handleConfirmationModalClose();

        toast.success('Task updated successfully');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error?.message);
      },
    },
  );

  return (
    <Confirm
      header="Upload Tasks"
      content={
        <div style={{ padding: '20px' }}>
          <p>{I18n.t('page.schedule.uploadReportTasks.message')}</p>
          <Form>
            <Form.Radio
              label={I18n.t('page.schedule.uploadReportTasks.yes')}
              checked={isUpdateMoreReports}
              onChange={() => setIsUpdateMoreReports(true)}
            />
            <Form.Radio
              label={I18n.t('page.schedule.uploadReportTasks.no')}
              checked={!isUpdateMoreReports}
              onChange={() => setIsUpdateMoreReports(false)}
            />
          </Form>
        </div>
      }
      open={isConfirmationModalOpen}
      cancelButton={{
        content: I18n.t('page.schedule.uploadReportTasks.cancel'),
      }}
      confirmButton={{
        content: I18n.t('page.schedule.uploadReportTasks.confirm'),
        positive: true,
      }}
      onCancel={handleConfirmationModalClose}
      onConfirm={() => {
        updateScheduleTaskChangeDate.mutateAsync();
        if (onScheduleFormModalClose) {
          onScheduleFormModalClose();
        }
      }}
      size="mini"
    />
  );
}
