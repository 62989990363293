import I18n from 'i18n-js';

import { DashboardOverview } from '../..';
import { backgroundAnalogousColors } from '../../../../settings/constants';
import { ProjectsOverviewDataProps } from '../../../../types/dashboard';
import { DoughnutChart } from '../Charts';
import { BasicHorBarChart } from '../Charts/BasicHorBarChart';
import { StackedBarChart } from '../Charts/StackedBarChart';
import { ChartsBox } from '../ChartsBox';

interface IProps {
  toggleOpen(value: DashboardOverview): void;
  dataChart: ProjectsOverviewDataProps;
}

export function ProjectsOverview({ toggleOpen, dataChart }: IProps) {
  return (
    <ChartsBox
      overviewName="projects"
      toggleOpen={toggleOpen}
      title={I18n.t('page.dashboard.projects.title')}
    >
      <DoughnutChart
        title={I18n.t('page.dashboard.projects.chart.projectsPerTestType')}
        data={dataChart.projectsPerTestType}
        isLoading={dataChart.isLoadingProjectsPerTestType}
        backgroundColor={backgroundAnalogousColors}
      />

      <BasicHorBarChart
        title={I18n.t('page.dashboard.projects.chart.projectsPerSegment')}
        data={dataChart.projectsPerSegment}
        isLoading={dataChart.isLoadingProjectsPerSegment}
      />

      <BasicHorBarChart
        title={I18n.t('page.dashboard.projects.chart.projectsPerEngineer')}
        data={dataChart.projectsPerEngineer}
        isLoading={dataChart.isLoadingProjectsPerEngineer}
      />

      <StackedBarChart
        title={I18n.t(
          'page.dashboard.projects.chart.projectsPerSegmentAndEngineer',
        )}
        data={dataChart.projectsPerSegmentAndEngineers}
        isLoading={dataChart.isLoadingProjectsPerSegmentAndEngineers}
      />

      <StackedBarChart
        title={I18n.t(
          'page.dashboard.projects.chart.projectsPerStatusAndEngineer',
        )}
        data={dataChart.projectsPerStatusAndEngineer}
        isLoading={dataChart.isLoadingProjectsPerStatusAndEngineer}
      />

      <StackedBarChart
        title={I18n.t(
          'page.dashboard.projects.chart.projectsPerTestTypeAndReport',
        )}
        data={dataChart.projectPerTestTypeAndReport}
        isLoading={dataChart.IsLoadingProjPerTestTypeAndReport}
      />

      <StackedBarChart
        title={I18n.t(
          'page.dashboard.projects.chart.projectsPerTestTypeAndEngineer',
        )}
        data={dataChart.projectsPerTestTypeAndEngineer}
        isLoading={dataChart.isLoadingProjectsPerTestTypeAndEngineer}
      />
    </ChartsBox>
  );
}
