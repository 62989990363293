import { useQuery } from 'react-query';

import { IsOpenOverviewProps } from '..';

import {
  getSchedulePerBusiness,
  getSchedulePerTaskType,
  getSchedulePerTask,
  getSchedulePerPriority,
  getSchedulePerStatus,
  getSchedulePerProject,
  getSchedulePerFleet,
  getSchedulePerResponsible,
  getScheduleTasksPerStatusAndEngineerService,
} from '../../../services/dashboard';
import { ScheduleOverviewDataProps } from '../../../types/dashboard';

export default function useScheduleChartData(
  isOpenOverview: IsOpenOverviewProps,
): ScheduleOverviewDataProps {
  const { data: schedulePerBusiness, isLoading: isLoadingSchedulePerBusiness } =
    useQuery('chart_schedule_per_business', getSchedulePerBusiness, {
      enabled: isOpenOverview.schedule,
    });

  const { data: schedulePerTaskType, isLoading: isLoadingSchedulePerTaskType } =
    useQuery('chart_schedule_per_task_type', getSchedulePerTaskType, {
      enabled: isOpenOverview.schedule,
    });

  const { data: schedulePerTask, isLoading: isLoadingSchedulePerTask } =
    useQuery('chart_schedule_per_task', getSchedulePerTask, {
      enabled: isOpenOverview.schedule,
    });

  const { data: schedulePerPriority, isLoading: isLoadingSchedulePerPriority } =
    useQuery('chart_schedule_per_priority', getSchedulePerPriority, {
      enabled: isOpenOverview.schedule,
    });

  const { data: schedulePerStatus, isLoading: isLoadingSchedulePerStatus } =
    useQuery('chart_schedule_per_status', getSchedulePerStatus, {
      enabled: isOpenOverview.schedule,
    });

  const { data: schedulePerProject, isLoading: isLoadingSchedulePerProject } =
    useQuery('chart_schedule_per_project', getSchedulePerProject, {
      enabled: isOpenOverview.schedule,
    });

  const { data: schedulePerFleet, isLoading: isLoadingSchedulePerFleet } =
    useQuery('chart_schedule_per_fleet', getSchedulePerFleet, {
      enabled: isOpenOverview.schedule,
    });

  const {
    data: schedulePerResponsible,
    isLoading: isLoadingSchedulePerResponsible,
  } = useQuery('chart_schedule_per_responsible', getSchedulePerResponsible, {
    enabled: isOpenOverview.schedule,
  });

  const {
    data: schedulePerStatusAndEngineer,
    isLoading: isLoadingSchedulePerStatusAndEngineer,
  } = useQuery(
    'chart_schedule_per_status_and_engineer',
    getScheduleTasksPerStatusAndEngineerService,
    {
      enabled: isOpenOverview.schedule,
    },
  );

  return {
    schedulePerBusiness,
    isLoadingSchedulePerBusiness,

    schedulePerTaskType,
    isLoadingSchedulePerTaskType,

    schedulePerTask,
    isLoadingSchedulePerTask,

    schedulePerPriority,
    isLoadingSchedulePerPriority,

    schedulePerStatus,
    isLoadingSchedulePerStatus,

    schedulePerProject,
    isLoadingSchedulePerProject,

    schedulePerFleet,
    isLoadingSchedulePerFleet,

    schedulePerResponsible,
    isLoadingSchedulePerResponsible,

    schedulePerStatusAndEngineer,
    isLoadingSchedulePerStatusAndEngineer,
  };
}
