import { GenericObject } from '../types/fleet';
import api from './api';

const SERVICE = '/service';

export async function getServiceTypeService(): Promise<GenericObject[]> {
  const response = await api.get(`${SERVICE}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createServiceTypeService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${SERVICE}/`, item);

  return response.data;
}

export async function updateServiceTypeService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${SERVICE}/`, item);

  return response.data;
}

export async function deleteServiceTypeService(id: number) {
  await api.delete(`${SERVICE}/${id}`);
}
