import { GenericObject } from '../types/fleet';
import api from './api';

const FLEET_TYPE = '/typeFleet';

export async function getFleetTypeService(): Promise<GenericObject[]> {
  const response = await api.get(`${FLEET_TYPE}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createFleetTypeService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${FLEET_TYPE}/`, item);

  return response.data;
}

export async function updateFleetTypeService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${FLEET_TYPE}/`, {
    id: item.id,
    label: item.label,
  });

  return response.data;
}

export async function deleteFleetTypeService(id: number) {
  await api.delete(`${FLEET_TYPE}/${id}`);
}
