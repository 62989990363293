import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 5px 3px -3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .filter-container {
    padding-top: 32px;

    .filter-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      margin-bottom: 16px;
    }

    .filter-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
    }
  }
`;

interface IFilterbarProps {
  show: boolean;
}

export const Filterbar = styled.div<IFilterbarProps>`
  background-color: #fff;
  width: 250px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 193px;
  right: ${({ show }) => (show ? '60px' : '-250px')};
  transition: ${({ theme }) => theme.transitions.fast};
  z-index: 2;

  form {
    max-height: 60vh;
    display: flex;
    flex-direction: column;

    .form-header {
      padding: 14px;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      display: block;

      img {
        display: none;
      }

      i {
        display: none;
      }
    }

    .form-body {
      height: 40vh;
      padding: 14px;
      display: block;
      overflow: hidden auto;

      ::-webkit-scrollbar {
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background: none;
      }
    }

    .form-footer {
      padding: 14px;
      border-top: 1px solid rgba(34, 36, 38, 0.15);
      display: block;

      .clear {
        color: #4183c4;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 460px) {
    height: 100%;
    width: 100vw;
    margin-top: 0;
    padding: 0;
    border-radius: 0;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;

    form {
      min-height: none;
      height: 100vh;
      max-height: none;
      position: relative;
      top: 0;
      bottom: 0;

      .form-header {
        background-color: #004ea8;
        color: #fff;
        height: 56px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          display: block;
        }

        i {
          display: block;
          color: #feda00;
        }
      }
      .form-body {
        height: 100%;
        padding: 14px;
        flex: 1;
      }
      .form-footer {
        padding: 14px;
      }
    }
  }

  .filterbar {
    height: 100%;
    width: 100px;
    position: relative;
    right: -20px;
  }
`;
