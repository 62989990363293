import { format } from 'date-fns';
import I18n from 'i18n-js';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Form, Icon, Input, Modal, ModalProps, Table } from 'semantic-ui-react';

import { getScheduleHolidayListService } from '../../../../services/schedule';
import { DATE_FORMAT } from '../../../../settings/constants';
import { HolidayItemProps } from '../../../../types/schedule';
import { useHandleCreateHoliday } from './hooks/useHandleCreateHoliday';
import { useHandleDeleteHoliday } from './hooks/useHandleDeleteHoliday';
import { useHandleUpdateHoliday } from './hooks/useHandleUpdateHoliday';

interface HolidayModalProps extends ModalProps {
  isModalOpen: boolean;
  onRequestClose: () => void;
}

interface HolidayStateItemProps extends HolidayItemProps {
  isEdit: boolean;
}

export function HolidayModal({
  isModalOpen,
  onRequestClose,
  ...rest
}: HolidayModalProps) {
  const [holiday, setHoliday] = useState({
    holidayDate: '',
    description: '',
  });
  const [holidayList, setHolidayList] = useState<HolidayStateItemProps[]>([]);

  const { data } = useQuery('HOLIDAY_LIST_MANAGEMENT', async () => {
    const response = await getScheduleHolidayListService();

    return response.map(item => ({
      ...item,
      isEdit: false,
    }));
  });

  useEffect(() => {
    setHolidayList(() => {
      if (data) {
        return data?.map(item => ({
          ...item,
          holidayDate: format(new Date(item.holidayDate), DATE_FORMAT),
          isEdit: false,
        }));
      }

      return [];
    });
  }, [data]);

  const { handleCreateHoliday } = useHandleCreateHoliday();

  const { handleUpdateHoliday } = useHandleUpdateHoliday();

  const { handleDeleteHoliday } = useHandleDeleteHoliday();

  function handleClickEditItem(id: number) {
    const index = holidayList?.findIndex(item => item?.id === id);

    const updatingItems = [...holidayList];

    updatingItems[index].isEdit = !holidayList[index].isEdit;

    setHolidayList([...updatingItems]);
  }

  function handleChangeDescription(id: number, value: string) {
    const index = holidayList?.findIndex(item => item?.id === id);

    const updatingItems = [...holidayList];

    updatingItems[index].description = value.toUpperCase();

    setHolidayList([...updatingItems]);
  }

  function handleChangeDate(id: number, value: string) {
    const index = holidayList?.findIndex(item => item?.id === id);

    const updatingItems = [...holidayList];

    updatingItems[index].holidayDate = value;

    setHolidayList([...updatingItems]);
  }

  function handleClickCheckItem(id: number) {
    const index = holidayList?.findIndex(item => item?.id === id);

    const holidayData: HolidayItemProps = {
      id: holidayList[index].id,
      holidayDate: holidayList[index].holidayDate,
      description: holidayList[index].description,
    };

    handleClickEditItem(id);

    handleUpdateHoliday.mutateAsync(holidayData);
  }

  return (
    <Modal
      {...rest}
      open={isModalOpen}
      size="tiny"
      onClose={onRequestClose}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>{I18n.t('title.holidayRegistration')}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group unstackable widths="equal">
            <Form.Input
              label={I18n.t('page.schedule.modal.holiday.date')}
              type="date"
              value={holiday.holidayDate}
              onChange={(_, { value }) =>
                setHoliday(prev => ({
                  ...prev,
                  holidayDate: value,
                }))
              }
              width="5"
            />
            <Form.Input
              label={I18n.t('page.schedule.modal.holiday.description')}
              value={holiday.description}
              onChange={(_, { value }) =>
                setHoliday(prev => ({
                  ...prev,
                  description: value,
                }))
              }
              width="11"
            />
          </Form.Group>
          <Form.Button
            type="button"
            color="green"
            content="ADD"
            disabled={
              !holiday.holidayDate ||
              !holiday.description ||
              handleCreateHoliday.isLoading
            }
            onClick={() => {
              handleCreateHoliday.mutateAsync(holiday);
              setHoliday({ holidayDate: '', description: '' });
            }}
            loading={handleCreateHoliday.isLoading}
            fluid
          />
        </Form>

        <Table unstackable compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                content={I18n.t('page.schedule.modal.holiday.date')}
                width="5"
              />
              <Table.HeaderCell
                content={I18n.t('page.schedule.modal.holiday.description')}
                colSpan="4"
              />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {holidayList?.map(({ id, holidayDate, description, isEdit }) => (
              <Table.Row key={id}>
                {isEdit ? (
                  <Table.Cell>
                    <Input
                      type="date"
                      value={holidayDate}
                      onChange={(_, { value }) => handleChangeDate(id, value)}
                      fluid
                    />
                  </Table.Cell>
                ) : (
                  <Table.Cell content={holidayDate?.split('T')[0]} />
                )}

                {isEdit ? (
                  <Table.Cell>
                    <Input
                      value={description}
                      onChange={(_, { value }) =>
                        handleChangeDescription(id, value)
                      }
                      fluid
                    />
                  </Table.Cell>
                ) : (
                  <Table.Cell content={description} />
                )}

                {isEdit ? (
                  <Table.Cell width="1">
                    <Icon
                      link
                      name="check"
                      color="green"
                      onClick={() => handleClickCheckItem(id)}
                    />
                  </Table.Cell>
                ) : (
                  <Table.Cell />
                )}
                <Table.Cell width="1">
                  <Icon
                    link
                    name="pencil"
                    color={isEdit ? undefined : 'yellow'}
                    onClick={() => handleClickEditItem(id)}
                    disabled={isEdit}
                  />
                </Table.Cell>
                <Table.Cell width="1">
                  <Icon
                    link
                    name="trash"
                    color={isEdit ? undefined : 'red'}
                    onClick={() => handleDeleteHoliday.mutateAsync(id)}
                    disabled={isEdit}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
}
