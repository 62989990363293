import { StrictLabelProps } from 'semantic-ui-react';

import { Container } from './styles';

interface IProps extends StrictLabelProps {
  children: React.ReactNode;
}

export function Label({ children, ...rest }: IProps) {
  return (
    <Container pointing {...rest}>
      {children}
    </Container>
  );
}
