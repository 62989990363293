import fileDownload from 'js-file-download';

import { ApiSearchProps, SearchResponseDataProps } from '../types/search';
import { getDateFormat, getDateFormatView } from '../utils/getDateFormat';
import api from './api';

export async function getItemsSearchListService({
  page = 0,
  size = 10,
  percentageIds,
  brands,
  fleetIds,
  folders,
  models,
  modifications,
  responsibleIds,
  servicesIds,
  sizeIds,
  sort,
  typeFleetIds,
}: ApiSearchProps): Promise<SearchResponseDataProps> {
  const params = {
    percentageIds,
    page,
    size,
    brands: brands?.join(','),
    fleetIds: fleetIds?.join(','),
    folders: folders?.join(','),
    models: models?.join(','),
    modifications: modifications?.join(','),
    responsibleIds: responsibleIds?.join(','),
    servicesIds: servicesIds?.join(','),
    sizeIds: sizeIds?.join(','),
    typeFleetIds: typeFleetIds?.join(','),
    sort,
  };

  const response = await api.get<SearchResponseDataProps>(`/search`, {
    params,
  });

  return {
    ...response.data,
    _embedded: {
      ...response.data._embedded,
      tireEvaluations: response.data._embedded.tireEvaluations?.map(
        tireEvaluation => ({
          ...tireEvaluation,
          reports: tireEvaluation.reports?.map(report => ({
            ...report,
            started: getDateFormat(report.started),
            finished: getDateFormat(report.finished),
            dateIssued: getDateFormat(report.dateIssued),
            reportDate: getDateFormat(report.reportDate),
          })),
        }),
      ),
    },
  };
}

interface IProps {
  params: ApiSearchProps;
}

export async function searchExcelDownload({ params }: IProps) {
  api
    .get(`/search/download`, {
      responseType: 'blob',
      params: {
        percentageIds: params.percentageIds,
        brands: params.brands?.join(','),
        fleetIds: params.fleetIds?.join(','),
        folders: params.folders?.join(','),
        models: params.models?.join(','),
        modifications: params.modifications?.join(','),
        responsibleIds: params.responsibleIds?.join(','),
        servicesIds: params.servicesIds?.join(','),
        sizeIds: params.sizeIds?.join(','),
        typeFleetIds: params.typeFleetIds?.join(','),

        page: undefined,
        size: undefined,
        sort: undefined,
      },
    })
    .then(response => {
      fileDownload(
        response.data,
        `search-${getDateFormatView(String(new Date()))}.xlsx`,
      );
    });
}
