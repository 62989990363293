import styled from 'styled-components';

export const Container = styled.div`
  .category {
    background-color: #004ea8;
    color: #feda00 !important;
    text-align: center;
    font-weight: 600 !important;
    padding: 8px 16px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      display: flex;
      align-items: center;
    }
  }
`;
