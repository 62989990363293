import { EventApi } from '@fullcalendar/react';
import { Label, Popup } from 'semantic-ui-react';

import useIsMobileScreen from '../../../../hooks/useIsMobileScreen';
import { getSemanticColor } from '../../../../utils/getSemanticColor';

interface Props {
  event: EventApi;
  selectedUser?: string[];
}

export function EventContent({ event, selectedUser }: Props) {
  const { isMobileScreen } = useIsMobileScreen(460);

  const isVacation = event.extendedProps?.isVacation;
  const isHoliday = event.extendedProps?.isHoliday;
  const isDayOff = event.extendedProps?.isDayOff;
  const isAdministration = event.extendedProps?.isAdministration;

  return (
    <Popup
      disabled={isMobileScreen || isVacation || isHoliday || isDayOff}
      content={
        <span>
          {!isAdministration && (
            <div>Priority: {event.extendedProps?.priority}</div>
          )}
          <div>Status: {event.extendedProps?.status}</div>
        </span>
      }
      inverted
      size="small"
      position="top center"
      trigger={
        <span>
          <div className="fc-event-title">
            <div className="fc-event-labels">
              {event.extendedProps.priority && (
                <Label
                  className="fc-event-priority"
                  circular
                  floating
                  color={getSemanticColor(event.extendedProps?.priority)}
                  size="mini"
                >
                  <span>{event.extendedProps?.priority}</span>
                </Label>
              )}

              {event.extendedProps.status && (
                <Label
                  className="fc-event-status"
                  circular
                  floating
                  color={getSemanticColor(event.extendedProps?.status)}
                  size="mini"
                >
                  <span>{event.extendedProps?.status}</span>
                </Label>
              )}
            </div>
            <div className="fc-event-text">{event.title}</div>
          </div>

          {selectedUser && selectedUser.length > 1 && (
            <div className="fc-event-author">
              {event.extendedProps?.author?.toUpperCase()}
            </div>
          )}
        </span>
      }
    />
  );
}
