import { Fleet } from '../types/fleet';

function convertToArrayOfObjects(arr?: any) {
  if (Object.prototype.toString.call(arr) === '[object Array]') {
    return arr?.map((id: number) => ({
      id,
    }));
  }
  if (typeof arr === 'number') {
    return [{ id: arr }];
  }
  if (arr === '') {
    return undefined;
  }
  return arr;
}

export function convertArrayDataToArrayOfObjects(data: Fleet) {
  data.fleetContact = data.fleetContact?.map(contact => ({
    ...contact,
    id: contact.id,
    fleetId: data.id,
  }));

  data.shareBusiness = data.shareBusiness
    ?.filter(item => {
      return item.brand && item.share;
    })
    .map(item => ({
      ...item,
      id: data.id,
      fleetId: data.id,
    }));

  data.crissCrossType = convertToArrayOfObjects(data.crissCrossType);

  data.controlTypes = convertToArrayOfObjects(data.controlTypes);

  data.numberVehicles = convertToArrayOfObjects(data.numberVehicles);
  data.truckBrands = convertToArrayOfObjects(data.truckBrands);
  data.engineTypes = convertToArrayOfObjects(data.engineTypes);
  data.axles = convertToArrayOfObjects(data.axles);
  data.truckModels = convertToArrayOfObjects(data.truckModels);
  data.trucks = convertToArrayOfObjects(data.trucks);

  data.fleetRecapping = data.fleetRecapping
    ?.filter(item => item.recapping?.id)
    .map(f => {
      return {
        brandTotalKM: f.brandTotalKM,
        grooveMM: f.grooveMM,
        recappingId: f.recapping?.id,
        id: f.recapping?.id,
        fleetId: data.id,
      };
    });

  data.services = convertToArrayOfObjects(data.services);

  data.sweepRadius = convertToArrayOfObjects(data.sweepRadius);

  data.tireAveragesKm = convertToArrayOfObjects(data.tireAveragesKm);

  data.tireBrands = convertToArrayOfObjects(data.tireBrands);

  data.usedBands = convertToArrayOfObjects(data.usedBands);
}
