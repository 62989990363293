import I18n from 'i18n-js';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';

import projectPackage from '../../../../package.json';
import useIsMobileScreen from '../../../hooks/useIsMobileScreen';
import {
  BASE_URL,
  FLEETS_PAGE,
  PROJECTS_PAGE,
  SCHEDULER_PAGE,
  SEARCH_PAGE,
} from '../../../settings/constants';
import { Container, Navigation, Items, Item, Info, Logo } from './styles';

interface Props {
  show: boolean;
  handleShow: () => void;
}

function Sidebar({ show, handleShow }: Props) {
  const location = useLocation();
  const { isMobileScreen } = useIsMobileScreen(460);
  const currentYear = new Date().getFullYear();

  function CloseSidebarIfOpened() {
    if (show) {
      handleShow();
    }
  }

  return (
    <Container show={show}>
      <Navigation show={show}>
        <Items show={show}>
          <Item onClick={handleShow} active={false}>
            <Icon name={show ? 'arrow left' : 'arrow right'} />
          </Item>

          <Popup
            disabled={isMobileScreen || show}
            inverted
            content="Dashboard"
            trigger={
              <Item
                onClick={CloseSidebarIfOpened}
                active={location.pathname === BASE_URL}
              >
                <Link to={BASE_URL}>
                  <Icon name="chart bar" />
                  <span>{I18n.t('title.dashboard')}</span>
                </Link>
              </Item>
            }
          />

          <Popup
            disabled={isMobileScreen || show}
            inverted
            content="Schedule"
            trigger={
              <Item
                onClick={CloseSidebarIfOpened}
                active={location.pathname === SCHEDULER_PAGE}
              >
                <Link to={SCHEDULER_PAGE}>
                  <Icon name="calendar alternate" />
                  <span>{I18n.t('title.scheduler')}</span>
                </Link>
              </Item>
            }
          />

          <Popup
            disabled={isMobileScreen || show}
            inverted
            content="Project"
            trigger={
              <Item
                onClick={CloseSidebarIfOpened}
                active={!!location.pathname.match(PROJECTS_PAGE)}
              >
                <Link to={`${PROJECTS_PAGE}/1`}>
                  <Icon name="wrench" />
                  <span>{I18n.t('title.projects')}</span>
                </Link>
              </Item>
            }
          />

          <Popup
            disabled={isMobileScreen || show}
            inverted
            content="Fleets"
            trigger={
              <Item
                onClick={CloseSidebarIfOpened}
                active={location.pathname === FLEETS_PAGE}
              >
                <Link to={FLEETS_PAGE}>
                  <Icon name="car" />
                  <span>{I18n.t('title.fleets')}</span>
                </Link>
              </Item>
            }
          />

          <Popup
            disabled={isMobileScreen || show}
            inverted
            content="Search"
            trigger={
              <Item
                onClick={CloseSidebarIfOpened}
                active={location.pathname === SEARCH_PAGE}
              >
                <Link to={SEARCH_PAGE}>
                  <Icon name="search" />
                  <span>{I18n.t('label.search')}</span>
                </Link>
              </Item>
            }
          />
        </Items>
      </Navigation>

      <Info show={show}>
        <Logo />

        <div className="info__version-and-year">
          <span className="info__version">v{projectPackage.version}</span>
          <span className="info__year">© {currentYear}</span>
        </div>

        <div className="info__sentence">The Goodyear Tire & Rubber Company</div>
      </Info>
    </Container>
  );
}

export { Sidebar };
