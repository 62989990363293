import { DropdownItemProps } from 'semantic-ui-react';

import { getSemanticColor } from '../utils/getSemanticColor';

export function toDropdownItemProps(
  items: any,
  activeColors = false,
  disabled = false,
): DropdownItemProps[] {
  return items?.map(
    (item: any, index: number): DropdownItemProps => ({
      key: item.id,

      text:
        item.label ??
        item.name ??
        item.folder ??
        `${item.brand} ${item.model} ${item.tireSize.label} (${item.modification})`,

      value: item.id,

      label: activeColors
        ? {
            color: getSemanticColor(item.color ? item.color : item.label),
            empty: true,
            circular: true,
          }
        : null,

      disabled: index === 0 ? disabled : false,
    }),
  );
}
