import {
  AuthenticationResult,
  EndSessionPopupRequest,
  InteractionRequiredAuthError,
  InteractionStatus,
  PopupRequest,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import api from '../services/api';
import { loginRequest } from '../settings/authConfig';
import { BASE_AUTHORIZE_BASE_API_URL } from '../settings/constants';
import { ENV } from '../types/env';

interface User {
  displayName: string;
  givenName: string;
  jobTitle: string;
  mail: string;
  mobilePhone?: any;
  officeLocation: string;
  preferredLanguage?: any;
  surname: string;
  userPrincipalName: string;
  id: string;
  photo_url?: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface AuthContextData {
  user: User;
  isUserLoading: boolean;
  currentUserRoles?: string[];
  signIn(): Promise<void>;
  signOut(): void;
}

const FIELDENG_TOKEN = '@FieldEng:token';
const FIELDENG_USER = '@FieldEng:user';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const { instance, inProgress, accounts } = useMsal();
  const [isUserLoading, setIsUserLoading] = useState(false);

  const homeAccountId = accounts[0]?.homeAccountId;

  const currentUserRoles =
    instance.getAccountByHomeId(homeAccountId)?.idTokenClaims?.roles;

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(FIELDENG_TOKEN);
    const user = localStorage.getItem(FIELDENG_USER);

    if (token && user) {
      api.defaults.headers.common.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  async function loadData(graphToken: string) {
    const AuthorizeResponse = await axios.post(
      `${BASE_AUTHORIZE_BASE_API_URL}/graphToken`,
      {
        graphToken,
      },
    );

    setIsUserLoading(true);

    const { data: user } = await axios
      .get<User>('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${graphToken}`,
        },
      })
      .finally(() => {
        setIsUserLoading(false);
      });

    await axios
      .get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: {
          Authorization: `Bearer ${graphToken}`,
        },

        responseType: 'blob',
      })
      .then(photoResponse => {
        user.photo_url = URL.createObjectURL(photoResponse.data);
      })
      .catch(error => {
        console.log(error);
      });

    const { accessToken } = AuthorizeResponse.data;

    localStorage.setItem(FIELDENG_TOKEN, accessToken);
    localStorage.setItem(FIELDENG_USER, JSON.stringify(user));

    api.defaults.headers.common.authorization = `Bearer ${accessToken}`;

    setData({ token: accessToken, user });
  }

  async function getGraphToken(accessTokenResponse: AuthenticationResult) {
    const { accessToken: graphToken } = accessTokenResponse;

    loadData(graphToken);
  }

  const signIn = useCallback(async () => {
    await instance.loginRedirect(loginRequest);

    const { accessToken: graphToken } = await instance.acquireTokenSilent(
      loginRequest,
    );

    loadData(graphToken);
  }, [instance]);

  const signOut = useCallback(async () => {
    const logoutRequest: EndSessionPopupRequest = {
      account: accounts[0],
      mainWindowRedirectUri: ENV.REACT_APP_REDIRECT_URI,
      postLogoutRedirectUri: ENV.REACT_APP_REDIRECT_URI,
    };

    localStorage.removeItem(FIELDENG_TOKEN);
    localStorage.removeItem(FIELDENG_USER);

    instance.logoutRedirect(logoutRequest as PopupRequest);

    setData({} as AuthState);
  }, [accounts, instance]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: ['user.read'],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(getGraphToken)
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(getGraphToken)
              .catch(signOut);
          }
        });
    }
  }, [instance, accounts, inProgress]);

  const value = useMemo(() => {
    return {
      user: data.user,
      isUserLoading,
      currentUserRoles,
      signIn,
      signOut,
    };
  }, [data.user, currentUserRoles, signIn, signOut]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
