import {
  Confirm,
  ConfirmProps,
  ModalContentProps,
  SemanticShorthandItem,
} from 'semantic-ui-react';

type ConfirmModalSizes = 'tiny' | 'mini' | 'small' | 'large' | 'fullscreen';
interface Props extends ConfirmProps {
  cancelBtnContent: string;
  confirmBtnContent: string;

  size?: ConfirmModalSizes;
  content?: SemanticShorthandItem<ModalContentProps>;
  children?: React.ReactNode;
}
export function ConfirmModal({
  cancelBtnContent,
  confirmBtnContent,
  size,
  content,
  children,
  ...rest
}: Props) {
  return (
    <Confirm
      {...rest}
      cancelButton={{
        content: cancelBtnContent,
      }}
      confirmButton={{
        content: confirmBtnContent,
        positive: true,
      }}
      size={size ?? 'tiny'}
      content={children || content}
    />
  );
}
