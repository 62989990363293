import { Control, Controller } from 'react-hook-form';
import { Table, Icon, Form, Popup, InputProps } from 'semantic-ui-react';

import useIsMobileScreen from '../../../hooks/useIsMobileScreen';
import { Container } from './styles';

interface IProps extends InputProps {
  control: Control<any, object>;
  label: string;
  watchFileName?: string;
  onChangeFile(file?: File): void;
  onDeleteFile(): void;
}

export function ControllerFormInputFile({
  control,
  label,
  watchFileName,
  onChangeFile,
  onDeleteFile,
  ...rest
}: IProps) {
  const { isMobileScreen } = useIsMobileScreen(460);

  return (
    <Container>
      <Form.Group grouped>
        {watchFileName ? (
          <>
            <label>{label}</label>
            <Table icon unstackable style={{ marginTop: '4px' }}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Popup
                      inverted
                      disabled={isMobileScreen}
                      mouseEnterDelay={1000}
                      content={watchFileName}
                      trigger={
                        <div className="text-overflow">{watchFileName}</div>
                      }
                    />
                  </Table.Cell>
                  <Table.Cell
                    content={
                      <Icon
                        name="trash"
                        color="red"
                        link
                        onClick={onDeleteFile}
                      />
                    }
                    width={1}
                  />
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        ) : (
          <Controller
            control={control}
            name="focusRequest"
            render={() => (
              <Form.Input
                label={label}
                type="file"
                accept={['.ppt, .pptx, .xls, .xlsx, .pdf']}
                icon="paperclip"
                onChange={e => onChangeFile(e.currentTarget.files?.[0])}
                {...rest}
                fluid
              />
            )}
          />
        )}
      </Form.Group>
    </Container>
  );
}
