import { useQuery } from 'react-query';
import { Loader, Modal } from 'semantic-ui-react';

import { convertFleetDataToCategorizedData } from '../../../helpers/convertFleetDataToCategorizedData';
import { getFleetByIdService } from '../../../services/fleets';
import { AdditionalInformation } from './AdditionalInformation';
import { GeneralInformation } from './GenneralInformation';
import { RoutesAndControl } from './RoutesAndControl';
import { Container } from './styles';
import { TireRecap } from './TireRecap';
import { TireShop } from './TireShop';
import { Vehicles } from './Vehicles';

interface IFleetDetailsProps {
  fleetId?: number;
  isOpen: boolean;
  onRequestClose: () => void;
}

export function FleetDetailsModal({
  fleetId,
  isOpen,
  onRequestClose,
}: IFleetDetailsProps) {
  const { data: fleet, isLoading } = useQuery('fleet', async () =>
    convertFleetDataToCategorizedData(await getFleetByIdService(fleetId)),
  );

  return (
    <Modal open={isOpen} onClose={onRequestClose} closeIcon>
      {isLoading ? (
        <Loader size="large" active inverted />
      ) : (
        <>
          <Modal.Header>Fleet Details</Modal.Header>
          <Modal.Content>
            <Container>
              <GeneralInformation data={fleet?.generalInformation} />
              <RoutesAndControl data={fleet?.routesAndControl} />
              <Vehicles data={fleet?.vehicles} />
              <TireShop data={fleet?.rubberShopAndTires} />
              <TireRecap data={fleet?.tireRecap} />
              <AdditionalInformation data={fleet?.additionalInformation} />
            </Container>
          </Modal.Content>
        </>
      )}
    </Modal>
  );
}
