import I18n from 'i18n-js';

import { DashboardOverview } from '../..';
import {
  backgroundTriadColors,
  backgroundAnalogousColors,
} from '../../../../settings/constants';
import { DoughnutChart } from '../Charts';
import { ChartsBox } from '../ChartsBox';

interface IProps {
  toggleOpen(value: DashboardOverview): void;
  dataChart: any;
}

export function DepartamentOverview({ toggleOpen, dataChart }: IProps) {
  return (
    <ChartsBox
      overviewName="departament"
      title={I18n.t('page.dashboard.department.title')}
      toggleOpen={toggleOpen}
    >
      <DoughnutChart
        title={I18n.t(
          'page.dashboard.department.chart.projectsPerMacroTestType',
        )}
        data={dataChart.projectsPerTestTypeJoinned}
        isLoading={dataChart.isLoadingProjectsPerTestTypeJoinned}
        backgroundColor={backgroundTriadColors}
      />

      <DoughnutChart
        title={I18n.t('page.dashboard.department.chart.projectsPerTestType')}
        data={dataChart.projectsPerTestType}
        isLoading={dataChart.isLoadingProjectsPerTestType}
        backgroundColor={backgroundAnalogousColors}
      />

      <DoughnutChart
        title={I18n.t('page.dashboard.department.chart.tiresPerMacroTestType')}
        data={dataChart.tiresPerTestTypeJoinned}
        isLoading={dataChart.isLoadingTiresPerTestTypeJoinned}
        backgroundColor={backgroundTriadColors}
      />

      <DoughnutChart
        title={I18n.t('page.dashboard.department.chart.tiresPerTestType')}
        data={dataChart.tiresPerTestType}
        isLoading={dataChart.isLoadingTiresPerTestType}
        backgroundColor={backgroundAnalogousColors}
      />
    </ChartsBox>
  );
}
