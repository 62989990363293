import { Control, Controller } from 'react-hook-form';
import { Form, FormInputProps } from 'semantic-ui-react';

interface Props extends FormInputProps {
  controllerControl: Control<any>;
  controllerName: string;
  required?: boolean;
  isNotUpperCase?: boolean;
}

export function ControllerFormInputDate({
  controllerControl,
  controllerName,
  required,
  isNotUpperCase,
  ...rest
}: Props) {
  return (
    <Controller
      control={controllerControl}
      name={controllerName}
      rules={{
        required,
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <Form.Input
          type="date"
          required={required}
          value={value}
          onChange={(_, data) =>
            onChange(isNotUpperCase ? data.value : data.value.toUpperCase())
          }
          onBlur={onBlur}
          {...rest}
        />
      )}
    />
  );
}
