import styled from 'styled-components';

import route3DotsImg from '../../../../../../assets/img/route_3dots.png';

export const Container = styled.div`
  .segment {
    margin-bottom: 1rem !important;
  }

  .routes__origin-destination {
    .dropdown {
      flex: 1;
    }

    span {
      .routes__3dots {
        position: absolute;
        background-image: url(${route3DotsImg});
        background-repeat: no-repeat;
        top: 100px;
        margin-left: -4px;
        width: 24px;
        height: 30px;
      }
    }
  }
`;
