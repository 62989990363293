import I18n from 'i18n-js';
import { Table } from 'semantic-ui-react';

import { FleetAdditionalInformation } from '../../../../types/fleet';
import { Article } from '../components/Article';
import { Header } from '../components/Header';

interface IProps {
  data?: FleetAdditionalInformation;
}

export function AdditionalInformation({ data }: IProps) {
  return (
    <Article>
      <div className="table-container">
        <Table celled unstackable compact>
          <Table.Header>
            <Table.Row>
              <Table.Cell className="title" colspan="4">
                <Header icon="plus circle" content="Additional information" />
              </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top" width={3}>
                {I18n.t(
                  'page.fleets.label.additionalInformation.additionalInformation',
                )}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top">
                {data?.additionalInformation}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top" width={3}>
                {I18n.t('page.fleets.label.additionalInformation.createdBy')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top">
                {data?.createdBy}
              </Table.Cell>
              <Table.Cell className="th" verticalAlign="top" width={3}>
                {I18n.t('page.fleets.label.additionalInformation.createdDate')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top">
                {data?.createdDate}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top" width={3}>
                {I18n.t('page.fleets.label.additionalInformation.modifiedBy')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top">
                {data?.modifiedBy}
              </Table.Cell>
              <Table.Cell className="th" verticalAlign="top" width={3}>
                {I18n.t('page.fleets.label.additionalInformation.modifiedDate')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top">
                {data?.modifiedDate}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Article>
  );
}
