import {
  Checkbox,
  CheckboxProps,
  Header,
  Modal,
  Table,
} from 'semantic-ui-react';

import { IShowColumnsProps } from '../..';

interface IShowHideColumnsModalProps {
  isShowHideModalOpen: boolean;
  showColumnsGroup?: boolean;
  showColumns: IShowColumnsProps;
  handleShowHideModalClose(): void;
  handleShowColumn(data: CheckboxProps): void;
  handleShowColumnsGroup(data: CheckboxProps): void;
}

export default function ShowHideColumnsModal({
  isShowHideModalOpen,
  showColumnsGroup,
  showColumns,
  handleShowHideModalClose,
  handleShowColumn,
  handleShowColumnsGroup,
}: IShowHideColumnsModalProps) {
  return (
    <Modal
      open={isShowHideModalOpen}
      onClose={handleShowHideModalClose}
      closeIcon
      size="mini"
    >
      <Modal.Content>
        <Table celled unstackable compact size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell colspan="2">Show/Hide</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Folder</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.folder}
                  name="folder"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Description</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.description}
                  name="description"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
              <Table.Cell rowSpan="10" textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumnsGroup}
                  name="description"
                  onClick={(_, data) => handleShowColumnsGroup(data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Fleet</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.fleet}
                  name="fleet"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Fleet Type</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.fleetType}
                  name="fleetType"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Service</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.service}
                  name="service"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Brand</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.brand}
                  name="brand"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Model</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.model}
                  name="model"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Size</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.size}
                  name="size"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Modification</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.modification}
                  name="modification"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Tires Qty</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.tiresQty}
                  name="tiresQty"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Responsible</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  defaultChecked
                  checked={showColumns.responsible}
                  name="responsible"
                  onClick={(_, data) => handleShowColumn(data)}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
}
