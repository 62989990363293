/* eslint-disable @typescript-eslint/ban-ts-comment */
import I18n from 'i18n-js';
import { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Form,
  Icon,
  Input,
  InputOnChangeData,
  Modal,
  Segment,
  StrictFormDropdownProps,
} from 'semantic-ui-react';

import { usePermission } from '../../../hooks/usePermission';
import { queryClient } from '../../../services/queryClient';
import { GenericObject } from '../../../types/fleet';
import { Table } from './components/Table';
import { useHandleUpdateItem } from './hooks/useHandleUpdateItem';
import { Container } from './styles';

export interface ItemsProps {
  key: number;
  text: string;
  isEdit: boolean;
}

type Props = Omit<StrictFormDropdownProps, 'control' | 'error'> & {
  controllerControl?: Control<any>;
  controllerName?: string;
  label: string;
  loading?: boolean;
  options?: DropdownItemProps[];
  activeInstructions?: boolean;
  instructions?: JSX.Element;
  queryKey: string;
  createItemService?: (item: GenericObject) => Promise<GenericObject>;
  updateItemService?: (item: GenericObject) => Promise<GenericObject>;
  deleteItemService?: (id: number) => Promise<void>;
  required?: boolean;
  error?: any;
  activateLabelError?: boolean;
};

export function EditableDropdown({
  controllerControl,
  controllerName,
  label,
  options,
  required,
  activateLabelError,
  queryKey,
  activeInstructions,
  instructions,
  createItemService,
  updateItemService,
  deleteItemService,
  ...rest
}: Props) {
  const { isAdmin } = usePermission();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState({
    label: '',
  });
  const [items, setItems] = useState<ItemsProps[] | undefined>(
    options?.map(option => ({
      key: option.key as number,
      text: option.text as string,
      isEdit: false,
    })),
  );

  useEffect(() => {
    setItems(
      options?.map(option => ({
        key: option.key as number,
        text: option.text as string,
        isEdit: false,
      })),
    );
  }, [options]);

  function handleModalOpen() {
    setIsModalOpen(true);
  }

  function handleModalClose() {
    setIsModalOpen(false);
  }

  function handleInputChange(data: InputOnChangeData) {
    setItem({
      label: data.value.toUpperCase(),
    });
  }

  const handleAddItem = useMutation(
    async () => {
      // @ts-ignore
      const response = await createItemService(item);

      handleInputChange({ value: '' });

      return response;
    },
    {
      onSuccess: () => {
        toast.success('Item created successfully');
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error('Error creating item');
        }
      },
    },
  );

  const handleUpdateItem = useHandleUpdateItem({ queryKey, updateItemService });

  const handleDeleteItem = useMutation(
    async (id: number) => {
      // @ts-ignore
      await deleteItemService(id);
    },
    {
      onSuccess: () => {
        toast.success('Item deleted successfully');
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error('This item is already in use');
        }
      },
    },
  );

  return (
    <Container>
      <Form.Group grouped>
        <Form.Field
          label={label}
          required={required}
          error={activateLabelError}
        />

        <Form.Group unstackable widths="equal" inline>
          {controllerControl ? (
            <Controller
              control={controllerControl}
              name={controllerName as string}
              rules={{
                required,
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  {...rest}
                  style={{ marginRight: isAdmin ? '1em' : '0' }}
                  search
                  selection
                  clearable
                  value={value}
                  onChange={(_, data) => onChange(data.value)}
                  options={options ?? []}
                />
              )}
            />
          ) : (
            <Dropdown
              {...rest}
              style={{ marginRight: isAdmin ? '1em' : '0' }}
              search
              selection
              clearable
              options={options ?? []}
            />
          )}

          {isAdmin && (
            <Button type="button" icon onClick={handleModalOpen}>
              <Icon name="edit" />
            </Button>
          )}
        </Form.Group>
      </Form.Group>

      {isAdmin && (
        <Modal
          open={isModalOpen}
          onCancel={handleModalClose}
          onConfirm={handleModalClose}
          size="mini"
        >
          <Modal.Header
            content={I18n.t('component.editableDropdown.manageList')}
          />
          <Modal.Content>
            <Form.Group>
              <Input
                placeholder={I18n.t('placeholder.insertValue')}
                action={
                  <Button
                    content={I18n.t('component.editableDropdown.button.add')}
                    color="green"
                    onClick={() => handleAddItem.mutateAsync()}
                    disabled={!item.label.length}
                  />
                }
                value={item.label}
                onChange={(_, data) => handleInputChange(data)}
                fluid
              />
            </Form.Group>

            {activeInstructions && <Segment basic>{instructions}</Segment>}

            <Table
              items={items}
              setItems={setItems}
              handleUpdateItem={handleUpdateItem}
              handleDeleteItem={handleDeleteItem}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleModalClose}>
              <Icon name="checkmark" />
              {I18n.t('component.editableDropdown.button.done')}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Container>
  );
}
