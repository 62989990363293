import { useState } from 'react';
import { UseFieldArrayRemove, useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import {
  Accordion,
  Button,
  DropdownItemProps,
  Form,
  Icon,
  Input,
  Label,
  Segment,
} from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../../../../../components/Form/ControllerFormDropdown';
import { ControllerFormField } from '../../../../../../components/Form/ControllerFormField';
import { ControllerFormInputDate } from '../../../../../../components/Form/ControllerFormInputDate';
import { toDropdownItemProps } from '../../../../../../helpers/toDropdownItemProps';
import { getProjectLinkReportStatusService } from '../../../../../../services/project';
import { getReportPercentageType } from '../../../../../../services/reportPercentageType';
import { FIELD_ERROR_MESSAGE } from '../../../../../../settings/constants';
import { LinkReport, ProjectFormData } from '../../../../../../types/project';
import { UploadTasksConfirmModal } from './components/UploadTasksConfirmModal';
import { useCreateReportLink } from './hooks/useCreateReportLink';
import { useDeleteReportLink } from './hooks/useDeleteReportLink';
import { useEditReportLink } from './hooks/useEditReportLink';

interface LinkedReportProps {
  isOldProject: boolean;
  projectId?: number;
  index: number;
  remove: UseFieldArrayRemove;
  linkReports?: LinkReport[];
}

export function LinkReports({
  isOldProject,
  projectId,
  index,
  remove,
  linkReports,
}: LinkedReportProps) {
  const { control, formState } = useFormContext<ProjectFormData>();
  const [activeAccordion, setActiveAccordion] = useState(false);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isTrashLoading, setIsTrashLoading] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUpdateMoreReports, setIsUpdateMoreReports] = useState(false);

  const originalEstimatedDate = String(
    linkReports?.[index]?.estimatedDate,
  ).split('T')[0];

  const currentEstimatedDate = useWatch({
    control,
    name: `linkReports.${index}.estimatedDate`,
  })?.toString();

  const reportLink = useWatch({
    control,
    name: `linkReports.${index}`,
  });

  const { data: linkReportStautsOptions } = useQuery(
    'LINK_REPORT_STATUS',
    async () =>
      toDropdownItemProps(
        (await getProjectLinkReportStatusService())?.linkReportStatus,
        true,
      ),
  );

  const { data: percentageReportOptions } = useQuery(
    'report_percentage_options',
    async () => toDropdownItemProps(await getReportPercentageType()),
  );

  const percentageReportLabel = percentageReportOptions?.find(
    (option: DropdownItemProps) =>
      option?.value === reportLink.percentageReportId,
  )?.text;

  const { createReportLink } = useCreateReportLink({
    setActiveAccordion,
    setIsSubmitLoading,
  });

  const { editReportLink } = useEditReportLink({
    setActiveAccordion,
    setIsSubmitLoading,
  });

  const { deleteReportLink } = useDeleteReportLink({
    reportLinkId: reportLink.id,
    index,
    remove,
    setIsTrashLoading,
  });

  function handleConfirmationModalOpen() {
    setIsConfirmationModalOpen(true);
  }

  function handleConfirmationModalClose() {
    setIsConfirmationModalOpen(false);

    setIsSubmitLoading(false);
  }

  function handleReportLinkSubmit() {
    const data = { projectId, ...reportLink };
    setIsSubmitLoading(true);

    if (reportLink.id) {
      if (originalEstimatedDate !== currentEstimatedDate) {
        return handleConfirmationModalOpen();
      }

      return editReportLink.mutateAsync({ data, isUpdateMoreReports: false });
    }
    return createReportLink.mutateAsync({ projectId, ...reportLink });
  }

  function handleDeleteReportLink() {
    if (!reportLink.id) {
      remove(index);
    } else {
      deleteReportLink.mutateAsync();
    }
  }

  return (
    <>
      <Accordion as={Segment} style={{ marginTop: 0 }}>
        <Form.Group grouped>
          <Accordion.Title active={activeAccordion}>
            <Label
              style={{ display: 'flex', justifyContent: 'space-between' }}
              as="a"
              onClick={() => setActiveAccordion(prev => !prev)}
              attached="top"
            >
              <Icon name="dropdown" />
              <span
                style={{ display: 'flex', gap: '1em', alignItems: 'center' }}
              >
                Link Report ({percentageReportLabel || index + 1})
              </span>
              <Icon
                name="trash"
                color="red"
                onClick={handleDeleteReportLink}
                loading={isTrashLoading}
              />
            </Label>
          </Accordion.Title>

          <Accordion.Content active={activeAccordion}>
            <Form.Group grouped>
              <ControllerFormDropdown
                controllerControl={control}
                controllerName={`linkReports.${index}.percentageReportId`}
                label="Percentage"
                selection
                clearable
                options={percentageReportOptions}
                required
                error={
                  formState.errors.linkReports?.[index]?.percentageReportId &&
                  FIELD_ERROR_MESSAGE
                }
              />
            </Form.Group>

            <Form.Group grouped>
              <ControllerFormField
                isNotUpperCase
                controllerControl={control}
                controllerName={`linkReports.${index}.link`}
                control={Input}
                label="Link"
              />
            </Form.Group>

            <Form.Group grouped>
              <ControllerFormInputDate
                controllerControl={control}
                controllerName={`linkReports.${index}.estimatedDate`}
                label="Estimated Date"
                required
                error={
                  formState.errors.linkReports?.[index]?.estimatedDate &&
                  FIELD_ERROR_MESSAGE
                }
              />
            </Form.Group>

            <Form.Group grouped>
              <ControllerFormDropdown
                controllerControl={control}
                controllerName={`linkReports.${index}.statusId`}
                label="Status"
                options={linkReportStautsOptions}
                required
                error={
                  formState.errors.linkReports?.[index]?.statusId?.type &&
                  FIELD_ERROR_MESSAGE
                }
              />
            </Form.Group>

            {!isOldProject && (
              <Button
                type="button"
                icon="cloud upload"
                content={reportLink.id ? 'Update' : 'Create'}
                labelPosition="left"
                loading={isSubmitLoading}
                color="green"
                onClick={handleReportLinkSubmit}
                fluid
              />
            )}
          </Accordion.Content>
        </Form.Group>
      </Accordion>

      {isConfirmationModalOpen && (
        <UploadTasksConfirmModal
          projectId={projectId}
          reportLink={reportLink}
          handleConfirmationModalClose={handleConfirmationModalClose}
          isConfirmationModalOpen={isConfirmationModalOpen}
          isUpdateMoreReports={isUpdateMoreReports}
          setActiveAccordion={setActiveAccordion}
          setIsSubmitLoading={setIsSubmitLoading}
          setIsUpdateMoreReports={setIsUpdateMoreReports}
        />
      )}
    </>
  );
}
