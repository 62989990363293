import React from 'react';
import { Form } from 'semantic-ui-react';

import { Container } from './styles';

interface IFilterGroupProps {
  title?: string;
  children: React.ReactNode;
}

export function FilterGroup({ title, children }: IFilterGroupProps) {
  return (
    <Container>
      <Form.Group grouped>
        <div className="filterGroup__title">{title}</div>
        <Form.Group className="filterGroup__content" grouped>
          {children}
        </Form.Group>
      </Form.Group>
    </Container>
  );
}
