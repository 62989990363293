import styled from 'styled-components';

export const Container = styled.div`
  font-size: 12px;

  .table-container {
    margin: 0 !important;

    overflow: auto;
    table {
      /* border: 0; */

      tr {
        &:nth-child(even) {
          background-color: #f2f2f2;
        }
        .th {
          font-weight: 600 !important;
          color: #33333380 !important;
        }
        .td {
          font-weight: 600 !important;
          color: #808080 !important;
          border: 0;
        }
        .th,
        .td {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
  }

  table > thead > tr > .title {
    padding: 0 !important;
  }
  .text-area {
    min-width: 200px;
  }
  .no-wrap {
    white-space: nowrap;
  }
`;
