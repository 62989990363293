import I18n from 'i18n-js';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../services/queryClient';
import {
  deleScheduleTaskSeriesServices,
  deleteScheduleService,
} from '../../../services/schedule';
import { SCHEDULE_TASKS } from '../../../settings/constants';

interface Props {
  onRequestClose(): void;
}

interface Props2 {
  scheduleTaskId?: number;
  scheduleTaskSeriesId?: number;
  isDeleteTaskSeriesSelected?: boolean;
}

export function useDeleteScheduleTask({ onRequestClose }: Props) {
  return useMutation(
    async ({
      scheduleTaskId,
      scheduleTaskSeriesId,
      isDeleteTaskSeriesSelected,
    }: Props2) => {
      if (isDeleteTaskSeriesSelected) {
        return deleScheduleTaskSeriesServices(scheduleTaskSeriesId);
      }

      return deleteScheduleService(scheduleTaskId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SCHEDULE_TASKS);
        toast.success(I18n.t('page.schedule.toast.success.create'));

        onRequestClose();
      },
      onError: () => {
        toast.error(I18n.t('page.schedule.toast.error.create'));
      },
    },
  );
}
