import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Loader } from 'semantic-ui-react';

import { ChartDataProps } from '../../../../../types/dashboard';
import { Container } from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

interface IProps {
  title: string;
  data?: ChartDataProps;
  isLoading?: boolean;
  indexAxis?: 'y' | 'x';
  stackedX?: boolean;
  stackedY?: boolean;
}

const backgroundColor = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#ff9e4f',
  '#213965',
  '#02b352',
];

export function StackedBarChart({
  title,
  data,
  isLoading,
  indexAxis,
  stackedX = true,
  stackedY = true,
}: IProps) {
  return (
    <Container>
      <div className="minichartbox__title">{title}</div>
      <div className="minichartbox__content">
        {isLoading ? (
          <Loader inline active />
        ) : (
          <Bar
            data={{
              labels: data?.labels ?? [],
              datasets:
                data?.datasets?.map((item, index) => ({
                  label: item.label,
                  data: item.data,
                  backgroundColor: backgroundColor[index],
                })) ?? [],
            }}
            options={{
              indexAxis: indexAxis ?? ('y' as const),
              responsive: true,
              scales: {
                x: {
                  stacked: stackedX,
                },
                y: {
                  stacked: stackedY,
                },
              },
              plugins: {
                datalabels: {
                  backgroundColor: '#FFFFFF20',
                  anchor: 'center',
                  borderRadius: 25,
                  borderWidth: 2,
                  color: 'black',
                  labels: {
                    title: {
                      font: {
                        weight: 'bold',
                      },
                    },
                    value: {
                      color: '#333333',
                    },
                  },
                },
                legend: {
                  display: true,
                  position: 'bottom' as const,
                },
              },
            }}
          />
        )}
      </div>
    </Container>
  );
}
