import { useState, useCallback } from 'react';
import { UseFieldArrayRemove, useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Accordion, Form, Icon, Label, Segment } from 'semantic-ui-react';

import useLocaleQueries from '../../../../../../hooks/useLocaleQueries';
import { Fleet, FleetDataCategorized } from '../../../../../../types/fleet';
import { AccordionContent } from '../AccordionContent';
import { useDeleteFile } from './hooks/useDeleteFile';
import { useUploadFile } from './hooks/useUploadFile';
import { Container } from './styles';

interface IProps {
  index: number;
  fleetId?: number;
  remove: UseFieldArrayRemove;

  currentRouteFiles: File[];
  setCurrentRouteFiles: React.Dispatch<React.SetStateAction<File[]>>;

  oldRouteFileName: string[];
  setOldRouteFileName: React.Dispatch<React.SetStateAction<string[]>>;

  isDeleteRouteFiles: boolean[];
  setIsDeleteRouteFiles: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export default function Routes({
  index,
  fleetId,
  remove,

  currentRouteFiles,
  oldRouteFileName,
  isDeleteRouteFiles,

  setCurrentRouteFiles,
  setOldRouteFileName,
  setIsDeleteRouteFiles,
}: IProps) {
  const { control } = useFormContext<FleetDataCategorized>();
  const [isActiveAccordion, setIsActiveAccordion] = useState(true);

  useQuery('FLEET_BY_ID', {
    refetchOnMount: false,
    onSuccess: (data: Fleet) =>
      setOldRouteFileName(
        () => data.route?.map(({ fileRoute }) => fileRoute) ?? [],
      ),
    enabled: !!fleetId,
  });

  const origin = useWatch({
    control,
    name: `routesAndControl.route.${index}.originCity`,
  });

  const destination = useWatch({
    control,
    name: `routesAndControl.route.${index}.destinationCity`,
  });

  const { states: originState, citiesByState: originCity } = useLocaleQueries(
    `routesAndControl.route.${index}.originRegion`,
  );

  const { states: destinationState, citiesByState: destinationCity } =
    useLocaleQueries(`routesAndControl.route.${index}.destinationRegion`);

  const handleUploadFile = useUploadFile({ index, currentRouteFiles });

  const handleDeleteFile = useDeleteFile({
    index,
    oldRouteFileName,
    currentRouteFiles,
    setCurrentRouteFiles,
    isDeleteRouteFiles,
    setIsDeleteRouteFiles,
  });

  const onSubmit = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      if (
        currentRouteFiles[index] &&
        currentRouteFiles[index].name !== oldRouteFileName?.[index]
      ) {
        return handleUploadFile.mutateAsync();
      }

      if (isDeleteRouteFiles[index] && oldRouteFileName[index]) {
        return handleDeleteFile.mutateAsync();
      }

      return toast.warn('Select file before uploading to server');
    },
    [
      currentRouteFiles,
      index,
      oldRouteFileName,
      isDeleteRouteFiles,
      handleUploadFile,
      handleDeleteFile,
    ],
  );

  return (
    <Container>
      <Accordion as={Segment} style={{ marginTop: 0 }}>
        <Form.Group grouped>
          <Accordion.Title active={isActiveAccordion}>
            <Label
              as="a"
              style={{ display: 'flex', justifyContent: 'space-between' }}
              attached="top"
              onClick={() => setIsActiveAccordion(prev => !prev)}
            >
              <Icon name="dropdown" />

              <span
                style={{ display: 'flex', gap: '1em', alignItems: 'center' }}
              >
                {origin ?? 'Origin'}
                <Icon name="shipping fast" />
                {destination ?? 'Destination'}
              </span>
              <Icon name="delete" color="red" onClick={() => remove(index)} />
            </Label>
          </Accordion.Title>

          <AccordionContent
            index={index}
            isActiveAccordion={isActiveAccordion}
            setIsActiveAccordion={setIsActiveAccordion}
            currentRouteFiles={currentRouteFiles}
            setCurrentRouteFiles={setCurrentRouteFiles}
            originState={originState}
            originCity={originCity}
            destinationState={destinationState}
            destinationCity={destinationCity}
            handleDeleteFile={handleDeleteFile}
            onSubmit={onSubmit}
          />
        </Form.Group>
      </Accordion>
    </Container>
  );
}
