import I18n from 'i18n-js';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Form, Input } from 'semantic-ui-react';

import { toDropdownItemProps } from '../../helpers/toDropdownItemProps';
import { queryClient } from '../../services/queryClient';
import { getReportPercentageType } from '../../services/reportPercentageType';
import {
  createInspectionAndReportTasks,
  getScheduleService,
} from '../../services/schedule';
import { InspectionAndReportTasksProps } from '../../types/schedule';
import { ConfirmModal } from '../ConfirmModal';
import { ControllerFormDropdown } from '../Form/ControllerFormDropdown';
import { ControllerFormField } from '../Form/ControllerFormField';

interface Props {
  isModalOpen?: boolean;
  projectId?: number;
  onClose(): void;
  setIsRefetchProjectById: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function GenerateScheduleTaskFormModal({
  isModalOpen,
  projectId,
  onClose,
  setIsRefetchProjectById,
}: Props) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<InspectionAndReportTasksProps>();

  useEffect(() => {
    setValue('projectId', projectId);
  }, [projectId, setValue]);

  const createInspAndRepTasks = useMutation(
    async (data: InspectionAndReportTasksProps) => {
      await createInspectionAndReportTasks(data);
    },
    {
      onSuccess: () => {
        toast.success('Tasks generated successfully');
        queryClient.invalidateQueries('projects');

        setIsRefetchProjectById(true);
        onClose();
      },
      onError: (e: any) => {
        toast.error(e?.response?.data?.error?.message);
      },
    },
  );

  const onSubmit = useCallback(
    async (data: InspectionAndReportTasksProps) => {
      await createInspAndRepTasks.mutateAsync(data);
    },
    [createInspAndRepTasks],
  );

  const { data: frequencyOptions } = useQuery('frequency', async () =>
    toDropdownItemProps((await getScheduleService()).frequencies),
  );

  const frequencyId = useWatch({
    control,
    name: 'frequency',
  });

  const [reports, setReports] = useState({
    one: true,
    two: true,
    thre: true,
    four: true,
  });

  const { data: reportPercentage } = useQuery(
    'report_percentage',
    getReportPercentageType,
  );

  const reportStages = Object.values(reports)?.map((report, index) => {
    if (report) {
      return reportPercentage?.[index].id;
    }
    return undefined;
  });

  useEffect(() => {
    setValue('reports', reportStages);
  }, [setValue, reportStages]);

  return (
    <ConfirmModal
      header="Generate Inspection And Report Tasks"
      cancelBtnContent={I18n.t('confirm.delete.cancel')}
      confirmBtnContent="Generate"
      open={isModalOpen}
      onCancel={onClose}
      onConfirm={handleSubmit(onSubmit)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group grouped>
          <Form.Field
            label="Assembly Date Considered"
            required
            error={!!errors.typeDateId}
          />
          <Controller
            control={control}
            name="typeDateId"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <Form.Group inline>
                <Form.Radio
                  label="Average"
                  checked={value === 2}
                  onChange={() => onChange(2)}
                  error={!!errors.typeDateId}
                />
                <Form.Radio
                  label="Final"
                  checked={value === 1}
                  onChange={() => onChange(1)}
                  error={!!errors.typeDateId}
                />
              </Form.Group>
            )}
          />
        </Form.Group>

        <ControllerFormDropdown
          controllerControl={control}
          controllerName="frequency"
          label="Inspections Frequence"
          options={frequencyOptions}
          required
          error={
            !!errors.frequency && I18n.t('message.error.thisFieldIsRequired')
          }
        />

        {frequencyId !== 5 && (
          <ControllerFormField
            controllerControl={control}
            controllerName="repetitions"
            control={Input}
            type="number"
            label="Inspections Repetitions"
            min={0}
            required={!!frequencyId}
            error={
              !!frequencyId &&
              !!errors.repetitions &&
              I18n.t('message.error.thisFieldIsRequired')
            }
            disabled={!frequencyId}
          />
        )}

        <Form.Group grouped>
          <Form.Field
            label="Report Stages"
            required
            error={!!errors.typeDateId}
          />
          <Controller
            control={control}
            name="reports"
            rules={{ required: true }}
            render={() => (
              <Form.Group inline>
                <Form.Checkbox
                  label="30%"
                  checked={reports.one}
                  onChange={() =>
                    setReports(prev => ({ ...prev, one: !prev.one }))
                  }
                  error={!!errors.reports}
                />
                <Form.Checkbox
                  label="60%"
                  checked={reports.two}
                  onChange={() =>
                    setReports(prev => ({ ...prev, two: !prev.two }))
                  }
                  error={!!errors.reports}
                />
                <Form.Checkbox
                  label="EOL"
                  checked={reports.thre}
                  disabled
                  onChange={() =>
                    setReports(prev => ({ ...prev, thre: !prev.thre }))
                  }
                  error={!!errors.reports}
                />
                <Form.Checkbox
                  label="Total Cycle"
                  checked={reports.four}
                  onChange={() =>
                    setReports(prev => ({ ...prev, four: !prev.four }))
                  }
                  error={!!errors.reports}
                />
              </Form.Group>
            )}
          />
        </Form.Group>

        <span>
          Are you sure you want to generate inspection and report tasks?
        </span>
      </Form>
      <span />
    </ConfirmModal>
  );
}
