import { useQuery } from 'react-query';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { getFleetListService } from '../../../services/fleets';
import { getProjectListService } from '../../../services/project';
import { getTireListService } from '../../../services/tire';
import { getUserListService } from '../../../services/user';

export interface IFilterOptionsProps {
  folderOptions?: DropdownItemProps[];
  fleetNameOptions?: DropdownItemProps[];
  fleetTypeOptions?: DropdownItemProps[];
  fleetServiceOptions?: DropdownItemProps[];
  usersOptions?: DropdownItemProps[];
  tireBrandOptions?: DropdownItemProps[];
  tireModelOptions?: DropdownItemProps[];
  tireSizeOptions?: DropdownItemProps[];
  tireModificationOptions?: DropdownItemProps[];
}

export default function useFiltersOptions(): IFilterOptionsProps {
  const { data: projects } = useQuery('projects', () =>
    getProjectListService({ size: 9999 }),
  );

  const { data: fleets } = useQuery('fleets', () =>
    getFleetListService({ size: 9999 }),
  );

  const { data: users } = useQuery('users', () => getUserListService());

  const { data: tires } = useQuery('tires', () =>
    getTireListService({ size: 9999 }),
  );

  const serviceList: DropdownProps[] = [];

  function removeDuplicatedValuesFromArray(arr?: any[]) {
    const setElement = new Set();
    let duplicatedElement;

    return arr?.filter(element => {
      duplicatedElement = setElement.has(element.text);

      setElement.add(element.text);

      return !duplicatedElement;
    });
  }

  return {
    folderOptions: removeDuplicatedValuesFromArray(
      projects?._embedded.projects?.map(project => ({
        key: project.id,
        text: project.folder,
        value: project.folder,
      })),
    ),
    fleetNameOptions: removeDuplicatedValuesFromArray(
      fleets?._embedded.fleets?.map(fleet => ({
        key: fleet.id,
        text: fleet.name,
        value: fleet.id,
      })),
    ),
    fleetTypeOptions: removeDuplicatedValuesFromArray(
      fleets?._embedded.fleets?.map(fleet => ({
        key: fleet.id,
        text: fleet.type?.label,
        value: fleet.type?.id,
      })),
    ),
    fleetServiceOptions: removeDuplicatedValuesFromArray(
      fleets?._embedded.fleets?.map(item => {
        item.services?.forEach(service => {
          if (service) {
            serviceList.push({
              key: service.id,
              text: service.label,
              value: service.id,
            });
          }
        });

        return serviceList;
      })[0],
    ),
    usersOptions: removeDuplicatedValuesFromArray(
      users?.map(user => ({
        key: user.id,
        text: user.name,
        value: user.id,
      })),
    ),
    tireBrandOptions: removeDuplicatedValuesFromArray(
      tires?.map(tire => ({
        key: tire.id,
        text: tire.brand,
        value: tire.brand,
      })),
    ),
    tireModelOptions: removeDuplicatedValuesFromArray(
      tires?.map(tire => ({
        key: tire.id,
        text: tire.model,
        value: tire.model,
      })),
    ),
    tireSizeOptions: removeDuplicatedValuesFromArray(
      tires?.map(tire => ({
        key: tire.id,
        text: tire.tireSize?.label,
        value: tire.tireSize?.id,
      })),
    ),
    tireModificationOptions: removeDuplicatedValuesFromArray(
      tires?.map(tire => ({
        key: tire.id,
        text: tire.modification,
        value: tire.modification,
      })),
    ),
  };
}
