import { useQuery } from 'react-query';

import { IsOpenOverviewProps } from '..';

import {
  getProjectsPerTestTypeAndReportService,
  getProjectsPerTestTypeAndEngineerService,
  getProjectsPerSegmentService,
  getProjectsPerEngineerService,
  getProjectsPerSegmentAndEngineerService,
  getProjectsPerStatusAndEngineerService,
  getProjectsPerTestTypeService,
} from '../../../services/dashboard';
import { ProjectsOverviewDataProps } from '../../../types/dashboard';

export default function useProjectsChartData(
  isOpenOverview: IsOpenOverviewProps,
): ProjectsOverviewDataProps {
  const { data: projectsPerTestType, isLoading: isLoadingProjectsPerTestType } =
    useQuery('chart_projects_per_test_type', getProjectsPerTestTypeService, {
      enabled: isOpenOverview.projects,
    });

  const {
    data: projectPerTestTypeAndReport,
    isLoading: IsLoadingProjPerTestTypeAndReport,
  } = useQuery(
    'chart_projects_per_testType_and_report',
    getProjectsPerTestTypeAndReportService,
    {
      enabled: isOpenOverview.projects,
    },
  );

  const {
    data: projectsPerTestTypeAndEngineer,
    isLoading: isLoadingProjectsPerTestTypeAndEngineer,
  } = useQuery(
    'chart_project_per_testType_and_engineer',
    getProjectsPerTestTypeAndEngineerService,
    {
      enabled: isOpenOverview.projects,
    },
  );

  const { data: projectsPerSegment, isLoading: isLoadingProjectsPerSegment } =
    useQuery('chart_projects_per_segment', getProjectsPerSegmentService, {
      enabled: isOpenOverview.projects,
    });

  const { data: projectsPerEngineer, isLoading: isLoadingProjectsPerEngineer } =
    useQuery('chart_projects_per_engineer', getProjectsPerEngineerService, {
      enabled: isOpenOverview.projects,
    });

  const {
    data: projectsPerSegmentAndEngineers,
    isLoading: isLoadingProjectsPerSegmentAndEngineers,
  } = useQuery(
    'chart_projects_per_segment_and_engineer',
    getProjectsPerSegmentAndEngineerService,
    {
      enabled: isOpenOverview.projects,
    },
  );

  const {
    data: projectsPerStatusAndEngineer,
    isLoading: isLoadingProjectsPerStatusAndEngineer,
  } = useQuery(
    'chart_projects_per_status_and_engineer',
    getProjectsPerStatusAndEngineerService,
    {
      enabled: isOpenOverview.projects,
    },
  );
  return {
    projectsPerTestType,
    isLoadingProjectsPerTestType,

    projectsPerSegment,
    isLoadingProjectsPerSegment,

    projectsPerEngineer,
    isLoadingProjectsPerEngineer,

    projectsPerSegmentAndEngineers,
    isLoadingProjectsPerSegmentAndEngineers,

    projectsPerStatusAndEngineer,
    isLoadingProjectsPerStatusAndEngineer,

    projectPerTestTypeAndReport,
    IsLoadingProjPerTestTypeAndReport,

    projectsPerTestTypeAndEngineer,
    isLoadingProjectsPerTestTypeAndEngineer,
  };
}
