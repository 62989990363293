import { EventSourceInput } from '@fullcalendar/react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { FilterParamsProps } from '..';

import {
  getScheduleActivityByUserIdService,
  getScheduleHolidayListService,
} from '../../../services/schedule';
import { SCHEDULE_TASKS } from '../../../settings/constants';
import { HolidayItemProps, ScheduleActivity } from '../../../types/schedule';

interface Props {
  filterParams?: FilterParamsProps;
  newStartDate?: string;
  isTriggerRefetchSchedule: boolean;
}

type AllEventsProps = (HolidayItemProps & ScheduleActivity)[] | undefined;

function getTaskColor(task: ScheduleActivity & HolidayItemProps) {
  // VACATION
  if (task?.activity?.id === 8) {
    return 'purple';
  }

  // HOLIDAY
  if (task?.holidayDate) {
    return 'red';
  }

  // ADMINISTRATION
  if (task?.activity?.id === 41) {
    return 'grey';
  }

  // DAY-OFF
  if (task?.activity?.id === 48) {
    return 'purple';
  }

  return task?.priority?.color?.toLowerCase();
}

export function useScheduleTaskRequests({
  filterParams,
  newStartDate,
  isTriggerRefetchSchedule,
}: Props) {
  const { data: scheduleTasksByUserId, isLoading: isUserTasksLoading } =
    useQuery(
      [SCHEDULE_TASKS, filterParams, newStartDate, isTriggerRefetchSchedule],
      async () => {
        return getScheduleActivityByUserIdService({
          typeFleetId: filterParams?.selectedBusinessId,
          typeActivityId: filterParams?.selectedTaskTypeId,
          activityId: filterParams?.selectedTaskNameId,
          priorityId: filterParams?.selectedPriorityId,
          activityStatusId: filterParams?.selectedStatusId,
          projectId: filterParams?.selectedProjectId,
          fleetId: filterParams?.selectedFleetId,
          emails: filterParams?.selectedUser,
        });
      },
    );

  const { data: holidayEvents, isLoading: holidayEventsLoading } = useQuery(
    'HOLIDAY_LIST_MANAGEMENT',
    getScheduleHolidayListService,
  );

  const isAllEventsLoading = useMemo(
    () => isUserTasksLoading && holidayEventsLoading,
    [holidayEventsLoading, isUserTasksLoading],
  );

  const events: AllEventsProps = useMemo((): any => {
    const list = [];

    if (scheduleTasksByUserId) {
      list.push(...scheduleTasksByUserId);
    }

    if (holidayEvents) {
      list.push(...holidayEvents);
    }

    return list;
  }, [holidayEvents, scheduleTasksByUserId]);

  const allEvents: EventSourceInput | undefined = events?.map(task => ({
    title: task?.holidayDate ? task?.description : task?.title,
    display: task?.holidayDate && 'background',
    color: getTaskColor(task),
    start: task?.holidayDate ?? String(task?.initialDate),
    end: task?.holidayDate ?? String(task?.finalDate),
    allDay: true,
    extendedProps: {
      taskId: task?.id,
      taskSeriesId: task?.batch,
      priority: task?.priority?.label,
      status: task?.activityStatus?.label,
      author: task?.user?.label,

      isVacation: task?.activity?.id === 8,
      isHoliday: !!task?.holidayDate,
      isDayOff: task?.activity?.id === 48,
      isAdministration: task?.activity?.id === 41,
    },
  }));

  return {
    allEvents,
    isAllEventsLoading,
  };
}
