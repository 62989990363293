import { GenericObject } from '../types/fleet';
import api from './api';

const ENGINE_TYPES = '/engineTypes';

export async function getEngineTypesService(): Promise<GenericObject[]> {
  const response = await api.get(`${ENGINE_TYPES}/`, {
    params: { sort: 'label,asc' },
  });

  return response.data;
}

export async function createEngineTypesService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.post(`${ENGINE_TYPES}/`, item);

  return response.data;
}

export async function updateEngineTypesService(
  item: GenericObject,
): Promise<GenericObject> {
  const response = await api.put(`${ENGINE_TYPES}/`, item);

  return response.data;
}

export async function deleteEngineTypesService(id: number) {
  await api.delete(`${ENGINE_TYPES}/${id}`);
}
