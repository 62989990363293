import React from 'react';

import { ItemsProps } from '..';

interface Props {
  items: ItemsProps[];
  setItems: React.Dispatch<ItemsProps[]>;
  id?: number;
  e: React.SyntheticEvent<HTMLElement, Event>;
  value: any;
}
export function handleChangeOptionItem({ items, setItems, id, value }: Props) {
  const index = items?.findIndex(item => item?.id === id);

  const updatingItems = [...(items ?? [])];

  updatingItems[index].tireSize.id = value;

  setItems([...updatingItems]);
}
