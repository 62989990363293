import I18n from 'i18n-js';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Button, Form, Header, Loader, Modal, Step } from 'semantic-ui-react';

import { ConfirmModal } from '../../../components/ConfirmModal';
import { ButtonGDY } from '../../../components/Form/ButtonGDY';
import { convertFleetDataToCategorizedDataForFormData } from '../../../helpers/convertFleetDataToCategorizedData';
import useIsMobileScreen from '../../../hooks/useIsMobileScreen';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { getFleetByIdService } from '../../../services/fleets';
import { getRecappingService } from '../../../services/recappingService';
import { initialValuesFleet } from '../../../settings/constants';
import { FleetDataCategorized } from '../../../types/fleet';
import { useCreateFleet } from '../hooks/useCreateFleet';
import { useEditFleet } from '../hooks/useEditFleet';
import { useFleetFormDataCachedExists } from '../hooks/useFleetFormDataCachedValidation';
import { useSteps } from '../hooks/useSteps';
import { Container } from './styles';

interface IFleetFormModal {
  fleetId?: number;
  isOpen: boolean;
  onRequestClose: () => void;
}

export function FleetFormModal({
  fleetId,
  isOpen,
  onRequestClose,
}: IFleetFormModal) {
  const [isRescueCachedDataModalOpen, setIsRescueCachedDataModalOpen] =
    useState(false);

  const [activeStep, activeSetStep] = useState<number>(0);
  const steps = useSteps({ fleetId });

  const { isMobileScreen } = useIsMobileScreen(460);

  const [fleetFormDataCachedValues, setFleetFormDataCachedValues] =
    useLocalStorage('@FleetFormData', '');

  const fleetFormDataCachedIsNotEmpty = useFleetFormDataCachedExists();

  const methods = useForm<any>({
    defaultValues: fleetFormDataCachedIsNotEmpty
      ? fleetFormDataCachedValues
      : initialValuesFleet,
  });
  const [isLoading, setIsLoading] = useState(false);

  const currentFormData = useWatch({ control: methods.control });

  useEffect(() => {
    if (!fleetId) {
      setFleetFormDataCachedValues(currentFormData);
    }
  }, [currentFormData, fleetId, setFleetFormDataCachedValues]);

  useEffect(() => {
    if (!fleetId && fleetFormDataCachedIsNotEmpty) {
      setIsRescueCachedDataModalOpen(true);
    }
  }, [fleetFormDataCachedIsNotEmpty, fleetId]);

  const { data: tireRecapping } = useQuery(
    'tire_recapping',
    getRecappingService,
    {},
  );

  useEffect(() => {
    if (fleetId) {
      setIsLoading(true);
      getFleetByIdService(fleetId)
        .then(response => {
          methods.reset(
            convertFleetDataToCategorizedDataForFormData(
              response,
              tireRecapping,
            ),
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [fleetId, methods, tireRecapping]);

  const getCurrentStep = useCallback(() => {
    return steps[activeStep].element;
  }, [activeStep, steps]);

  const createFleet = useCreateFleet(onRequestClose);

  const editFleet = useEditFleet(onRequestClose);

  const isLastStep = useCallback(() => {
    return activeStep === steps.length - 1;
  }, [activeStep, steps.length]);

  function getButtonName() {
    if (isLastStep()) {
      return 'Finish';
    }
    return 'Next';
  }

  function scrollToTop() {
    document.getElementById('form__body')?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }

  function handleCachedDataRescue() {
    setIsRescueCachedDataModalOpen(false);
  }

  function handleCachedDataDiscard() {
    setIsRescueCachedDataModalOpen(false);

    methods.reset(initialValuesFleet);
    setFleetFormDataCachedValues(initialValuesFleet);
  }

  const handleClickNextStep = async (data: FleetDataCategorized) => {
    if (activeStep < steps.length - 1) {
      activeSetStep(currStep => currStep + 1);
    }
    if (isLastStep()) {
      if (fleetId) {
        await editFleet.mutateAsync(data);
      } else {
        await createFleet.mutateAsync(data);
      }

      methods.reset(initialValuesFleet);
    }
    scrollToTop();
  };

  const handleClickPrevStep = () => {
    if (activeStep > 0) {
      activeSetStep(currStep => currStep - 1);
    }
    scrollToTop();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onRequestClose}
        closeIcon
        closeOnDimmerClick={false}
      >
        {fleetId && isLoading ? (
          <Loader size="large" active inverted />
        ) : (
          <>
            <Modal.Header>{I18n.t('title.fleetRegistration')}</Modal.Header>
            <Modal.Content>
              <Container>
                <Step.Group vertical={!isMobileScreen} unstackable size="mini">
                  {steps.map((item, index) => (
                    <Step
                      key={item.title}
                      icon={item.icon}
                      title={!isMobileScreen && item.title}
                      active={activeStep === index}
                      disabled={activeStep < index}
                    />
                  ))}
                </Step.Group>

                <div className="form__container">
                  <Header
                    className="form__title"
                    content={steps[activeStep].title}
                    as="h4"
                  />
                  <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(handleClickNextStep)}>
                      <div id="form__body" className="form__body">
                        {getCurrentStep()}
                      </div>

                      <div className="form__footer">
                        <Form.Group>
                          <Form.Field
                            control={Button}
                            content="Prev"
                            disabled={activeStep === 0}
                            onClick={handleClickPrevStep}
                            type="button"
                          />

                          <ButtonGDY
                            floated="right"
                            content={getButtonName()}
                            type="submit"
                          />
                        </Form.Group>
                      </div>
                    </Form>
                  </FormProvider>
                </div>
              </Container>
            </Modal.Content>
          </>
        )}
      </Modal>
      <ConfirmModal
        header={I18n.t('page.fleets.modal.rescueCachedData.title')}
        content={I18n.t('page.fleets.modal.rescueCachedData.content')}
        open={isRescueCachedDataModalOpen}
        onClose={() => setIsRescueCachedDataModalOpen(false)}
        cancelBtnContent={I18n.t(
          'page.fleets.modal.rescueCachedData.button.cancel',
        )}
        onCancel={handleCachedDataDiscard}
        confirmBtnContent={I18n.t(
          'page.fleets.modal.rescueCachedData.button.confirm',
        )}
        onConfirm={handleCachedDataRescue}
      />
    </>
  );
}
