import { useQuery } from 'react-query';

import { getTotalTiresPerFleetsService } from '../../../services/projectTotalFleets';
import { getProjectTotalTiresService } from '../../../services/projectTotalTires';

export function useTireDetailsRequests(projectId?: string) {
  const { data: tires } = useQuery('total_tires', async () =>
    getProjectTotalTiresService(Number(projectId)),
  );

  const { data: totalTiresPerFleet } = useQuery(
    'total_tires_per_fleets',
    async () => {
      return getTotalTiresPerFleetsService(Number(projectId));
    },
  );

  const totalTiresFleetArray: any = totalTiresPerFleet?.map(item => {
    return item.totalTires;
  });

  const totalTiresFleetTotal =
    totalTiresFleetArray?.length === 0
      ? null
      : totalTiresFleetArray?.reduce((item: any, i: any) => {
          return item + i;
        });

  return {
    tires,
    totalTiresPerFleet,
    totalTiresFleetTotal,
  };
}
